import {Component, OnInit} from '@angular/core';
import {PermissionsService} from "../../services/permissions.service";
import {GameService} from "../../services/game.service";
import {IRoleWithPermissions} from "../../interfaces/IRoleWithPermissions";
import {UIStateEnum} from "../../enum/UIStateEnum";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {PermissionTypes} from "../../enum/PermissionTypes";
import {FirstDataRenderedEvent, GridSizeChangedEvent} from "ag-grid-community";
import {AdminUserTables} from "../../table-definitions/admin-user-tables";
import {IUpdateRole} from "../../interfaces/IUpdateRole";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {SnackbarService} from "../../services/snackbar.service";
import {IPermission} from "../../interfaces/IPermission";
import {IDialogGenericData} from "../../interfaces/IDialogGenericData";
import {
  ConfirmationActionDialogComponent
} from "../../components/dialogs/confirmation-action-dialog/confirmation-action-dialog.component";
import {DialogFunctionService} from "../../services/dialog-function.service";
import {IRemovePermissionFromRole} from "../../interfaces/IRemovePermissionFromRole";
import {MatDialog} from "@angular/material/dialog";
import {
  AddPermissionsToRoleComponent
} from "../../components/dialogs/add-permissions-to-role/add-permissions-to-role.component";
import {concatMap, forkJoin, tap} from "rxjs";
import {AddNewRoleComponent} from "../../components/dialogs/add-new-role/add-new-role.component";

@Component({
  selector: 'app-manage-permissions',
  templateUrl: './manage-permissions.component.html',
  styleUrls: ['./manage-permissions.component.css']
})
export class ManagePermissionsComponent implements OnInit {
  public rolesWithPermissions: IRoleWithPermissions[] = [];
  public displayedRolesColumns: string[] = ['roleName', 'description', 'active', 'edit'];
  public displayedPermissionsPerRoleColumns: string[] = ['value', 'name', 'active', 'remove'];
  public uiState: UIStateEnum = UIStateEnum.ShowLoading;
  public uiStateEnumForTemplate = UIStateEnum;
  public chosenRole: IRoleWithPermissions | undefined = undefined;
  public gameId = this.gameService.activeGame().Id;
  public allAvailablePermissions: IPermission[] = [];
  public roleNameControl: FormControl<string | null> = new FormControl<string>('', Validators.required);
  public descriptionControl: FormControl<string | null> = new FormControl<string>('', Validators.required);
  public userHasEditPermission = this.permissionsService.userHasPermission(PermissionTypes.ModifyRolesAndPermissions);
  public permissionTypes = PermissionTypes;
  public genericAdminColDefs = this.adminUserTables.genericAdminColDefs;
  public roleManagementSettingsFormGroup: FormGroup = new FormGroup<any>({
    roleName: this.roleNameControl,
    description: this.descriptionControl
  })

  constructor(private permissionsService: PermissionsService,
              private adminUserTables: AdminUserTables,
              private snackBarService: SnackbarService,
              private matDialog: MatDialog,
              private dialogFunctionService: DialogFunctionService,
              private errorHandlingService: ErrorHandlingService,
              private gameService: GameService) {
  }

  ngOnInit(): void {
    this.fetchRolesAndPermissions().subscribe({
      next: () => {
        this.uiState = UIStateEnum.ShowData;
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    });
  }

  private fetchRolesAndPermissions() {
    return forkJoin([
      this.permissionsService.getAllRolesAndPermissions(this.gameId),
      this.permissionsService.fetchAllAvailablePermissions(this.gameId),
    ]).pipe(tap(([rolesWithPermissions, allPermissions]) => {
      this.rolesWithPermissions = rolesWithPermissions;
      this.allAvailablePermissions = allPermissions;
      // reset chosen role if it is already set with updated version after role edit
      if (this.chosenRole) {
        this.chosenRole = this.rolesWithPermissions.find((rolesWithPermissionsP) => rolesWithPermissionsP.Role.Id === this.chosenRole?.Role.Id)
      }
    }))
  }

  onAddNewRoleClick() {
    const dialogRef = this.matDialog.open(AddNewRoleComponent, {
      width: '650px'
    });

    dialogRef.afterClosed().pipe(concatMap(() => this.fetchRolesAndPermissions()
    )).subscribe({
      next: () => {
        this.uiState = UIStateEnum.ShowData;
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    });
  }

  onAddPermissionsToRoleClick() {
    const dialogRef = this.matDialog.open(AddPermissionsToRoleComponent, {
      data: this.chosenRole,
      width: '650px'
    });

    dialogRef.afterClosed().pipe(concatMap(() => this.fetchRolesAndPermissions()
    )).subscribe({
      next: () => {
        this.uiState = UIStateEnum.ShowData;
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    });
  }

  viewRoleDetailsClick(chosenRoleP: IRoleWithPermissions) {
    this.chosenRole = chosenRoleP;
    this.roleNameControl.setValue(this.chosenRole.Role.Name);
    this.descriptionControl.setValue(this.chosenRole.Role.Description);
  }

  onRemovePermissionFromRoleClick(chosenPermissionP: IPermission) {
    this.dialogFunctionService.setCallback(() => {
      let removePermissionCommand: IRemovePermissionFromRole = {
        roleId: this.chosenRole!.Role.Id,
        permissionValue: chosenPermissionP.Value,
        gameId: this.gameId
      };

      return this.permissionsService.removePermissionFromRole(removePermissionCommand)
    });

    this.removePermissionFromRole(chosenPermissionP.Name);
  }

  removePermissionFromRole(permissionNameP: string) {
    let deactivateDialogData: IDialogGenericData = {
      title: 'Confirm Removing Permission from Role',
      message: `Confirm that you wish to remove the ${permissionNameP} permission from the ${this.chosenRole?.Role.Name} role ?: `,
      successMessage: 'Successfully deactivated ticket strip',
      dataCyTag: 'confirm-deactivate-numbers'
    };

    const dialogRef = this.matDialog.open(ConfirmationActionDialogComponent, {
      data: deactivateDialogData,
      width: '650px'
    });

    dialogRef.afterClosed().pipe(concatMap(() => this.fetchRolesAndPermissions()
    )).subscribe({
      next: () => {
        this.uiState = UIStateEnum.ShowData;
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    });
  }

  updateRoleSettings() {
    if (this.roleManagementSettingsFormGroup.valid && this.chosenRole) {
      const updateRoleRequest: IUpdateRole = {
        description: this.descriptionControl.value ?? '',
        name: this.roleNameControl.value ?? '',
        roleId: this.chosenRole?.Role.Id,
        gameId: this.gameId
      };
      this.uiState = UIStateEnum.ShowLoading;
      this.roleManagementSettingsFormGroup.disable();
      this.permissionsService.updateUserRole(updateRoleRequest)
        .pipe(concatMap(() => this.fetchRolesAndPermissions()))
        .subscribe({
          next: () => {
            this.uiState = UIStateEnum.ShowData;
            this.roleManagementSettingsFormGroup.enable();
            this.snackBarService.openSuccessfulSnackBar('Role has been successfully updated')
          },
          error: (err) => {
            this.uiState = UIStateEnum.ShowData;
            this.roleManagementSettingsFormGroup.enable();
            this.errorHandlingService.displayPageLevelErrorMessage(err);
          }
        }
      )
    }
  }

  gridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

}
