import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from "./pages/dashboard/dashboard.component";
import {UserIsAdminGuard} from "./guards/user-is-admin-guard";
import {LogoutPage} from "./pages/auth/logout/logout.page";
import {UserIsNotAdminPageComponent} from "./pages/user-is-not-admin-page/user-is-not-admin-page.component";
import {DrawWinnerPageComponent} from "./pages/draw-winner/draw-winner-page.component";
import {ManageGameComponent} from "./pages/manage-game/manage-game.component";
import {ManagePlayersComponent} from "./pages/manage-players/manage-players.component";
import {ChoosePlayerComponent} from "./pages/choose-player/choose-player.component";
import {GameSelectedGuard} from "./guards/game-selected-guard";
import {ChooseGameComponent} from "./pages/choose-game/choose-game.component";
import {CharityPayoutsComponent} from "./pages/charity-payouts/charity-payouts.component";
import {WinnerPayoutsComponent} from "./pages/winner-payouts/winner-payouts.component";
import {ManageUsersComponent} from "./pages/manage-users/manage-users.component";
import {RecoverPasswordComponent} from "./pages/recover-password/recover-password.component";
import {UpdatePasswordComponent} from "./pages/update-password/update-password.component";
import {UpdateEmailAddressComponent} from "./pages/update-email-address/update-email-address.component";
import {GetAllGamesForUserGuard} from "./guards/get-all-games-for-user.guard";
import {AuditingComponent} from "./pages/auditing/auditing.component";
import {AuditRouteGuard} from "./guards/audit-route.guard";
import {DisabledRouteGuard} from "./guards/disabled-route.guard";
import {ReportsComponent} from "./pages/reports/reports.component";
import {UserDetailsComponent} from "./pages/user-details/user-details.component";
import {PermissionGuard} from "./guards/permission-guard";
import {PermissionTypes} from "./enum/PermissionTypes";
import {NotAuthorizedComponent} from "./pages/not-authorized/not-authorized.component";
import {ManagePermissionsComponent} from "./pages/manage-permissions/manage-permissions.component";

const routes: Routes = [
  {
    path: 'choose-game',
    title: 'Choose Game',
    canActivate: [UserIsAdminGuard, GetAllGamesForUserGuard],
    component: ChooseGameComponent
  },
  {
    path: 'not-admin',
    title: 'Not Admin',
    component: UserIsNotAdminPageComponent
  },
  {
    path: 'not-authorized',
    title: 'Not Authorized',
    component: NotAuthorizedComponent
  },
  {
    path: 'recover-password',
    title: 'Recover Password',
    component: RecoverPasswordComponent
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'logout',
    title: 'Logout',
    canActivate: [UserIsAdminGuard],
    component: LogoutPage
  },
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full'
  },
  {
    path: 'update-password',
    canActivate: [UserIsAdminGuard],
    title: 'Update Password',
    component: UpdatePasswordComponent
  },
  {
    path: 'update-email-address',
    title: 'Update Email Address',
    canActivate: [UserIsAdminGuard],
    component: UpdateEmailAddressComponent
  },
  {
    path: ':gameId',
    canActivate: [UserIsAdminGuard, GameSelectedGuard],
    children: [
      {
        path: 'dashboard',
        title: 'Dashboard',
        component: DashboardComponent
      },
      {
        path: 'auditing',
        title: 'Auditing',
        canActivate: [DisabledRouteGuard, AuditRouteGuard],
        component: AuditingComponent
      },
      {
        path: 'charity-payouts',
        title: 'Charity Payouts',
        canActivate: [DisabledRouteGuard],
        component: CharityPayoutsComponent
      },
      {
        path: 'winner-payouts',
        title: 'Winner Payouts',
        canActivate: [DisabledRouteGuard],
        component: WinnerPayoutsComponent
      },
      {
        path: 'reports',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewReports)],
        title: 'Reports',
        component: ReportsComponent
      },
      {
        path: 'manage-users',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewAdminUsers)],
        title: 'Manage Users',
        component: ManageUsersComponent
      },
      {
        path: 'manage-permissions',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewRolesAndPermissions)],
        title: 'Manage Permissions',
        component: ManagePermissionsComponent
      },
      {
        path: 'user-details/:userId',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewAdminUsers)],
        title: 'User',
        component: UserDetailsComponent
      },
      {
        path: 'draw-winner',
        title: 'Draw Winner',
        canActivate: [DisabledRouteGuard],
        component: DrawWinnerPageComponent
      },
      {
        path: 'manage-game',
        title: 'Manage Game',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewGameSettings)],
        component: ManageGameComponent
      },
      {
        path: 'manage-charities',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewCharities)],
        loadChildren: () => import('./pages/manage-charities/charity-management.module').then(m => m.CharityManagementModule)
      },
      {
        path: 'active-game-instances',
        loadChildren: () => import('./pages/active-game-instances/active-game-instances.module').then(m => m.ActiveGameInstancesModule)
      },
      {
        path: 'manage-player/:playerId',
        title: 'Manage Game Players',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewPlayerInfo)],
        component: ManagePlayersComponent
      },
      {
        path: 'player-search',
        title: 'Player Search',
        canActivate: [() => PermissionGuard(PermissionTypes.ViewPlayerInfo)],
        component: ChoosePlayerComponent
      }
    ]
  }
];

// initialNavigation: "disabled" stops navigation until we are ready (re-enabled in app component when org and auth config have been set up)
@NgModule({
  imports: [RouterModule.forRoot(routes, {initialNavigation: "disabled"})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
