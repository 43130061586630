<app-dialog-base [showLoadingSpinner]="uiState == uiStateEnumForTemplate.ShowLoading">
  <span dialog-title>Add Permission to {{role.Role.Name}}</span>
  <ng-container dialog-content>
    <div autofocus [tabindex]="0">Current Permissions</div>
    <mat-chip-listbox aria-label="current permission for role">
      <mat-chip
        data-cy="assigning-roles-current-role-chip"
        *ngFor="let permission of role.Permissions">
        {{permission.Name}}
      </mat-chip>
    </mat-chip-listbox>
    <ng-container *ngIf="availablePermissions.length > 0 else noPermissions">
      <p>Select permissions to add to this role. You may add multiple permissions at a time.</p>
      <form [formGroup]="newPermissionsFormGroup">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Select Permissions</mat-label>
          <mat-chip-grid #chipGrid aria-label="Fruit selection">
            <mat-chip-row
              data-cy="assigning-roles-role-to-add-chip"
              *ngFor="let role of permissionsToAdd"
              (removed)="remove(role)">
              {{role.Name}}
              <button matChipRemove [attr.aria-label]="'remove ' + role">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </mat-chip-grid>
          <input #permissionInput [formControl]="newPermissionsFormControl"
                 data-cy="assigning-roles-select-roles-input"
                 placeholder="start typing or select from list...."
                 [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
                 (matChipInputTokenEnd)="add($event)"/>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option
              data-cy="assigning-roles-select-options"
              *ngFor="let permission of filteredAvailablePermissions | async"
              [value]="permission">
              {{permission.Name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-error *ngIf="permissionsToAdd.length === 0 && (newPermissionsFormControl.touched || newPermissionsFormControl.dirty)">You
          must choose at least one permission to add
        </mat-error>
      </form>
    </ng-container>

    <ng-template #noPermissions>
      <p>Looks like this role has all available permissions</p>
    </ng-template>

    <div *ngIf="newPermissionsFormControl.value">New Permissions:
      <span class="bold-text">{{newPermissionsFormControl.value}}</span>
    </div>
  </ng-container>
  <ng-container actions>
    <button
      mat-button
      data-cy="save-new-charities-button"
      color="primary"
      [disabled]="uiState == uiStateEnumForTemplate.ShowLoading"
      (click)="addPermissions()">Save
    </button>
  </ng-container>
</app-dialog-base>
