import {Component} from '@angular/core';
import {IGameQueryResult} from "../../interfaces/IGameQueryResult";
import {map, Observable, of, timer} from "rxjs";
import {GameService} from "../../services/game.service";
import {AppConfigService} from "../../services/app-config.service";
import {tap} from "rxjs/operators";
import {CountdownService} from "../../services/countdown.service";
import {IActiveGameInstance} from "../../interfaces/IActiveGameInstance";
import {GameInstanceService} from "../../services/game-instance.service";
import {ThemeService} from "../../services/theme.service";
import {IAppConfig} from "../../interfaces/IAppConfig";
import {PotentialEnvironments} from "../../consts/PotentialEnvironments";
import {EnvironmentType} from "../../enum/EnvironmentType";

@Component({
  selector: 'app-game-banner',
  templateUrl: './game-banner.component.html',
  styleUrls: ['./game-banner.component.scss']
})
export class GameBannerComponent {

  public activeGame$: Observable<IGameQueryResult | undefined> = this.gameService.selectActiveGame$().pipe(tap((gameP) => {
    if (gameP) {
      this.themeService.setApplicationTheme(gameP);
      this.activeGameInstance$ = this.gameInstanceService.activeGameInstanceByGame(gameP.Id).pipe(tap((activeGameInstanceP) => {
        if (activeGameInstanceP) {
          this.createCountdownSubscription(new Date(activeGameInstanceP.EndedOn));
        }
      }))
    } else {
      this.activeGameInstance$ = of(null);
    }
  }));

  public currentEnvironment: string = '';
  public appConfig$: Observable<IAppConfig> = this.appConfigService.appConfig$.pipe(tap((configP) => {
    this.determineEnvironment(configP.currentEnvironment);
  }));
  public endDateCountDown$: Observable<string> = of('');
  public activeGameInstance$: Observable<IActiveGameInstance | null> = of(null);

  constructor(private gameService: GameService,
              private gameInstanceService: GameInstanceService,
              private themeService: ThemeService,
              private countDownService: CountdownService,
              private appConfigService: AppConfigService) {
  }

  private createCountdownSubscription(endDateP: Date): void {
    this.endDateCountDown$ = timer(0, 1000).pipe(map(() => {

      if (endDateP) {
        return this.countDownService.formatRemainingTimeUntilDraw(endDateP);
      }

      return '';
    }));
  }

  private determineEnvironment(currentEnvironmentStringP: string) {
    const currentEnvironment = PotentialEnvironments.find((envP) => {
      return envP.type.toLowerCase() === currentEnvironmentStringP.toLowerCase();
    });

    if (currentEnvironment && currentEnvironment.type !== EnvironmentType.Production) {
      this.currentEnvironment = currentEnvironment.name;
    }
  }
}
