import {Component} from '@angular/core';
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CharityService} from "../../../services/charity.service";
import {ICreateCharityCategoryCommand} from "../../../interfaces/charity/ICreateCharityCategoryCommand";
import {SnackbarService} from "../../../services/snackbar.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {GameService} from "../../../services/game.service";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-add-new-category',
  templateUrl: './add-new-category.component.html',
  styleUrls: ['./add-new-category.component.css']
})
export class AddNewCategoryComponent {

  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;
  public categoryNameControl: FormControl<string | null> = new FormControl<string | null>('', Validators.required);
  public createCharityCategoryForm = new FormGroup({
    categoryName: this.categoryNameControl
  });

  constructor(private charityService: CharityService,
              private snackBarService: SnackbarService,
              private errorHandlingService: ErrorHandlingService,
              private gameService: GameService,
              private matDialogRef: MatDialogRef<AddNewCategoryComponent>,) {
  }

  submitNewCharity() {
    if (this.createCharityCategoryForm.valid) {
      this.uiState = UIStateEnum.ShowLoading;
      let newCategoryCommand: ICreateCharityCategoryCommand = {
        charityCategoryName: this.categoryNameControl.value!,
        gameId: this.gameService.activeGame()?.Id!,
        active: true
      };

      this.charityService.createNewCharityCategory(newCategoryCommand).subscribe({
        next: (res) => {
          this.matDialogRef.close(res.NewCategoryId);
          this.snackBarService.openSuccessfulSnackBar('Successfully Add New Charity Category');
        },
        error: (err) => {
          this.uiState = UIStateEnum.ShowData;
          this.errorHandlingService.displayDialogLevelErrorMessage(err);
        }
      })
    }

  }

}
