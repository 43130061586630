import {Component, Inject} from '@angular/core';
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ICharityPaymentBalance} from "../../../interfaces/reporting/ICharityPaymentBalance";

@Component({
  selector: 'app-charity-payouts-dialog',
  templateUrl: './make-payment-dialog.component.html',
  styleUrls: ['./make-payment-dialog.component.scss']
})
export class MakePaymentDialogComponent {

  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;
  public selectedCharityBalance!: ICharityPaymentBalance;

  public makeCharityPaymentForm = new FormGroup({
    paymentAmount: new FormControl<number | null>(0, Validators.required),
    paymentDateControl: new FormControl<Date>(new Date(), Validators.required),
  });

  constructor(@Inject(MAT_DIALOG_DATA) public charityBreakdown: ICharityPaymentBalance[]) {
  }

  updateForm() {
    this.makeCharityPaymentForm.get('paymentAmount')?.setValue(this.selectedCharityBalance.TotalOwedToCharity / 100);
  }

}
