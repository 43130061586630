<app-dialog-base [showLoadingSpinner]="uiState == uiStateEnumForTemplate.ShowLoading">
  <span
    dialog-title>Update Basic Player Info</span>

  <ng-container dialog-content *ngIf="chosenPlayer && uiState === uiStateEnumForTemplate.ShowData">
    <form [formGroup]="updateBasicInfoForm">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>First Name</mat-label>
        <input data-cy="edit-player-info-fname-input" formControlName="firstName" type="text" matInput
               placeholder="enter first name" required>
        <mat-error *ngIf="firstNameControl.hasError('required')">
          first name is required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="full-width margin-top-small">
        <mat-label>Last Name</mat-label>
        <input data-cy="edit-player-info-lname-input" formControlName="lastName" type="text" matInput
               placeholder="enter last name" required>
        <mat-error *ngIf="lastNameControl.hasError('required')">
          last name is required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="full-width margin-top-small">
        <mat-label>Phone Number</mat-label>
        <input data-cy="edit-player-info-phone-input" formControlName="phoneNumber" type="text" matInput
               placeholder="enter phone number" required>
        <mat-error *ngIf="phoneNumberControl.invalid">please enter a valid phone number</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="full-width margin-top-small">
        <mat-label>Email</mat-label>
        <input data-cy="edit-player-info-email-input" formControlName="email" type="text" matInput
               placeholder="enter email" required>
        <mat-error
          *ngIf="emailControl.errors && (emailControl.dirty || emailControl.touched)">
          <ng-container *ngIf="emailControl.hasError('required')">
            <strong>required.</strong>
          </ng-container>
          <ng-container *ngIf="emailControl.hasError('email')">
            incorrect email format. <strong>ex: example@test.com</strong>
          </ng-container>
        </mat-error>
      </mat-form-field>
    </form>

    <ng-container *ngIf="areChangesPresent()">
      <h3>Proposed Changes</h3>
      <div *ngFor="let key of Object.keys(changes)">
        <div *ngIf="changes[key]" [innerHTML]="changes[key]"></div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container actions>
    <button
      data-cy="save-update-charity"
      mat-button
      (click)="updateBasicInfo()"
      [disabled]="uiState === uiStateEnumForTemplate.ShowLoading  || !areChangesPresent()">Confirm
    </button>
  </ng-container>
</app-dialog-base>
