<h2 mat-dialog-title><ng-content select="[dialog-title]"></ng-content></h2>
<app-dialog-display-error></app-dialog-display-error>
<mat-dialog-content>
  <ng-content select="[dialog-content]"></ng-content>
</mat-dialog-content>
<mat-spinner class="center-margin" *ngIf="showLoadingSpinner"></mat-spinner>
<mat-dialog-actions align="end">
  <button mat-button class="mat-warn close-dialog-button" data-cy="cancel-dialog" [mat-dialog-close]="false">Cancel</button>
  <ng-content select="[actions]"></ng-content>
</mat-dialog-actions>
