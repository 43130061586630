import {Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';

import {switchMap} from 'rxjs/operators';
import {GameService} from "../../services/game.service";
import {PermissionsService} from "../../services/permissions.service";
import {Subscription} from "rxjs";

@Directive({
  selector: '[userHasCausableAdminAccess]'
})
export class UserHasCausableAdminAccessDirective implements OnInit, OnDestroy {

  private getIsCausableAdminSubscription: Subscription | null = null;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionService: PermissionsService,
    private gameService: GameService
  ) {
  }

  ngOnInit() {
    this.getIsCausableAdminSubscription = this.gameService.selectActiveGame$().pipe(
      switchMap(() => this.permissionService.hasCausableAdminPermissions())
    ).subscribe((hasAccess: boolean) => {
      if (hasAccess) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.getIsCausableAdminSubscription != null) {
      this.getIsCausableAdminSubscription.unsubscribe();
    }
  }
}
