import {Component} from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {MatDialog} from "@angular/material/dialog";
import {ICellRendererParams} from "ag-grid-community";
import {IGameInstanceWinner} from "../../../interfaces/IGameInstanceWinner";
import {PlayWinnerDialogComponent} from "../../dialogs/play-winner-dialog/play-winner-dialog.component";

@Component({
  selector: 'app-pay-winner-button',
  templateUrl: './pay-winner-button.component.html',
  styleUrls: ['./pay-winner-button.component.css']
})
export class PayWinnerButtonComponent implements ICellRendererAngularComp {

  public rowData!: IGameInstanceWinner;

  constructor(private matDialog: MatDialog) {
  }

  agInit(params: ICellRendererParams): void {
    this.rowData = params.data;
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }

  onPayWinnerClick() {
    this.matDialog.open(PlayWinnerDialogComponent, {
      data: this.rowData,
      width: '650px'
    });
  }

}
