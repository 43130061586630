<div class="flex-row justify-center align-start margin-top-large">
  <mat-card class="login-card">
    <mat-card-header>
      <mat-card-title class="text-center">Recover your Password</mat-card-title>
    </mat-card-header>
    <mat-card-content class="text-center margin-top-large">
      <ng-container *ngIf="!showCheckEmailMessage else showMessage">
        <p>PROVIDE YOUR EMAIL ADDRESS AND WE'LL SEND YOU AN EMAIL THAT WILL ALLOW YOU TO BEGIN RECOVERING YOUR
          PASSWORD.</p>
        <form [formGroup]="recoverPasswordForm">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Email</mat-label>
            <input
              matInput
              email
              formControlName="recoverPasswordEmail"
              required
              placeholder="Your email address"
            >
            <mat-error *ngIf="recoverPasswordForm.controls.recoverPasswordEmail.hasError('required')">
              email address is <span class="bold-text">required</span>
            </mat-error>
            <mat-error *ngIf="recoverPasswordForm.controls.recoverPasswordEmail.hasError('email')">
              incorrect email format. <strong>ex: example@test.com</strong>
            </mat-error>
          </mat-form-field>

          <re-captcha
            required
            theme="dark"
            formControlName="recaptcha"
            [siteKey]="recaptchaKey">
          </re-captcha>

        </form>

        <button mat-raised-button class="login-button" [disabled]="uiState == uiStateForTemplate.ShowLoading"
                (click)="sendPasswordRecoveryEmail()">Send Email
        </button>
      </ng-container>
    </mat-card-content>
  </mat-card>
</div>

<ng-template #showMessage>
  <div>
    THANK YOU!
    <p>PLEASE CHECK YOUR EMAIL FOR INSTRUCTIONS ON HOW TO RECOVER YOUR PASSWORD.</p>
  </div>
</ng-template>
