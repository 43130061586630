import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'drawDayOfWeek'
})
export class DrawDayOfWeekPipe implements PipeTransform {
  public transform(drawDayOfWeek: number): string {
    if (drawDayOfWeek) {
      switch (drawDayOfWeek) {
        case 1:
          return 'Monday';
        case 2:
          return 'Tuesday';
        case 3:
          return 'Wednesday';
        case 4:
          return 'Thursday';
        case 5:
          return 'Friday';
        case 6:
          return 'Saturday';
        case 7:
          return 'Sunday';
      }
    }
    return 'Unknown Day';
  }
}
