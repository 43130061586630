<div class="page-container">

  <ng-container *ngIf="uiState === uiStateForTemplate.ShowData else showLoading">
    <h1>Manage Admin Users</h1>
    <div class="flex-row justify-space-between align-end">
      <h3>Admin Users</h3>
      <button mat-raised-button
              data-cy="add-game-admin-button"
              *hasPermission="permissionTypes.ModifyAdminUsers"
              color="primary"
              (click)="onInviteUserClick(adminInviteTypes.GameAdminInvite)"
              matTooltip="Invite New Game Admin"
              matTooltipShowDelay="500">Add User
        <mat-icon>add_circle</mat-icon>
      </button>
    </div>

    <ag-grid-angular
      id="gameAdminGrid"
      class="ag-theme-alpine margin-top-med margin-bottom-med"
      [tooltipShowDelay]=0
      domLayout='autoHeight'
      [rowHeight]="50"
      rowClass="table-row"
      (firstDataRendered)="onFirstDataRendered($event)"
      (gridSizeChanged)="gridSizeChanged($event)"
      overlayNoRowsTemplate="<span>No game admins for this game</span>"
      [rowData]="gameAdminUsers"
      [columnDefs]="gameAdminColDefs">
    </ag-grid-angular>

    <ng-container *userHasCausableAdminAccess>
      <div class="flex-row justify-space-between align-end">
        <h3>Causable Admins</h3>
        <button mat-raised-button
                color="primary"
                *hasPermission="permissionTypes.ModifyAdminUsers"
                (click)="onInviteUserClick(adminInviteTypes.CausableInvite)"
                matTooltip="Invite New Game Admin"
                matTooltipShowDelay="500">Add Causable Admin
          <mat-icon>add_circle</mat-icon>
        </button>
      </div>
      <ag-grid-angular
        data-cy="causable-admins-table"
        class="ag-theme-alpine margin-top-med margin-bottom-med"
        [tooltipShowDelay]=0
        domLayout='autoHeight'
        [rowHeight]="50"
        overlayNoRowsTemplate="<span>No causable admins</span>"
        (firstDataRendered)="onFirstDataRendered($event)"
        (gridSizeChanged)="gridSizeChanged($event)"
        [rowData]="causableAdminUsers"
        [columnDefs]="causableAdminColDefs">
      </ag-grid-angular>
    </ng-container>
  </ng-container>

  <ng-template #showLoading>
    <app-loading-card-content></app-loading-card-content>
  </ng-template>

</div>
