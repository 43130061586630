import {Component, Inject, OnInit} from '@angular/core';
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {IGameInstanceWinner} from "../../../interfaces/IGameInstanceWinner";

@Component({
  selector: 'app-play-winner-dialog',
  templateUrl: './play-winner-dialog.component.html',
  styleUrls: ['./play-winner-dialog.component.css']
})
export class PlayWinnerDialogComponent implements OnInit {

  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;

  public makeWinnerPaymentForm = new FormGroup({
    paymentDateControl: new FormControl<Date>(new Date(), Validators.required),
  });

  constructor(@Inject(MAT_DIALOG_DATA) public winner: IGameInstanceWinner) {
  }

  ngOnInit(): void {
  }

}
