import {Component} from '@angular/core';

@Component({
  selector: 'app-loading-card-content',
  templateUrl: './loading-card-content.component.html',
  styleUrls: ['./loading-card-content.component.scss']
})
export class LoadingCardContentComponent {

  constructor() {
  }


}
