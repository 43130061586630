import {Component} from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {UsersService} from "../../../services/users.service";
import {ICellRendererParams} from "ag-grid-community";
import {IAdminQueryResult} from "../../../interfaces/IAdminQueryResult";
import {PermissionTypes} from "../../../enum/PermissionTypes";

@Component({
  selector: 'app-org-causable-settings',
  templateUrl: './org-causable-settings.component.html',
  styleUrls: ['./org-causable-settings.component.scss']
})
export class OrgCausableSettingsComponent implements ICellRendererAngularComp {
  public permissionTypes = PermissionTypes;
  public rowData!: IAdminQueryResult;
  public params!: any;

  constructor(private userService: UsersService) {
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.rowData = params.data;
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }

  onDeactivateUserClick() {
    this.userService.onDeactivateUserClick(this.rowData);
  }

  onReactiveUserClick() {
    this.userService.onReactivateUserClick(this.rowData);
  }
}
