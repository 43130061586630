import {Injectable} from '@angular/core';
import {AuthConfig, OAuthService} from 'angular-oauth2-oidc';
import {IAuthConfig} from "../interfaces/IAuthConfig";

@Injectable({
  providedIn: 'root'
})
export class OidcService {
  constructor(private oauthService: OAuthService) {
  }

  public async attemptLogin(): Promise<boolean> {
    await this.oauthService.tryLogin();
    return this.isAuthenticated();
  }

  public isAuthenticated() {
    return this.oauthService.hasValidAccessToken();
  }

  public async configureAuth(authConfigP: IAuthConfig): Promise<any> {
    const auth_config = this.buildAuthConfig(authConfigP);

    this.oauthService.configure(auth_config);
    return await this.oauthService.loadDiscoveryDocument();
  }

  public getAccessToken(): string {
    return this.oauthService.getAccessToken();
  }


  public getAdminIdFromClaims(): string {
    const identityClaims: any = this.oauthService.getIdentityClaims();

    if (typeof identityClaims !== 'undefined' && identityClaims !== null && identityClaims.hasOwnProperty('sub')) {

      // identity claims are split into a two part string with subject (admin or causable) and their admin id
      const subSplit = identityClaims['sub'].split('|');

      if (subSplit.length >= 2) {
        return subSplit[1].toLowerCase();
      }
    }

    return '';
  }

  public login(): void {
    this.oauthService.initCodeFlow();
  }

  public logout(noRedirectP: boolean = false): void {
    this.oauthService.logOut(noRedirectP);
  }

  private buildAuthConfig(authConfigP: IAuthConfig): AuthConfig {

    return <AuthConfig>{
      clientId: 'causable-admin-app',
      customQueryParams: {
        'adminLogin': true,
        'returnUrlForPasswordReset': `${window.location.origin}/recover-password`
      },
      issuer: authConfigP.issuer,
      redirectUri: `${window.location.origin}/auth/admin-auth-callback`,
      requireHttps: false,
      responseType: 'code',
      scope: 'openid profile causable-admin-api',
      showDebugInformation: authConfigP.showDebugInformation,
      clearHashAfterLogin: false
    };
  }
}
