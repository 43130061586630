import {Injectable} from "@angular/core";
import {APIService} from "./api.service";
import {BehaviorSubject, map, Observable} from "rxjs";
import {IPlayer} from "../interfaces/player/IPlayer";
import {IPlayerSearchParams} from "../interfaces/player/IPlayerSearchParams";
import {IPlayerPerGameQueryResult} from "../interfaces/player/IPlayerPerGameQueryResult";
import {IApiResponseBase} from "../interfaces/IApiResponseBase";
import {IDeactivatePlayerNumberGroup} from "../interfaces/player/IDeactivatePlayerNumberGroup";
import {IReactivatePlayerNumberGroup} from "../interfaces/player/IReactivatePlayerNumberGroup";
import {IPlayerPaymentTransactions} from "../interfaces/player/IPlayerPaymentTransactions";
import {IPlayerNumberGroupsByGameId} from "../interfaces/player/IPlayerNumberGroupsByGameId";
import {IPlayerNumbersByGroupId} from "../interfaces/player/IPlayerNumbersByGroupId";
import {HttpParams} from "@angular/common/http";
import {IDeactivatePlayer} from "../interfaces/player/IDeactivatePlayer";
import {IReactivatePlayer} from "../interfaces/player/IReactivatePlayer";
import {IAdminUpdatePlayerBasicInfo} from "../interfaces/player/IAdminUpdatePlayerBasicInfo";

@Injectable({
  providedIn: 'root'
})
export class PlayersService {

  public fetchUpdatedTicketNumbersSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public fetchUpdatedTicketNumbersSubject$: Observable<boolean> = this.fetchUpdatedTicketNumbersSubject.asObservable();

  constructor(private apiService: APIService) {
  }

  //retrieve player data tied to a particular game
  public getPlayerGameInfo(playerIdP: string, gameIdP: string): Observable<IPlayerPerGameQueryResult> {
    return this.apiService.MakeGetRequest<IPlayerPerGameQueryResult>(`player/${playerIdP}/for-game/${gameIdP}`);
  }

  public updateBasicPlayerInfo(commandP: IAdminUpdatePlayerBasicInfo): Observable<IApiResponseBase> {
    return this.apiService.MakePutRequest<IApiResponseBase>(`player/admin-update/basic-info`, commandP);
  }

  public getPlayerNumbersByGameId(playerIdP: string, gameIdP: string): Observable<IPlayerNumbersByGroupId[]> {
    return this.apiService.MakeGetRequest<IPlayerNumberGroupsByGameId>(`player-number/by-player/${playerIdP}/for-game-in-groups/${gameIdP}`)
      .pipe(map((res) => res.PlayerNumberGroups));
  }

  public getPlayerTransactionsByGameId(playerIdP: string, gameIdP: string): Observable<IPlayerPaymentTransactions[]> {
    return this.apiService.MakeGetRequest<{
      Transactions: IPlayerPaymentTransactions[]
    }>(`player/transactions/${playerIdP}/${gameIdP}`)
      .pipe(map((res) => res.Transactions));
  }

  public deactivatePlayerNumbersByGroup(deactivateCommandP: IDeactivatePlayerNumberGroup): Observable<IApiResponseBase> {
    return this.apiService.MakePutRequest<IApiResponseBase>(`player-number/deactivate-number-group`, deactivateCommandP);
  }

  public deactivatePlayer(deactivatePlayerCommandP: IDeactivatePlayer): Observable<IApiResponseBase> {
    return this.apiService.MakePutRequest<IApiResponseBase>(`player/deactivate`, deactivatePlayerCommandP);
  }

  public reactivatePlayer(reactivatePlayerCommandP: IReactivatePlayer): Observable<IApiResponseBase> {
    return this.apiService.MakePutRequest<IApiResponseBase>(`player/reactivate`, reactivatePlayerCommandP);
  }

  public reactivatePlayerNumbersByGroup(reactivateCommandP: IReactivatePlayerNumberGroup): Observable<IApiResponseBase> {
    return this.apiService.MakePutRequest<IApiResponseBase>(`player-number/reactivate-number-group`, reactivateCommandP);
  }

  public getPlayersBySearchParams(paramsP: IPlayerSearchParams) {
    let queryParams: HttpParams = new HttpParams();

    queryParams = queryParams.append('gameId', paramsP.gameId)

    if (paramsP.firstName) {
      queryParams = queryParams.append('firstName', paramsP.firstName)
    }

    if (paramsP.lastName) {
      queryParams = queryParams.append('lastName', paramsP.lastName)
    }

    if (paramsP.email) {
      queryParams = queryParams.append('email', paramsP.email)
    }

    if (paramsP.phoneNumber) {
      queryParams = queryParams.append('phoneNumber', paramsP.phoneNumber)
    }

    return this.apiService.MakeGetRequest<{ Players: IPlayer[] }>('player/search', queryParams)
      .pipe(map((res) => {
        return res.Players
      }));
  }
}
