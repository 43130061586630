import {Component} from '@angular/core';
import {ErrorHandlingService} from "../../services/error-handling.service";
import {Observable} from "rxjs";

@Component({
  selector: 'app-dialog-display-error',
  templateUrl: './base-display-error/base-display-error-message.component.html',
  styleUrls: ['./base-display-error/base-display-error-message.component.scss']
})
export class DialogLevelErrorMessageComponent {
  public errorMessage$: Observable<string> = this.errorHandlingService.dialogLevelErrorMessage$;

  constructor(private errorHandlingService: ErrorHandlingService) {
  }

}
