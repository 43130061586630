<div class="flex-row justify-center align-start margin-top-large">
  <mat-card class="flex-50">
    <mat-card-header>
      <mat-card-title class="text-center">Change Your Email Address</mat-card-title>
    </mat-card-header>
    <app-spinner-overlay [showSpinner]="uiStateEnum === uiStateEnumForTemplate.ShowLoading">

      <mat-card-content class="text-center margin-top-large">
        <div class="text-center margin-bottom-med">Current Email Address: <strong>{{activeAdmin.Email}}</strong></div>
        <form [formGroup]="updateEmailForm">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>New Email Address</mat-label>
            <input
              data-cy="new-email-address-input"
              matInput
              name="newEmail"
              formControlName="newEmail"
              email
              required
              placeholder="Email"
            >
            <mat-error
              *ngIf="newEmailControl.errors && (newEmailControl.dirty || newEmailControl.touched)">
              <ng-container *ngIf="newEmailControl.hasError('required')">
                <strong>required.</strong>
              </ng-container>
              <ng-container *ngIf="newEmailControl.hasError('email')">
                incorrect email format. <strong>ex: example@test.com</strong>
              </ng-container>
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width margin-bottom-small">
            <mat-label>Password</mat-label>
            <input
              data-cy="update-email-password-input"
              matInput
              [type]="showPassword ? 'text' : 'password'"
              formControlName="password"
              required
              placeholder="enter your password"
            >
            <mat-error
              *ngIf="passwordControl.errors && (passwordControl.dirty || passwordControl.touched)">
              <ng-container *ngIf="passwordControl.hasError('required')">
                <strong>required.</strong>
              </ng-container>
            </mat-error>
            <mat-icon matSuffix
                      (click)="showPassword = !showPassword">{{showPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          </mat-form-field>

        </form>
        <p *ngIf="showConfirmationCompleteMessage">Please check your new email inbox to continue updating your email
          address.</p>
        <button mat-raised-button
                data-cy="update-email-button"
                class="login-button"
                [disabled]="uiStateEnum == uiStateEnumForTemplate.ShowLoading || showConfirmationCompleteMessage"
                (click)="updateEmailAddress()">Update Email Address
        </button>
      </mat-card-content>
    </app-spinner-overlay>

  </mat-card>
</div>
