import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from "@angular/common";
import {AgGridModule} from "ag-grid-angular";
import {ActiveGameInstancesComponent} from "./active-game-instances.component";
import {
  ActiveGameInstancesTableComponent
} from "../../components/active-game-instances-table/active-game-instances-table.component";
import {
  GroupsPlayedPerHourComponent
} from "../../components/reporting/groups-played-per-hour/groups-played-per-hour.component";
import {
  SalesPerGameComponent
} from "../../components/reporting/sales-per-game/sales-per-game.component";
import {PipesModule} from "../../pipes/pipes.module";
import {FormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {
  DisplayActiveGameInstanceComponent
} from "../../components/display-active-game-instance/display-active-game-instance.component";
import {SharedModule} from "../../shared/shared.module";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatTabsModule} from "@angular/material/tabs";
import {MatCardModule} from "@angular/material/card";
import {MatSelectModule} from "@angular/material/select";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {RaffleReportComponent} from "../../components/reporting/raffle-report/raffle-report.component";
import {
  CharityContributionsReportComponent
} from "../../components/reporting/charity-contributions-report/charity-contributions-report.component";

@NgModule({
  declarations: [
    ActiveGameInstancesComponent,
    ActiveGameInstancesTableComponent,
    GroupsPlayedPerHourComponent,
    RaffleReportComponent,
    SalesPerGameComponent,
    DisplayActiveGameInstanceComponent,
    CharityContributionsReportComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      {
        path: ':id',
        title: 'Current Draw',
        component: ActiveGameInstancesComponent
      },
      {
        path: '',
        title: 'Current Draw',
        component: ActiveGameInstancesComponent
      }
    ]),
    AgGridModule,
    PipesModule,
    MatSelectModule,
    MatCardModule,
    FormsModule,
    MatTabsModule,
    MatButtonModule,
    MatInputModule,
    MatDatepickerModule
  ],
  exports: [
    SalesPerGameComponent,
    GroupsPlayedPerHourComponent,
    RaffleReportComponent,
    CharityContributionsReportComponent
  ],
  providers: [
    DatePipe
  ]
})
export class ActiveGameInstancesModule {
}
