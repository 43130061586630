import {Component} from '@angular/core';
import {AuditingService} from "../../services/auditing.service";

@Component({
  selector: 'app-auditing',
  templateUrl: './auditing.component.html',
  styleUrls: ['./auditing.component.css']
})
export class AuditingComponent {


  constructor(private auditService: AuditingService) {
  }

}
