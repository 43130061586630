<app-dialog-base>
  <span dialog-title>{{genericData.title}}</span>

  <ng-container dialog-content>
    <ng-container *ngIf="uiState === uiStateForTemplate.ShowData">
      <p data-cy="generic-dialog-message" [innerHTML]="genericData.message"></p>

      <div *ngIf="genericData.playerData?.firstName">Name: <span
        class="bold-text">{{genericData.playerData?.firstName}} {{genericData.playerData?.lastName!}}</span></div>
      <div *ngIf="genericData.playerData?.email">Email: <span class="bold-text">{{genericData.playerData?.email}}</span>
      </div>

      <p *ngIf="genericData.extraDetails">{{genericData.extraDetails}}</p>
    </ng-container>
  </ng-container>
  <ng-container actions>
    <button [attr.data-cy]="genericData.dataCyTag"
            mat-button
            class="primary-button"
            (click)="onConfirmationClick()">Confirm
    </button>
  </ng-container>
</app-dialog-base>
