import {Injectable} from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class DateService {

  public twoWeeksAgo(dateP = new Date()) {
    dateP.setDate(dateP.getDate() - 14);

    return dateP;
  }
  public areDatesSameDay(dateOneP: Date, dateTwoP: Date) {
    dateOneP = new Date(dateOneP);
    dateTwoP = new Date(dateTwoP);

    return dateOneP.toDateString() == dateTwoP.toDateString();
  }
}
