import {Component, OnInit} from '@angular/core';
import {UpdateUserRolesComponent} from "../../components/dialogs/update-user-roles/update-user-roles.component";
import {IRole} from "../../interfaces/IRole";
import {GamePageBase} from "../../shared/game-page-base";
import {GameService} from "../../services/game.service";
import {MatDialog} from "@angular/material/dialog";
import {UsersService} from "../../services/users.service";
import {ActivatedRoute, Router} from "@angular/router";
import {UIStateEnum} from "../../enum/UIStateEnum";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {IAdminQueryResult} from "../../interfaces/IAdminQueryResult";
import {DialogFunctionService} from "../../services/dialog-function.service";
import {IDialogGenericData} from "../../interfaces/IDialogGenericData";
import {
  ConfirmationActionDialogComponent
} from "../../components/dialogs/confirmation-action-dialog/confirmation-action-dialog.component";
import {PermissionsService} from "../../services/permissions.service";

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent extends GamePageBase implements OnInit {

  displayedColumns: string[] = ['name', 'description', 'deactivate'];
  constructor(gameService: GameService,
              private matDialog: MatDialog,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private dialogFunctionService: DialogFunctionService,
              private errorHandlingService: ErrorHandlingService,
              private permissionsService: PermissionsService,
              private userService: UsersService) {
    super(gameService)
  }

  currentUser: IAdminQueryResult | null = null;

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((paramMapP) => {
      let userId = paramMapP.get('userId');
      if (userId) {
        this.fetchAdminUser(userId)
      }
    });
  }

  private fetchAdminUser(userIdP: string) {
    this.uiState = UIStateEnum.ShowLoading;
    this.userService.fetchAdminByAdminId(userIdP, this.activeGame.Id).subscribe({
      next: (user) => {
        this.currentUser = user;
        this.uiState = UIStateEnum.ShowData;
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    })
  }

  onBackToUsersClick() {
    let activeGame = this.gameService.activeGame().Id;
    this.router.navigateByUrl(`${activeGame}/manage-users`);
  }

  onUpdateUserRolesClick(chosenUserP: IAdminQueryResult) {
    const dialogRef = this.matDialog.open(UpdateUserRolesComponent, {
      data: chosenUserP,
      width: '650px'
    });

    dialogRef.afterClosed().subscribe(
      {
        next: (updateSuccessful: boolean) => {
          if (updateSuccessful) {
            this.fetchAdminUser(this.currentUser?.Id!);
          }
        }
      })
  }

  onRemoveUserRoleClick(roleToRemoveP: IRole) {
    this.dialogFunctionService.setCallback(() => {
      return this.permissionsService.removeUserRole({
        gameId: this.gameService.activeGame()?.Id!,
        roleIds: [roleToRemoveP.Id],
        targetAdminId: this.currentUser?.Id!
      })
    })

    const dialogData: IDialogGenericData = {
      title: 'Remove Role',
      message: `Are you sure that you want to remove the ${roleToRemoveP.Name} role from ${this.currentUser?.FirstName} ${this.currentUser?.LastName}`,
      successMessage: 'Successfully removed user role',
      dataCyTag: 'confirm-remove-role-button'
    };

    const dialogRef = this.matDialog.open(ConfirmationActionDialogComponent, {
      data: dialogData,
      width: '650px'
    });

    dialogRef.afterClosed().subscribe(
      {
        next: (updateSuccessful: boolean) => {
          if (updateSuccessful) {
            this.fetchAdminUser(this.currentUser?.Id!);
          }
        }
      })
  }
}
