import {Component} from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
import {IRecalculateGameEntry} from "../../../interfaces/IRecalculateGameEntry";

@Component({
  selector: 'app-entity-changes',
  templateUrl: './entity-changes.component.html',
  styleUrls: ['./entity-changes.component.css']
})
export class EntityChangesComponent implements ICellRendererAngularComp {

  public recalculateEntry!: IRecalculateGameEntry;

  constructor() {
  }

  agInit(params: ICellRendererParams): void {
    this.recalculateEntry = params.data;
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }
}
