import {
  CharityPayoutCharityInfoComponent
} from "./components/cell-renderers/charity-payout-charity-info/charity-payout-charity-info.component";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MakePaymentDialogComponent} from "./components/dialogs/make-payment-dialog/make-payment-dialog.component";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {ChooseGameInstanceComponent} from "./components/choose-game-instance/choose-game-instance.component";
import {MatInputModule} from "@angular/material/input";
import {DateAdapter, MAT_DATE_FORMATS, MatNativeDateModule} from "@angular/material/core";
import * as Sentry from "@sentry/angular-ivy";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatExpansionModule} from "@angular/material/expansion";
import {RecaptchaFormsModule, RecaptchaModule} from "ng-recaptcha";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatMenuModule} from "@angular/material/menu";
import {SharedModule} from "./shared/shared.module";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatTableModule} from "@angular/material/table";
import {ChoosePlayerComponent} from "./pages/choose-player/choose-player.component";
import {ManagePlayersComponent} from "./pages/manage-players/manage-players.component";
import {MatCardModule} from "@angular/material/card";
import {PlayerGameInfoComponent} from "./components/player-game-info/player-game-info.component";
import {MatTooltipModule} from "@angular/material/tooltip";
import {
  GameAdminSettingsComponent
} from "./components/cell-renderers/game-admin-settings/game-admin-settings.component";
import {WinnerPayoutsComponent} from "./pages/winner-payouts/winner-payouts.component";
import {APP_INITIALIZER, ErrorHandler, NgModule} from "@angular/core";
import {OAuthModule, OAuthStorage} from "angular-oauth2-oidc";
import {MatSelectModule} from "@angular/material/select";
import {MatIconModule} from "@angular/material/icon";
import {
  UpdateTicketActivationRendererComponent
} from "./components/cell-renderers/update-ticket-activation-renderer/update-ticket-activation-renderer.component";
import {Router} from "@angular/router";
import {GameBannerComponent} from "./components/game-banner/game-banner.component";
import {MatSidenavModule} from "@angular/material/sidenav";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ClipboardModule} from "@angular/cdk/clipboard";
import {FormatterComponent} from "./components/json-formatter/json-formatter.component";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {AdminAuditTableComponent} from "./components/audit-tables/admin-audit-table/admin-audit-table.component";
import {ManageGameComponent} from "./pages/manage-game/manage-game.component";
import {AppComponent} from "./app.component";
import {MatDialogModule} from "@angular/material/dialog";
import {PayWinnerButtonComponent} from "./components/cell-renderers/pay-winner-button/pay-winner-button.component";
import {DashboardComponent} from "./pages/dashboard/dashboard.component";
import {AddNewAdminComponent} from "./components/dialogs/add-new-admin/add-new-admin.component";
import {CharityPayoutsComponent} from "./pages/charity-payouts/charity-payouts.component";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {UpdateUserRolesComponent} from "./components/dialogs/update-user-roles/update-user-roles.component";
import {AgGridModule} from "ag-grid-angular";
import {PipesModule} from "./pipes/pipes.module";
import {LogoutPage} from "./pages/auth/logout/logout.page";
import {ManageUsersComponent} from "./pages/manage-users/manage-users.component";
import {DrawWinnerPageComponent} from "./pages/draw-winner/draw-winner-page.component";
import {SystemAuditTableComponent} from "./components/audit-tables/system-audit-table/system-audit-table.component";
import {PrettyJsonModule} from "angular2-prettyjson";
import {UpdateEmailAddressComponent} from "./pages/update-email-address/update-email-address.component";
import {PlayerAuditTableComponent} from "./components/audit-tables/player-audit-table/player-audit-table.component";
import {AppRoutingModule} from "./app-routing.module";
import {UserIsNotAdminPageComponent} from "./pages/user-is-not-admin-page/user-is-not-admin-page.component";
import {RecoverPasswordComponent} from "./pages/recover-password/recover-password.component";
import {MatTabsModule} from "@angular/material/tabs";
import {MatButtonModule} from "@angular/material/button";
import {ChooseGameComponent} from "./pages/choose-game/choose-game.component";
import {MatListModule} from "@angular/material/list";
import {CharityInfoComponent} from "./components/dialogs/charity-info/charity-info.component";
import {PlayWinnerDialogComponent} from "./components/dialogs/play-winner-dialog/play-winner-dialog.component";
import {AuditingDataDisplayComponent} from "./components/dialogs/auditing-data-display/auditing-data-display.component";
import {UpdatePasswordComponent} from "./pages/update-password/update-password.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatRadioModule} from "@angular/material/radio";
import {BrowserModule} from "@angular/platform-browser";
import {SideNavComponent} from "./components/side-nav/side-nav.component";
import {AuditingComponent} from "./pages/auditing/auditing.component";
import {CopyValueComponent} from "./components/cell-renderers/copy-value/copy-value.component";
import {
  OrgCausableSettingsComponent
} from "./components/cell-renderers/org-causable-settings/org-causable-settings.component";
import {HttpClientModule} from "@angular/common/http";
import {StandardGameReportComponent} from "./components/reporting/standard-game-report/standard-game-report.component";
import {ActiveGameInstancesModule} from "./pages/active-game-instances/active-game-instances.module";
import {LuxonDateAdapter, MatLuxonDateModule} from "@angular/material-luxon-adapter";
import {
  CharityCategoryChangeNameComponent
} from './components/dialogs/charity-category-change-name/charity-category-change-name.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {SpinnerOverlayComponent} from './shared/spinner-overlay/spinner-overlay.component';
import {DirectiveModule} from "./directives/directive.module";
import {UserProfileMenuComponent} from './components/user-profile-menu/user-profile-menu.component';
import {ReportsComponent} from './pages/reports/reports.component';
import {MatStepperModule} from "@angular/material/stepper";
import {MatChipsModule} from "@angular/material/chips";
import {UserDetailsComponent} from './pages/user-details/user-details.component';
import {ShowActiveComponent} from './components/cell-renderers/show-active/show-active.component';
import {NotAuthorizedComponent} from './pages/not-authorized/not-authorized.component';
import {
  ConfirmationActionDialogComponent
} from './components/dialogs/confirmation-action-dialog/confirmation-action-dialog.component';
import { MoveCharityCategoriesComponent } from './components/dialogs/move-charity-categories/move-charity-categories.component';
import { ComprehensiveReportByDateRangeComponent } from './components/reporting/comprehensive-report-by-date-range/comprehensive-report-by-date-range.component';
import { DateRangeReportingComponent } from './components/reporting/date-range-reporting/date-range-reporting.component';
import { LotteryLicenseReportComponent } from './components/reporting/lottery-license-report/lottery-license-report.component';
import { ManagePermissionsComponent } from './pages/manage-permissions/manage-permissions.component';
import {
  GenericAdminSettingsComponent
} from "./components/cell-renderers/generic-admin-settings/generic-admin-settings.component";
import { AddPermissionsToRoleComponent } from './components/dialogs/add-permissions-to-role/add-permissions-to-role.component';
import { AddNewRoleComponent } from './components/dialogs/add-new-role/add-new-role.component';
import {
  UpdateGenericGameSettingsComponent
} from "./components/dialogs/update-generic-game-settings/update-generic-game-settings.component";
import {
  UpdateSensitiveGameSettingsComponent
} from "./components/dialogs/update-sensitive-game-settings/update-sensitive-game-settings.component";
import { RecalculateGameTotalsComponent } from './components/dialogs/recalculate-game-totals/recalculate-game-totals.component';
import { EntityChangesComponent } from './components/cell-renderers/entity-changes/entity-changes.component';
import { UpdateBasicPlayerInfoComponent } from './components/dialogs/update-basic-player-info/update-basic-player-info.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MMM dd, yyyy',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'MMM dd, yyyy',
    monthYearA11yLabel: 'MMMM yyyy',
  },
};

export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SideNavComponent,
    LogoutPage,
    UserIsNotAdminPageComponent,
    StandardGameReportComponent,
    DrawWinnerPageComponent,
    ManageGameComponent,
    ChooseGameInstanceComponent,
    CharityPayoutsComponent,
    ChooseGameComponent,
    ManagePlayersComponent,
    ChoosePlayerComponent,
    MakePaymentDialogComponent,
    GameAdminSettingsComponent,
    CharityInfoComponent,
    CharityPayoutCharityInfoComponent,
    WinnerPayoutsComponent,
    PayWinnerButtonComponent,
    ManageUsersComponent,
    AddNewAdminComponent,
    PlayWinnerDialogComponent,
    PlayerGameInfoComponent,
    AuditingDataDisplayComponent,
    UpdateTicketActivationRendererComponent,
    UpdateUserRolesComponent,
    OrgCausableSettingsComponent,
    RecoverPasswordComponent,
    UpdatePasswordComponent,
    UpdateEmailAddressComponent,
    GameBannerComponent,
    AuditingComponent,
    PlayerAuditTableComponent,
    AdminAuditTableComponent,
    SystemAuditTableComponent,
    CopyValueComponent,
    FormatterComponent,
    CharityCategoryChangeNameComponent,
    SpinnerOverlayComponent,
    UserProfileMenuComponent,
    ReportsComponent,
    UserDetailsComponent,
    ShowActiveComponent,
    NotAuthorizedComponent,
    ConfirmationActionDialogComponent,
    MoveCharityCategoriesComponent,
    ComprehensiveReportByDateRangeComponent,
    DateRangeReportingComponent,
    LotteryLicenseReportComponent,
    ManagePermissionsComponent,
    GenericAdminSettingsComponent,
    AddPermissionsToRoleComponent,
    AddNewRoleComponent,
    UpdateGenericGameSettingsComponent,
    UpdateSensitiveGameSettingsComponent,
    RecalculateGameTotalsComponent,
    EntityChangesComponent,
    UpdateBasicPlayerInfoComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    HttpClientModule,
    AgGridModule,
    AppRoutingModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    PipesModule,
    OAuthModule.forRoot(),
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    MatAutocompleteModule,
    MatInputModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatRadioModule,
    MatTabsModule,
    MatTooltipModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    DragDropModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatLuxonDateModule,
    MatDatepickerModule,
    MatMenuModule,
    ClipboardModule,
    PrettyJsonModule,
    ActiveGameInstancesModule,
    MatCheckboxModule,
    DirectiveModule,
    MatStepperModule,
    MatChipsModule
  ],
  providers: [
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {provide: DateAdapter, useClass: LuxonDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {provide: OAuthStorage, useFactory: storageFactory},
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
