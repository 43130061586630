import {Component} from '@angular/core';
import {IGameQueryResult} from "../../interfaces/IGameQueryResult";
import {GameService} from "../../services/game.service";
import {AppConfigService} from "../../services/app-config.service";
import {IGameInstance} from "../../interfaces/IGameInstance";
import {MatDialog} from "@angular/material/dialog";
import {
  UpdateSensitiveGameSettingsComponent
} from "../../components/dialogs/update-sensitive-game-settings/update-sensitive-game-settings.component";
import {Observable} from "rxjs";
import {PermissionTypes} from "../../enum/PermissionTypes";
import {
  UpdateGenericGameSettingsComponent
} from "../../components/dialogs/update-generic-game-settings/update-generic-game-settings.component";
import {
  RecalculateGameTotalsComponent
} from "../../components/dialogs/recalculate-game-totals/recalculate-game-totals.component";

@Component({
  selector: 'app-manage-game',
  templateUrl: './manage-game.component.html',
  styleUrls: ['./manage-game.component.scss']
})
export class ManageGameComponent {

  public activeGame$: Observable<IGameQueryResult | undefined> = this.gameService.selectActiveGame$();
  public cdnRoot = this.appConfigService.cdnRoot();
  public chosenGameInstance: IGameInstance | null = null;
  permissionTypes = PermissionTypes;

  constructor(private gameService: GameService,
              private matDialog: MatDialog,
              private appConfigService: AppConfigService) {
  }

  public resetActiveGameInstance() {
    this.chosenGameInstance = null;
  }

  public onEditSensitiveSettingsClick() {
    this.matDialog.open(UpdateSensitiveGameSettingsComponent, {
      width: '650px'
    });
  }

  public onEditGenericSettingsClick() {
    this.matDialog.open(UpdateGenericGameSettingsComponent, {
      width: '650px'
    });
  }

  onRecalculateGameClick() {
    this.matDialog.open(RecalculateGameTotalsComponent, {
      width: '80vw'
    });
  }
}
