<p>Choose a date range for your report</p>
<form [formGroup]="comprehensiveDatesFromGroup">
  <mat-form-field class="full-width">
    <mat-label>Enter a date range</mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input matStartDate placeholder="Start date" formControlName="startDate">
      <input matEndDate placeholder="End date" formControlName="endDate">
    </mat-date-range-input>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
</form>

<div class="text-end">
  <button mat-raised-button (click)="onSubmit()" class="margin-top-med">Generate Reports</button>
</div>

<div *ngIf="showReports" class="margin-top-large">

  <mat-tab-group disableRipple dynamicHeight data-cy="report-tab-group">
    <mat-tab
      *hasPermission="permissionTypes.ViewComprehensiveGameReport"
      label="Comprehensive Report"
      labelClass="comprehensive-report-tab">
      <ng-template matTabContent>
        <app-comprehensive-report-by-date-range
          [dateRangesSetter]="dateRanges"></app-comprehensive-report-by-date-range>
      </ng-template>
    </mat-tab>
    <mat-tab
      *hasPermission="permissionTypes.ViewLotteryLicenseReport"
      label="Lottery License Report"
      labelClass="lottery-license-report-tab">
      <ng-template matTabContent>
        <app-lottery-license-report [dateRangesSetter]="dateRanges"></app-lottery-license-report>
      </ng-template>

    </mat-tab>
  </mat-tab-group>
</div>
