import {Component} from '@angular/core';
import {ReportingService} from "../../../services/reporting.service";
import {FormatCurrencyPipe} from "../../../pipes/format-currency.pipe";
import {PdfUtilityService} from "../../../services/pdf-utility.service";
import {DatePipe} from "@angular/common";
import {ReportBaseComponent} from "../../../pages/reports/report-base.component";
import {IRaffleSummaryData} from "../../../interfaces/reporting/IRaffleSummaryData";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {IPlayerWithTickets} from "../../../interfaces/reporting/IPlayerWithTickets";
import {IGameInstancePlayerNumber} from "../../../interfaces/player/IGameInstancePlayerNumber";
import {Content, TDocumentDefinitions} from "pdfmake/interfaces";
import {GameTypeEnum} from "../../../enum/GameTypeEnum";

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import {GameService} from "../../../services/game.service";
import {IGameInstanceShared} from "../../../interfaces/IGameInstanceShared";
import {IPlayer} from "../../../interfaces/player/IPlayer";
import {IGetRaffleReportResult} from "../../../interfaces/reporting/IGetRaffleReportResult";

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

export interface IRaffleTicketGroup {
  GroupId: string;
  Tickets: IGameInstancePlayerNumber[];
}

export interface AllGroupedTickets {
  Player: IPlayer;
  GroupedTickets: IRaffleTicketCategories;
}

export interface IRaffleTicketCategories {
  PaidAndPlayed: any;
  Voided: any;
  NotPaid: any;
}

@Component({
  selector: 'app-raffle-report',
  templateUrl: './raffle-report.component.html',
  styleUrls: ['../../../pages/reports/reports.scss', 'raffle-report.component.scss']
})
export class RaffleReportComponent extends ReportBaseComponent {

  public raffleReportSummaryData: IRaffleSummaryData | null = null;
  public raffleReport: IPlayerWithTickets[] = [];
  public allGroupedTickets: AllGroupedTickets[] = [];

  constructor(reportingService: ReportingService,
              currencyPipe: FormatCurrencyPipe,
              pdfUtilityService: PdfUtilityService,
              datePipe: DatePipe,
              gameService: GameService) {
    super(gameService, reportingService, currencyPipe, pdfUtilityService, datePipe)
  }

  generateReport(chosenGameInstanceP: IGameInstanceShared) {
    this.uiState = UIStateEnum.ShowLoading;
    this.reportingService.getRafflingReportData(chosenGameInstanceP.Id).subscribe({
      next: (reportP) => {
        if (reportP) {
          this.raffleReport = reportP.Players;
          this.groupAllTicketsByGroupId(reportP);
          this.raffleReportSummaryData = reportP.RaffleReportSummaryData;
        }
        this.uiState = UIStateEnum.ShowData;
      },
      error: () => {
        this.errorMessage = 'Looks there is no draw report data available for this game';
        this.uiState = UIStateEnum.ShowData;
      }
    });
  }

  private groupAllTicketsByGroupId(reportP: IGetRaffleReportResult) {
    let groupedTickets: AllGroupedTickets[] = [];

    reportP.Players.forEach((player) => {
      let organizedTicketsPerPlayer = this.organizeTickets(player.PlayerNumbers);
      groupedTickets.push({
        GroupedTickets: organizedTicketsPerPlayer,
        Player: player.Player
      })
    });

    this.allGroupedTickets = groupedTickets;
  }

  public organizeTickets(ticketsP: IGameInstancePlayerNumber[]): IRaffleTicketCategories {
    const organizedTickets: IRaffleTicketCategories = {
      PaidAndPlayed: [],
      NotPaid: [],
      Voided: []
    };

    const groupedTickets: { [key: string]: IRaffleTicketGroup } = {};

    ticketsP.forEach((ticketP: IGameInstancePlayerNumber) => {
      if (ticketP.Paid && !ticketP.Deactivated) {
        if (!groupedTickets[ticketP.GroupId]) {
          groupedTickets[ticketP.GroupId] = {
            GroupId: ticketP.GroupId,
            Tickets: []
          };
          organizedTickets.PaidAndPlayed.push(groupedTickets[ticketP.GroupId]);
        }
        groupedTickets[ticketP.GroupId].Tickets.push(ticketP);
      } else if (!ticketP.Paid && !ticketP.Deactivated) {
        if (!groupedTickets[ticketP.GroupId]) {
          groupedTickets[ticketP.GroupId] = {
            GroupId: ticketP.GroupId,
            Tickets: []
          };
          organizedTickets.NotPaid.push(groupedTickets[ticketP.GroupId]);
        }
        groupedTickets[ticketP.GroupId].Tickets.push(ticketP);
      } else if (ticketP.Deactivated) {
        if (!groupedTickets[ticketP.GroupId]) {
          groupedTickets[ticketP.GroupId] = {
            GroupId: ticketP.GroupId,
            Tickets: []
          };
          organizedTickets.Voided.push(groupedTickets[ticketP.GroupId]);
        }
        groupedTickets[ticketP.GroupId].Tickets.push(ticketP);
      }
    });

    return organizedTickets;
  }

  generatePDF() {
    const contentP: Content[] = [];

    contentP.push({
      text: `Auditing Report for Draw Date ${this.datePipe.transform(this.chosenGameInstance?.EndedOn, 'yyyy-MM-dd', 'UTC')}`,
      style: this.pdfUtilityService.PdfStyles.pageTitle,
      margin: [0, 0, 0, 8]
    });

    contentP.push({
      text: `${this.datePipe.transform(this.chosenGameInstance?.StartedOn, 'yyyy-MM-dd', 'UTC')} - ${this.datePipe.transform(this.chosenGameInstance?.EndedOn, 'yyyy-MM-dd')}`,
      style: this.pdfUtilityService.PdfStyles.subtitle,
      margin: [0, 0, 0, 16]
    });

    contentP.push({
      text: 'Ticket Summary:',
      style: this.pdfUtilityService.PdfStyles.sectionTitle,
      margin: [0, 0, 0, 8]
    });

    contentP.push({
      text: `Number of Registered Players: ${this.raffleReportSummaryData?.NumberOfRegisteredPlayers}`,
      fontSize: 11,
      margin: [0, 0, 0, 4]
    });

    contentP.push({
      text: `Number of Players in this Game: ${this.raffleReportSummaryData?.NumberOfPlayersInGame}`,
      fontSize: 11,
      margin: [0, 0, 0, 4]
    });

    contentP.push({
      text: `Number of Played Tickets: ${this.raffleReportSummaryData?.TotalPaidAndPlayed}`,
      fontSize: 11,
      margin: [0, 0, 0, 4]
    });

    if (this.activeGame?.Type === GameTypeEnum.GoldRush) {
      contentP.push({
        text: `Number of Not Paid Tickets: ${this.raffleReportSummaryData?.TotalNotPaid}`,
        fontSize: 11,
        margin: [0, 0, 0, 4]
      });
    }

    contentP.push({
      text: `Number of Voided Tickets: ${this.raffleReportSummaryData?.TotalVoided}`,
      fontSize: 11,
      margin: [0, 0, 0, 4]
    });

    contentP.push({
      text: `Price per ticket strip: ${this.currencyPipe.transform(this.raffleReportSummaryData!.GroupPrice)}`,
      fontSize: 11,
      margin: [0, 0, 0, 16]
    });

    contentP.push(this.pdfUtilityService.AddLineBreak());
    this.allGroupedTickets.forEach((allGroupedTicketsP) => {
      contentP.push({
        text: 'Player Info',
        style: this.pdfUtilityService.PdfStyles.sectionTitle,
        margin: [0, 0, 0, 8]
      });

      contentP.push({
        text: allGroupedTicketsP.Player.FirstName + ' ' + allGroupedTicketsP.Player.LastName,
        fontSize: 11
      });

      contentP.push({
        text: allGroupedTicketsP.Player.Email,
        fontSize: 11
      });

      contentP.push({
        text: allGroupedTicketsP.Player.Phone,
        fontSize: 11,
        margin: [0, 0, 0, 8]
      });

      contentP.push(this.pdfAddPaidAndPlayedTickets(allGroupedTicketsP.GroupedTickets.PaidAndPlayed));
      if (this.activeGame?.Type === GameTypeEnum.GoldRush) {
        contentP.push(this.pdfAddUnPaidTickets(allGroupedTicketsP.GroupedTickets.NotPaid));
      }
      contentP.push(this.pdfAddVoidedTickets(allGroupedTicketsP.GroupedTickets.Voided));
    });

    const docDef: TDocumentDefinitions = {
      pageMargins: [40, 40, 40, 40],
      footer: (currentPage, pageCount) => {
        return {
          text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
          style: this.pdfUtilityService.PdfStyles.footer
        };
      },
      content: contentP
    };

    const pdf = pdfMake.createPdf(docDef);

    pdf.open();
  }

  private pdfAddUnPaidTickets(unPlayedTicketsP: any[]): Content[] {
    const unPaidTicketContent: Content[] = [];

    unPaidTicketContent.push({
      text: 'Unpaid Tickets',
      bold: true,
      fontSize: 13,
      margin: [0, 0, 0, 8]
    });

    if (unPlayedTicketsP.length > 0) {

      unPlayedTicketsP.forEach((groupP: any) => {
        const ticketStripeIdRow: Content = {
          layout: 'lightHorizontalLines',
          table: {
            body: [
              [
                {
                  text: 'Ticket Strip Id: ' + groupP.GroupId,
                  bold: true,
                  fontSize: 11
                }
              ]
            ]
          },
          margin: [0, 0, 0, 4]
        };

        unPaidTicketContent.push(ticketStripeIdRow);

        const tableForTicketsContent: Content = {
          layout: 'lightHorizontalLines',
          table: {
            headerRows: 1,
            body: [
              [
                {
                  text: 'Ticket Number',
                  fontSize: 10
                },
                {
                  text: 'Assigned On',
                  fontSize: 10
                }
              ]
            ]
          },
          margin: [0, 0, 0, 16]
        };

        groupP.Tickets.forEach((ticket: any) => {
          tableForTicketsContent.table.body.push([
            ticket.TicketNumber, ticket.CreatedOn
          ]);
        })

        unPaidTicketContent.push(tableForTicketsContent);
      })

    } else {
      unPaidTicketContent.push({
        text: 'This player had no unpaid tickets in this draw',
        margin: [0, 0, 0, 16]
      });
    }

    return unPaidTicketContent;
  }

  private pdfAddPaidAndPlayedTickets(paidAndPlayedTickets: any[]): Content[] {
    const playedTicketContent: Content[] = [];

    playedTicketContent.push({
      text: 'Played Tickets',
      bold: true,
      fontSize: 13,
      margin: [0, 0, 0, 8]
    });

    if (paidAndPlayedTickets.length > 0) {

      paidAndPlayedTickets.forEach((groupP: any) => {
        const ticketStripeIdRow: Content = {
          layout: 'lightHorizontalLines',
          table: {
            body: [
              [
                {
                  text: 'Ticket Strip Id: ' + groupP.GroupId,
                  bold: true,
                  fontSize: 11
                }
              ]
            ]
          },
          margin: [0, 0, 0, 4]
        };
        playedTicketContent.push(ticketStripeIdRow);

        const tableForTicketsContent: Content = {
          layout: 'lightHorizontalLines',
          table: {
            headerRows: 1,
            body: [
              [
                {
                  text: 'Ticket Number',
                  fontSize: 10
                },
                {
                  text: 'Assigned On',
                  fontSize: 10
                },
                {
                  text: 'Purchased On',
                  fontSize: 10
                }
              ]
            ]
          },
          margin: [0, 0, 0, 16]
        };

        groupP.Tickets.forEach((ticket: any) => {
          tableForTicketsContent.table.body.push([
            ticket.TicketNumber, ticket.CreatedOn, ticket.PurchasedOn
          ]);
        })

        playedTicketContent.push(tableForTicketsContent);
      })

    } else {
      playedTicketContent.push({
        text: 'This player had no paid tickets in this draw',
        margin: [0, 0, 0, 16]
      });
    }

    return playedTicketContent;
  }

  private pdfAddVoidedTickets(voidedTickets: any[]): Content[] {
    const voidedTicketContent: Content[] = [];

    voidedTicketContent.push({
      text: 'Voided Tickets',
      bold: true,
      fontSize: 13,
      margin: [0, 0, 0, 8]
    });

    if (voidedTickets.length > 0) {

      voidedTickets.forEach((groupP: any) => {
        const ticketStripeIdRow: Content = {
          layout: 'lightHorizontalLines',
          table: {
            body: [
              [
                {
                  text: 'Ticket Strip Id: ' + groupP.GroupId,
                  bold: true,
                  fontSize: 11
                }
              ]
            ]
          },
          margin: [0, 0, 0, 4]
        };

        voidedTicketContent.push(ticketStripeIdRow);

        const tableForTicketsContent: Content = {
          layout: 'lightHorizontalLines',
          table: {
            headerRows: 1,
            body: [
              [
                {
                  text: 'Ticket Number',
                  fontSize: 10
                }
              ]
            ]
          },
          margin: [0, 0, 0, 16]
        };

        groupP.Tickets.forEach((ticket: any) => {
          tableForTicketsContent.table.body.push([
            ticket.TicketNumber
          ]);
        })

        voidedTicketContent.push(tableForTicketsContent);
      })

    } else {
      voidedTicketContent.push({
        text: 'This player had no voided tickets in this draw',
        margin: [0, 0, 0, 16]
      });
    }

    return voidedTicketContent;
  }
}
