<mat-toolbar>
  <mat-toolbar-row class="active-game-row" *ngIf="appConfig$ | async as orgConfig">
    <ng-container *ngIf="activeGame$ | async as activeGame else noGame">
      <img alt="game logo" [src]="orgConfig.cdnRoot + activeGame.CombinedLogoUrl">
      <span>{{activeGame.Name}} Admin App</span>
      <span class="env-banner" *ngIf="currentEnvironment">{{currentEnvironment}}</span>
      <span class="example-spacer"></span>
      <ng-container *ngIf="activeGameInstance$ | async as activeGameInstance">
        <div class="margin-right-med">
          <div class="banner-label">Next Draw</div>
          <div class="banner-value">{{endDateCountDown$ | async}}</div>
        </div>
        <div>
          <div class="banner-label">Current Pot</div>
          <div class="banner-value">{{activeGameInstance.PotTotal | formatCurrency}}</div>
        </div>
        <app-user-profile-menu></app-user-profile-menu>
      </ng-container>

    </ng-container>
    <ng-template #noGame>
      <h2>Causable Admin App</h2>
      <span class="example-spacer"></span>
      <app-user-profile-menu></app-user-profile-menu>
    </ng-template>
  </mat-toolbar-row>
</mat-toolbar>
