<div class="flex-row justify-center align-start margin-top-large">
  <mat-card class="flex-50">
    <mat-card-header>
      <mat-card-title class="text-center">Change your Password</mat-card-title>
    </mat-card-header>
    <mat-card-content class="text-center margin-top-large">
      <form [formGroup]="updatePasswordForm">
        <mat-form-field appearance="outline" class="full-width margin-bottom-small">
          <mat-label>Old Password</mat-label>
          <input
            data-cy="change-password-old-input"
            matInput
            [type]="showOldPassword ? 'text' : 'password'"
            formControlName="oldPassword"
            required
            placeholder="enter your old (existing) password"
          >
          <mat-error
            data-cy="old-password-error"
            *ngIf="oldPasswordControl.errors && (oldPasswordControl.dirty || oldPasswordControl.touched)">
            <ng-container *ngIf="oldPasswordControl.hasError('required')">
              <strong>required.</strong>
            </ng-container>
          </mat-error>
          <mat-icon matSuffix
                    (click)="showOldPassword = !showOldPassword">{{showOldPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field>

        <mat-form-field class="full-width margin-bottom-small" appearance="outline">
          <mat-label>New Password</mat-label>
          <input
            data-cy="change-password-new-input"
            matInput
            [type]="showNewPassword ? 'text' : 'password'"
            formControlName="newPassword"
            required
            placeholder="enter your new password"
          >
          <mat-error *ngIf="newPasswordControl.errors && (newPasswordControl.dirty || newPasswordControl.touched)"
                     data-cy="new-password-error">
            <ng-container *ngIf="newPasswordControl.hasError('required')">
              <strong>required.</strong>
            </ng-container>
            <ng-container *ngIf="newPasswordControl.hasError('maxlength')">
              cannot exceed <strong>50 chars.</strong>
            </ng-container>
            <ng-container *ngIf="newPasswordControl.hasError('minlength')">
              must be at least <strong>6 chars.</strong>
            </ng-container>
            <ng-container *ngIf="newPasswordControl.hasError('pattern')">
              must contain at least one <strong>uppercase</strong>, and one <strong>number.</strong>
            </ng-container>
          </mat-error>

          <mat-icon matSuffix
                    (click)="showNewPassword = !showNewPassword">{{showNewPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width margin-bottom-small">
          <mat-label>Confirm Password</mat-label>
          <input
            matInput
            data-cy="change-password-new-confirm-input"
            [type]="showConfirmPassword ? 'text' : 'password'"
            formControlName="confirmNewPassword"
            required
            placeholder="confirm your new password"
          >
          <mat-error
            data-cy="confirm-password-error"
            *ngIf="confirmNewPasswordControl.errors && (confirmNewPasswordControl.dirty || confirmNewPasswordControl.touched)">
            <ng-container *ngIf="confirmNewPasswordControl.hasError('required')">
              <strong>required.</strong>
            </ng-container>
            <ng-container *ngIf="confirmNewPasswordControl.hasError('confirmedValidator')">
              new and confirm passwords must match
            </ng-container>
          </mat-error>

          <mat-icon matSuffix
                    (click)="showConfirmPassword = !showConfirmPassword">{{showConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field>

      </form>

      <button mat-raised-button
              data-cy="change-password-confirm-button"
              class="login-button"
              (click)="updatePassword()">Update Password
      </button>
    </mat-card-content>
  </mat-card>
</div>
