export enum PermissionTypes {
  ViewGameSettings = 1,
  ModifyGameSettings = 2,
  ViewReports = 3,
  ViewCharities = 4,
  ModifyCharities = 5,
  ViewPlayerInfo = 6,
  ModifyPlayerInfo = 7,
  ViewAdminUsers = 8,
  ModifyAdminUsers = 9,
  ModifyPlayerNumbers = 10,
  ViewPlayerTransactions = 11,
  ViewGameSummaryReport = 12,
  ViewPlayerNumbers = 13,
  ViewAuditingReport = 14,
  ViewSalesPerGameReport = 15,
  ViewHourlyTicketSalesReport = 16,
  UpdatePlayerActivationStatus = 17,
  ViewComprehensiveGameReport = 18,
  ViewLotteryLicenseReport = 19,
  ViewRolesAndPermissions = 20,
  ModifyRolesAndPermissions = 21,
  ModifySensitiveGameSettings = 22,
  RecalculateGameTotals = 23,
  AdminUpdatePlayerBasicInfo = 24
}
