import {Component} from '@angular/core';
import {map, Observable} from "rxjs";
import {ColDef, RowClickedEvent} from "ag-grid-community";
import {AuditingService} from "../../../services/auditing.service";
import {AuditEventTables} from "../../../table-definitions/audit-event-tables";
import {IAdminAuditEvent} from "../../../interfaces/audit/IAdminAuditEvent";
import {FormControl, FormGroup} from "@angular/forms";
import {AuditTablesBase} from "../audit-tables-base";
import {DateService} from "../../../services/date.service";
import {AuditingDataDisplayComponent} from "../../dialogs/auditing-data-display/auditing-data-display.component";
import {EventSourceType} from "../../../enum/EventSourceType";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-admin-audit-table',
  templateUrl: './admin-audit-table.component.html',
  styleUrls: ['./admin-audit-table.component.css']
})
export class AdminAuditTableComponent extends AuditTablesBase {

  public adminAudits$: Observable<IAdminAuditEvent[]> = this.auditingService.adminAudits$.pipe(map((responseP) => {
    this.pagedAuditResponse = responseP;
    return responseP.Data;
  }));
  public adminAuditColDefs: ColDef[] = this.auditEventTables.adminAuditEventColDefs;
  public adminIdFormControl: FormControl = new FormControl('');
  public adminAuditFilterFormGroup = new FormGroup(
    {
      adminId: this.adminIdFormControl,
      eventTypeValue: this.eventTypeFormControl,
      fromDate: this.fromDateFormControl,
      toDate: this.toDateFormControl
    }
  );

  constructor(auditingService: AuditingService,
              dateService: DateService,
              matDialog: MatDialog,
              errorHandlingService: ErrorHandlingService,
              auditEventTables: AuditEventTables) {
    super(auditingService, dateService, matDialog, errorHandlingService, auditEventTables);
  }

  onRowSelected(adminAuditP: RowClickedEvent<IAdminAuditEvent>) {
    this.matDialog.open(AuditingDataDisplayComponent, {
      data: {
        audit: adminAuditP.data,
        eventSource: EventSourceType.AdminApp
      }
    })
  }

  onResetClick() {
    super.onResetClick();
    this.adminIdFormControl.setValue('');
    this.getPaginatedResponse(1);
  }

  getPaginatedResponse(pageNumberP: number): void {
    this.auditingService.retrieveAdminAudits({
      fromDate: this.fromDateFormControl.value,
      toDate: this.toDateFormControl.value,
      pageNumber: pageNumberP,
      pageSize: 15,
      eventTypeValue: this.eventTypeFormControl.value,
      adminId: this.adminIdFormControl.value,
    }).subscribe({
      next: () => {
        this.updatePaginator();
      },
      error: (err) => {
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    });
  }
}
