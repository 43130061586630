import {Component, OnInit} from '@angular/core';
import {PlayersService} from "../../services/players.service";
import {IPlayerPerGameQueryResult} from "../../interfaces/player/IPlayerPerGameQueryResult";
import {UIStateEnum} from "../../enum/UIStateEnum";
import {ActivatedRoute, Router} from "@angular/router";
import {GameService} from "../../services/game.service";
import {GamePageBase} from "../../shared/game-page-base";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {MatDialog} from "@angular/material/dialog";
import {PermissionTypes} from "../../enum/PermissionTypes";
import {DialogFunctionService} from "../../services/dialog-function.service";
import {IDialogGenericData} from "../../interfaces/IDialogGenericData";
import {
  ConfirmationActionDialogComponent
} from "../../components/dialogs/confirmation-action-dialog/confirmation-action-dialog.component";
import {
  UpdateBasicPlayerInfoComponent
} from "../../components/dialogs/update-basic-player-info/update-basic-player-info.component";

@Component({
  selector: 'app-manage-players',
  templateUrl: './manage-players.component.html',
  styleUrls: ['./manage-players.component.scss']
})
export class ManagePlayersComponent extends GamePageBase implements OnInit {

  public chosenPlayerInfo?: IPlayerPerGameQueryResult;
  public permissionTypes = PermissionTypes;
  protected readonly PermissionTypes = PermissionTypes;

  constructor(private activatedRoute: ActivatedRoute,
              private errorHandlingService: ErrorHandlingService,
              private dialogFunctionService: DialogFunctionService,
              private router: Router,
              private matDialog: MatDialog,
              gameService: GameService,
              private playerService: PlayersService) {
    super(gameService);
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((paramMapP) => {
      let playerId = paramMapP.get('playerId');
      if (playerId) {
        this.getPlayerGameInfo(playerId);
      }
    });
  }

  public onUpdateBasicInfoClick() {
    const dialog_ref = this.matDialog.open(UpdateBasicPlayerInfoComponent, {
      data: this.chosenPlayerInfo,
      width: '650px'
    });
    dialog_ref.afterClosed().subscribe({
      next: (res) => {
        if (res) {
          this.getPlayerGameInfo(this.chosenPlayerInfo!.Id);
        }
      }
    })
  }

  public onDeactivatePlayerClick() {
    this.dialogFunctionService.setCallback(() => {
      return this.playerService.deactivatePlayer({
        playerId: this.chosenPlayerInfo?.Id!,
        gameId: this.gameService.activeGame().Id
      })
    });

    if (this.chosenPlayerInfo) {
      let deactivatePlayerDialogData: IDialogGenericData = {
        title: 'Confirm Player Deactivation',
        message: `Please confirm that you would like to deactivate the following player`,
        playerData: {
          firstName: this.chosenPlayerInfo.FirstName,
          email: this.chosenPlayerInfo.Email,
          lastName: this.chosenPlayerInfo.LastName
        },
        dataCyTag: 'confirm-deactivate-player-button',
        successMessage: 'Successfully deactivated player',
        extraDetails: 'Deactivating a player will remove all of their numbers from all games, except any numbers already paid for for any active games. The user will no longer have access to any games.'
      };

      const dialogRef = this.matDialog.open(ConfirmationActionDialogComponent, {
        data: deactivatePlayerDialogData,
        width: '650px'
      });

      dialogRef.afterClosed().subscribe(
        {
          next: (updateSuccessful: boolean) => {
            if (updateSuccessful) {
              this.getPlayerGameInfo(this.chosenPlayerInfo?.Id!);
            }
          }
        })
    }
  }

  public onReactivatePlayerClick() {
    this.dialogFunctionService.setCallback(() => {
      return this.playerService.reactivatePlayer({
        playerId: this.chosenPlayerInfo?.Id!,
        gameId: this.gameService.activeGame().Id
      })
    });

    if (this.chosenPlayerInfo) {
      let reactivatePlayerDialogData: IDialogGenericData = {
        title: 'Confirm Player Reactivation',
        message: `Please confirm that you would like to reactivate the following player`,
        playerData: {
          firstName: this.chosenPlayerInfo.FirstName,
          email: this.chosenPlayerInfo.Email,
          lastName: this.chosenPlayerInfo.LastName
        },
        dataCyTag: 'confirm-reactivate-player-button',
        successMessage: 'Successfully reactivated player',
        extraDetails: 'Reactivating a player allows them to log back into any game, while their numbers remain deactivated. You can selectively reactivate numbers per game or strip using the "Reactivate Strip" feature.'
      };

      const dialogRef = this.matDialog.open(ConfirmationActionDialogComponent, {
        data: reactivatePlayerDialogData,
        width: '650px'
      });

      dialogRef.afterClosed().subscribe(
        {
          next: (updateSuccessful: boolean) => {
            if (updateSuccessful) {
              this.getPlayerGameInfo(this.chosenPlayerInfo?.Id!);
            }
          }
        })
    }
  }

  chooseNewPlayerClick() {
    let gameId = this.gameService.activeGame().Id;
    this.router.navigateByUrl(`${gameId}/player-search`);
  }

  getPlayerGameInfo(playerId: string) {
    const activeGameId = this.gameService.activeGame()?.Id;
    if (activeGameId) {
      this.uiState = UIStateEnum.ShowLoading;
      this.playerService.getPlayerGameInfo(playerId, activeGameId)
        .subscribe({
          next: (res) => {
            this.chosenPlayerInfo = res;
            this.uiState = UIStateEnum.ShowData;
          },
          error: (err) => {
            this.uiState = UIStateEnum.ShowData;
            this.errorHandlingService.displayPageLevelErrorMessage(err);
          }
        })
    }

  }

}
