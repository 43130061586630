import {Component, Input} from '@angular/core';
import {ColDef, GridApi, GridReadyEvent} from "ag-grid-community";
import {DatePipe} from "@angular/common";
import {ReportingService} from "../../../services/reporting.service";
import {IPlayerRetentionReportResult} from "../../../interfaces/reporting/IPlayerRetentionReportResult";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {GameService} from "../../../services/game.service";
import {DateService} from "../../../services/date.service";

@Component({
  selector: 'app-sales-per-game',
  templateUrl: './sales-per-game.component.html',
  styleUrls: ['./sales-per-game.component.scss']
})
export class SalesPerGameComponent {

  @Input() set setDefaultGameInstanceDrawDate(drawTimeP: Date) {
    this.reportingService.fetchSalesPerGameReport(this.gameService.activeGame().Id).subscribe({
      next: (reportResP) => {

        if (reportResP.length > 0) {
          this.possibleDrawDates = reportResP.map((reportP) => {
            return reportP.EndedOn;
          })
          this.allRetentionReports = reportResP;
          this.drawDate = drawTimeP;
          this.filterResultsPerGameDrawDate();
        }
        this.uiState = UIStateEnum.ShowData;
      },
      error: () => {
        this.errorMessage = 'There was an issue retrieving your retention report. ' +
          'Please try refreshing the page or reaching out to a system administrator.';
        this.uiState = UIStateEnum.ShowData;
      }
    })
  }

  public uiState = UIStateEnum.ShowLoading;
  public uiStateForTemplate = UIStateEnum;
  public allRetentionReports: IPlayerRetentionReportResult[] = [];
  public possibleDrawDates: Date[] = [];
  public filteredRetentionReportsByGameNumber: IPlayerRetentionReportResult[] = [];
  public errorMessage: string = '';
  public drawDate: Date | null = null;
  public retentionReportDefs: ColDef[] = [
    {
      headerName: 'Draw Date',
      resizable: true,
      sortable: true,
      width: 250,
      field: 'EndedOn',
      valueFormatter: params => {
        const convertedDate = this.datePipe.transform(params.value, 'MMM d, y HH:mm', 'UTC');
        return convertedDate ? convertedDate : '';
      }
    },
    {
      headerName: 'Started On',
      resizable: true,
      sortable: true,
      width: 250,
      field: 'StartedOn',
      valueFormatter: params => {
        const convertedDate = this.datePipe.transform(params.value, 'MMM d, y HH:mm', 'UTC');
        return convertedDate ? convertedDate : '';
      }
    },
    {
      headerName: 'New Registrations',
      resizable: true,
      wrapHeaderText: true,
      sortable: true,
      field: 'NewPlayerCount'
    },
    {
      headerName: 'New Players Who Played',
      resizable: true,
      wrapHeaderText: true,
      sortable: true,
      field: 'NewPlayersWhoPlayed'
    },
    {
      headerName: 'Returning Players',
      resizable: true,
      wrapHeaderText: true,
      sortable: true,
      field: 'ReturningPlayers'
    },
    {
      headerName: 'Total Players',
      resizable: true,
      wrapHeaderText: true,
      sortable: true,
      field: 'TotalPlayers'
    }
  ];

  public retentionGridApi!: GridApi<IPlayerRetentionReportResult>;

  constructor(private reportingService: ReportingService,
              private errorHandlingService: ErrorHandlingService,
              private gameService: GameService,
              private dateService: DateService,
              private datePipe: DatePipe) {
  }

  filterResultsPerGameDrawDate(overrideP: boolean = false) {
    if (this.drawDate && !overrideP) {
      this.filteredRetentionReportsByGameNumber = this.allRetentionReports.filter((reportP) => {
        return this.dateService.areDatesSameDay(reportP.EndedOn, this.drawDate!)
      });

      return;
    }

    this.drawDate = null;
    this.filteredRetentionReportsByGameNumber = this.allRetentionReports;
  }

  onRetentionGridReady(params: GridReadyEvent<IPlayerRetentionReportResult>) {
    this.retentionGridApi = params.api;
    this.retentionGridApi.sizeColumnsToFit({
      defaultMinWidth: 90,
    });
  }
}
