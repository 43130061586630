import { Component } from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
@Component({
  selector: 'app-show-active',
  templateUrl: './show-active.component.html',
  styleUrls: ['./show-active.component.css']
})
export class ShowActiveComponent implements ICellRendererAngularComp {

  public active!: any;

  constructor() {
  }

  agInit(params: ICellRendererParams): void {
    this.active = params.value;
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }
}
