<app-component-level-error-message [errorMessage]="errorMessage"></app-component-level-error-message>
<ng-container *ngIf="selectedGameInstance">
  <div class="flex-row-wrap justify-start gap-normal margin-bottom-med margin-top-med custom-card-border">

    <div class="detail-section">
      <div class="detail-label">Started On</div>
      <div class="detail-value">{{selectedGameInstance.StartedOn | date: 'MMM d, y' : 'UTC'}}</div>
    </div>

    <div class="detail-section">
      <div class="detail-label">Draw Date & Time</div>
      <div class="detail-value">{{selectedGameInstance.EndedOn | date: 'MMM d, y HH:mm' : 'UTC'}}</div>
    </div>

    <div class="detail-section">
      <div class="detail-label">Description</div>
      <div class="detail-value">{{selectedGameInstance.Description}}</div>
    </div>

    <div class="detail-section">
      <div class="detail-label">Status</div>
      <div class="detail-value" data-cy="game-instance-status">{{selectedGameInstance.State | gameInstanceStatus}}</div>
    </div>

    <div class="detail-section">
      <div class="detail-label">Price Per Strip</div>
      <div class="detail-value">{{selectedGameInstance.TicketGroupPrice | formatCurrency}}</div>
    </div>

    <div class="detail-section">
      <div class="detail-label">Number Of Tickets in Strip</div>
      <div class="detail-value" data-cy="number-tickets-per-strip">{{selectedGameInstance.TicketsInGroup}}</div>
    </div>
  </div>
</ng-container>
<div class="flex-row justify-center align-center">
  <div class="flex-50" *ngIf="!noTicketChartData">
    <canvas data-cy="tickets-chart" id="ticketsChart" style="max-width: 340px; max-height: 340px"></canvas>
  </div>
  <div class="flex-50 " *ngIf="!noManualAutoPlayChartData">
    <canvas data-cy="manual-auto-sales-chart" id="manualAutoPlayChart" style="max-width: 340px; max-height: 340px"></canvas>
  </div>
</div>
<div class="flex-row justify-space-between align-end">
  <div class="flex-50">
    <canvas data-cy="player-ticket-chart" id="playersTicketsLineChart"></canvas>
  </div>
  <div class="flex-50" *ngIf="!noSalesPerDayLineChartData">
    <canvas data-cy="sales-per-day-chart" id="salesPerDayLineChart"></canvas>
  </div>
</div>

<div class="flex-row justify-start align-center" *ngIf="!noCharitySalesBarGraphData">
  <canvas id="charitySalesBarGraph" style="max-width: 340px; max-height: 340px"></canvas>
</div>
<ng-template #readyForDraw>
  {{'This Game is ready to be drawn' | uppercase}}
</ng-template>
