<app-dialog-base [showLoadingSpinner]="uiState == uiStateForTemplate.ShowLoading">
  <span dialog-title>Assign Roles</span>
  <ng-container dialog-content>
    <div autofocus [tabindex]="0">Current Roles</div>
    <mat-chip-listbox aria-label="current roles for user">
      <mat-chip
        data-cy="assigning-roles-current-role-chip"
        *ngFor="let role of adminUser.Roles"
        [matTooltip]="role.Description"
        matTooltipPosition="below">
        {{role.Name}}
      </mat-chip>
    </mat-chip-listbox>
    <ng-container *ngIf="possibleRoles.length > 0 else noRoles">
      <p>Select roles to assign to this user. You may assign multiple roles to a user.</p>
      <form [formGroup]="newPermissionFormGroup">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Select Roles</mat-label>
          <mat-chip-grid #chipGrid aria-label="permission selection">
            <mat-chip-row
              data-cy="assigning-roles-role-to-add-chip"
              *ngFor="let role of rolesToAdd"
              (removed)="remove(role)">
              {{role.Name}}
              <button matChipRemove [attr.aria-label]="'remove ' + role">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </mat-chip-grid>
          <input #roleInput [formControl]="newRoleFormControl"
                 data-cy="assigning-roles-select-roles-input"
                 placeholder="start typing or select from list...."
                 [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
                 (matChipInputTokenEnd)="add($event)"/>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option
              data-cy="assigning-roles-select-options"
              *ngFor="let role of filteredPossibleRoles | async"
              [value]="role"
              [matTooltip]="role.Description"
              matTooltipPosition="right">
              {{role.Name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-error *ngIf="rolesToAdd.length === 0 && (newRoleFormControl.touched || newRoleFormControl.dirty)">You
          must choose at least one role to add
        </mat-error>
      </form>
    </ng-container>

    <ng-template #noRoles>
      <p>Looks like this user has all available roles</p>
    </ng-template>

    <div *ngIf="newRoleFormControl.value">New Permission:
      <span class="bold-text">{{newRoleFormControl.value}}</span>
    </div>
  </ng-container>
  <ng-container actions>
    <button
      data-cy="confirm-update-user-roles-button"
      mat-button
      class="primary-button"
      [disabled]="uiState === uiStateForTemplate.ShowLoading"
      (click)="updateUserPermissions()">Update
    </button>
  </ng-container>
</app-dialog-base>
