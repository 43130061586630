import {Component, Inject, OnInit} from '@angular/core';
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {IAuditEvent} from "../../../interfaces/audit/IAuditEvent";
import {AuditingService} from "../../../services/auditing.service";
import {EventSourceType} from "../../../enum/EventSourceType";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-auditing-data-display',
  templateUrl: './auditing-data-display.component.html',
  styleUrls: ['./auditing-data-display.component.scss']
})
export class AuditingDataDisplayComponent implements OnInit {

  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;
  public metaData: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
                audit: IAuditEvent,
                eventSource: EventSourceType
              },
              private errorHandlingService: ErrorHandlingService,
              private auditingService: AuditingService) {
  }

  private metaDataForEvent() {
    this.auditingService.retrieveMetaDataForAuditEvent(this.data.eventSource, this.data.audit.Id).subscribe({
      next: (res) => this.metaData = res,
      error: (err) => {
        this.errorHandlingService.displayDialogLevelErrorMessage(err);
      }
    });
  }

  ngOnInit(): void {
    this.metaDataForEvent();
  }
}
