import {Component, Input} from '@angular/core';
import {ErrorHandlingService} from "../../../services/error-handling.service";

@Component({
  selector: 'app-dialog-base',
  templateUrl: './dialog-base.component.html',
  styleUrls: ['./dialog-base.component.css']
})
export class DialogBaseComponent {

  @Input()
  public showLoadingSpinner: boolean = false;

  constructor(private errorHandlingService: ErrorHandlingService) {
    this.errorHandlingService.clearDialogErrorMessages();
  }

}
