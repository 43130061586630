<app-dialog-base [showLoadingSpinner]="uiState == uiStateForTemplate.ShowLoading">
  <span dialog-title>Recalculate Game Totals</span>

  <ng-container dialog-content>
    <p *ngIf="!recalculationSubmitted">recalculating tool will assess all payments and game instance totals and show a
      summary of suggested changes</p>
    <p *ngIf="recalculationCompleted">
      All recalculations have been submitted successfully!
    </p>
    <p *ngIf="recalculationSubmitted && groupedEntries.length == 0">
      No changes are needed.
    </p>
    <mat-tab-group *ngIf="groupedEntries.length > 0">
      <mat-tab *ngFor="let entry of groupedEntries; let i = index" [label]="entry.EntityName">
        <ng-template matTabContent>
          <ag-grid-angular
            class="ag-theme-alpine margin-top-med large-table"
            [tooltipShowDelay]=0
            overlayNoRowsTemplate="<span>No entries found</span>"
            [rowData]="entry.Entries"
            [pagination]="true"
            [domLayout]="'autoHeight'"
            [columnDefs]="changeColDefs">
          </ag-grid-angular>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
  <ng-container actions>
    <button mat-button
            class="primary-button"
            (click)="onRecalculateGameClick()">Recalculate
    </button>
    <button mat-button
            *ngIf="recalculationSubmitted && groupedEntries.length > 0"
            class="primary-button"
            (click)="onSubmitChanges()">Approve/Commit Updates
    </button>
  </ng-container>
</app-dialog-base>
