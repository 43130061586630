<ng-container *hasPermission="permissionTypes.ModifyPlayerNumbers">
  <button
    data-cy="deactivate-ticket-strip-button"
    mat-button
    class="red-text"
    (click)="onDeactivateClick()"
    *ngIf="rowData.Active">
    <mat-icon>do_disturb</mat-icon>
    Deactivate Strip
  </button>
  <button
    data-cy="reactivate-ticket-strip-button"
    mat-button
    class="green-text"
    color="primary"
    (click)="onReactivateClick()"
    *ngIf="!rowData.Active">
    Reactivate Strip
    <mat-icon>refresh</mat-icon>
  </button>
</ng-container>

