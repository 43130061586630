import {Component} from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
import {MatDialog} from "@angular/material/dialog";
import {IPlayerNumberGroupRow} from "../../../interfaces/player/IPlayerNumberGroupRow";
import {PermissionTypes} from "../../../enum/PermissionTypes";
import {DialogFunctionService} from "../../../services/dialog-function.service";
import {IDeactivatePlayerNumberGroup} from "../../../interfaces/player/IDeactivatePlayerNumberGroup";
import {GameService} from "../../../services/game.service";
import {PlayersService} from "../../../services/players.service";
import {IDialogGenericData} from "../../../interfaces/IDialogGenericData";
import {
  ConfirmationActionDialogComponent
} from "../../dialogs/confirmation-action-dialog/confirmation-action-dialog.component";
import {IReactivatePlayerNumberGroup} from "../../../interfaces/player/IReactivatePlayerNumberGroup";

@Component({
  selector: 'app-update-ticket-activation-renderer',
  templateUrl: './update-ticket-activation-renderer.component.html',
  styleUrls: ['./update-ticket-activation-renderer.component.scss']
})
export class UpdateTicketActivationRendererComponent implements ICellRendererAngularComp {

  public rowData!: IPlayerNumberGroupRow;
  public permissionTypes = PermissionTypes;

  constructor(private matDialog: MatDialog,
              private gameService: GameService,
              private playerService: PlayersService,
              private dialogFunctionService: DialogFunctionService) {
  }


  agInit(params: ICellRendererParams): void {
    this.rowData = params.data;
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }

  onDeactivateClick() {
    this.dialogFunctionService.setCallback(() => {
      let deactivatePlayerCommand: IDeactivatePlayerNumberGroup = {
        PlayerId: this.rowData.PlayerId,
        PlayerNumberIds: this.rowData.TicketNumberIds,
        GameId: this.gameService.activeGame().Id
      };

      return this.playerService.deactivatePlayerNumbersByGroup(deactivatePlayerCommand)
    });

    this.deactivateTicketStrip();
  }

  deactivateTicketStrip() {
    let deactivateDialogData: IDialogGenericData = {
      title: 'Confirm Deactivate Ticket Strip',
      message: `Confirm that you wish to deactivate the following ticket strip with numbers: ${this.rowData.TicketNumbers}`,
      successMessage: 'Successfully deactivated ticket strip',
      dataCyTag: 'confirm-deactivate-numbers'
    };

    const dialogRef = this.matDialog.open(ConfirmationActionDialogComponent, {
      data: deactivateDialogData,
      width: '650px'
    });

    dialogRef.afterClosed().subscribe({
      next: (confirmedP) => {
        if (confirmedP) {
          this.playerService.fetchUpdatedTicketNumbersSubject.next(true);
        }
      }
    })
  }

  onReactivateClick() {
    this.dialogFunctionService.setCallback(() => {
      let reactivatePlayerCommand: IReactivatePlayerNumberGroup = {
        PlayerId: this.rowData.PlayerId,
        PlayerNumberIds: this.rowData.TicketNumberIds,
        GameId: this.gameService.activeGame().Id
      };

      return this.playerService.reactivatePlayerNumbersByGroup(reactivatePlayerCommand);
    })

    this.reactivateTicketStrip();
  }

  reactivateTicketStrip() {
    let reactivateDialogData: IDialogGenericData = {
      title: 'Confirm Reactivate Ticket Strip',
      message: `Confirm that you wish to reactivate the following ticket strip with numbers: ${this.rowData.TicketNumbers}`,
      successMessage: 'Successfully reactivated ticket strip',
      dataCyTag: 'confirm-reactivate-numbers'
    };

    const dialogRef = this.matDialog.open(ConfirmationActionDialogComponent, {
      data: reactivateDialogData,
      width: '650px'
    });

    dialogRef.afterClosed().subscribe({
      next: (confirmedP) => {
        if (confirmedP) {
          this.playerService.fetchUpdatedTicketNumbersSubject.next(true);
        }
      }
    })
  }
}
