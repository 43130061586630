<div class="text-center" *hasPermission="permissionTypes.ModifyAdminUsers">
  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon class="menu-button"
              data-cy="show-admin-settings-menu"
              matTooltipPosition="right"
              matTooltip="open admin user menu"
    >more_horiz
    </mat-icon>
  </button>
</div>
<mat-menu #menu="matMenu">
  <button mat-menu-item
          data-cy="navigate-to-admin-details"
          (click)="onViewDetailsClick()">
    <mat-icon class="dark-grey menu-icon">settings</mat-icon>
    <span class="dark-grey menu-text">View Details</span>
  </button>

  <mat-divider></mat-divider>
  <button mat-menu-item
          data-cy="assign-user-roles-menu-option"
          (click)="onUpdateUserRolesClick()">
    <mat-icon class="dark-grey menu-icon">manage_accounts</mat-icon>
    <span class="dark-grey menu-text">Assign Roles</span>
  </button>
  <button mat-menu-item
          (click)="onReactiveUserClick()"
          data-cy="reactivate-user-menu-option"
          *ngIf="!adminUser.Active">
    <mat-icon class="dark-grey">refresh</mat-icon>
    <span class="dark-grey menu-text">Reactivate</span>
  </button>
  <button mat-menu-item
          (click)="onResendInviteClick()"
          data-cy="resend-user-invite-menu-option"
          *ngIf="!adminUser.ConfirmedOn">
    <mat-icon class="dark-grey">send</mat-icon>
    <span class="dark-grey menu-text">Resend Invite</span>
  </button>
  <button mat-menu-item
          (click)="onDeactivateUserClick()"
          data-cy="deactivate-user-menu-option"
          *ngIf="adminUser.Active">
    <mat-icon class="red-text">do_disturb</mat-icon>
    <span class="red-text menu-text">Deactivate</span>
  </button>

</mat-menu>
