import {Component} from '@angular/core';
import {Observable} from "rxjs";
import {GameInstanceService} from "../../services/game-instance.service";
import {IDrawNumberResponse} from "../../interfaces/IDrawNumberResponse";
import {IManualDrawWinnerResults} from "../../interfaces/IManualDrawWinnerResults";
import {GamePageBase} from "../../shared/game-page-base";
import {GameService} from "../../services/game.service";
import {UIStateEnum} from "../../enum/UIStateEnum";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {GameInstanceStatuses} from "../../enum/GameInstanceStatusEnum";
import {IActiveGameInstance} from "../../interfaces/IActiveGameInstance";

@Component({
  selector: 'app-draw-winner',
  templateUrl: './draw-winner-page.component.html',
  styleUrls: ['./draw-winner-page.component.scss']
})
export class DrawWinnerPageComponent extends GamePageBase {

  public endDatePassedButNotReadyMessage: string = 'The game is now over! Just give us a couple of minuites to get ready for the draw!';
  public showEndDatePassedButNotReadyMessage: boolean = false;
  public activeGameInstance$: Observable<IActiveGameInstance> = this.gameInstanceService.selectActiveGameInstance();
  public winnerResponse?: IManualDrawWinnerResults;

  public gameInstanceStates = GameInstanceStatuses;

  constructor(private gameInstanceService: GameInstanceService,
              private errorHandlingService: ErrorHandlingService,
              gameService: GameService) {
    super(gameService);
    this.listenForGameEndedEvent();
  }

  private listenForGameEndedEvent() {
    this.gameInstanceService.gameInstanceHasEnded$.subscribe({
      next: (gameInstanceHasEnded) => {
        if (gameInstanceHasEnded) {
          // this is for when the timer is up, but the game is still not in the ready for draw state
          this.showEndDatePassedButNotReadyMessage = true;
        }
      }
    })
  }

  drawWinner() {
    this.uiState = UIStateEnum.ShowLoading;

    this.gameInstanceService.drawWinner(this.gameInstanceService.activeGameInstanceId()).subscribe({
      next: (responseP: IDrawNumberResponse) => {
        if (responseP.GameInstanceWinnerDetails) {
          this.winnerResponse = responseP.GameInstanceWinnerDetails;
          this.uiState = UIStateEnum.ShowData;
        }
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    })
  }

}
