<app-dialog-base [showLoadingSpinner]="uiState == uiStateEnumForTemplate.ShowLoading">
  <span dialog-title>Add Charities</span>
  <ng-container dialog-content>
    <form
      [formGroup]="createCharityForm"
      *ngIf="uiState === uiStateEnumForTemplate.ShowData">
      <p>Choose the category you would like to add charities to:</p>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Charity Category</mat-label>
        <mat-select
          data-cy="add-charity-category-select"
          formControlName="categoryControl">
          <mat-option [value]="null"> ----</mat-option>
          <mat-option data-cy="add-charity-category-option" *ngFor="let category of charityCategories"
                      [value]="category">
            {{category.Name}}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="createCharityForm.controls['categoryControl'].hasError('required')">
          category is <span class="bold-text">required</span>
        </mat-error>
      </mat-form-field>

      <small class="red-text" *ngIf="createCharityForm.controls.categoryControl.value?.HighestCharitySortOrder === 0">
        Note*: The chosen category currently has no associated charities. The category will not appear in the web
        application until a charity has been linked to it.
      </small>

      <p *ngIf="!createCharityForm.controls['categoryControl'].valid" class="text-end">Can't find your category?
        <button mat-flat-button (click)="openAddNewCategoryDialog()" data-cy="add-new-charity-category-button">
          <span class="text-underline">ADD NEW CATEGORY</span>
        </button>
      </p>

      <ng-container *ngIf="createCharityForm.controls['categoryControl'].valid">
        <p>Enter new charity name and then click "Add to request". Multiple charities can be added to one request.</p>
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Enter a new charity name</mat-label>
          <input
            data-cy="new-charity-name-input"
            formControlName="charityNameControl"
            type="text"
            matInput
            placeholder="enter charity name">
          <mat-error *ngIf="createCharityForm.controls['charityNameControl'].hasError('notAddedToRequest')">make sure
            you click "Add to request"
          </mat-error>

        </mat-form-field>
        <button class="float-right"
                mat-raised-button
                data-cy="add-charity-to-request-button"
                [disabled]="createCharityForm.controls.charityNameControl.value === ''" (click)="addToCharityList()">
          <mat-icon>add</mat-icon>
          Add to request
        </button>
      </ng-container>
    </form>
    <div *ngIf="listOfNewCharities.length > 0">
      <h3>New Charities</h3>
      <div *ngFor="let charity of listOfNewCharities" class="flex-row justify-space-between align-center">
        <div>
          <span class="margin-left-med" data-cy="new-charity-name-from-request">{{charity.name}}</span>
        </div>
        <button matSuffix
                data-cy="remove-new-charity-name-from-request-button"
                mat-icon-button
                aria-label="remove from request"
                matTooltip="remove from request"
                (click)="removeFromCharityList(charity)">
          <mat-icon [matTooltip]="'click here to remove from list'">remove</mat-icon>
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container actions>
    <button
      mat-button
      data-cy="save-new-charities-button"
      color="primary"
      [disabled]="uiState == uiStateEnumForTemplate.ShowLoading"
      (click)="submitNewCharities()">Save
    </button>
  </ng-container>
</app-dialog-base>
