import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {SnackbarService} from "../../../services/snackbar.service";
import {GameService} from "../../../services/game.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {FormControl, FormGroup} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {IRole} from "../../../interfaces/IRole";
import {map, Observable, of, startWith} from "rxjs";
import {IAdminQueryResult} from "../../../interfaces/IAdminQueryResult";
import {PermissionsService} from "../../../services/permissions.service";

@Component({
  selector: 'app-update-user-roles',
  templateUrl: './update-user-roles.component.html',
  styleUrls: ['./update-user-roles.component.css']
})
export class UpdateUserRolesComponent implements OnInit {
  @ViewChild('roleInput') roleInput!: ElementRef<HTMLInputElement>;
  public uiState: UIStateEnum = UIStateEnum.ShowLoading;
  public uiStateForTemplate = UIStateEnum;
  public possibleRoles: IRole[] = [];
  public filteredPossibleRoles: Observable<IRole[] | null> = of(null);
  public rolesToAdd: IRole[] = [];
  public newRoleFormControl: FormControl<string | null> = new FormControl<string | null>(
    null
  );
  public newPermissionFormGroup: FormGroup = new FormGroup({
    newPermission: this.newRoleFormControl
  })

  constructor(private snackBarService: SnackbarService,
              private permissionService: PermissionsService,
              private errorHandlingService: ErrorHandlingService,
              private gameService: GameService,
              private matDialogRef: MatDialogRef<UpdateUserRolesComponent>,
              @Inject(MAT_DIALOG_DATA) public adminUser: IAdminQueryResult) {
    this.filteredPossibleRoles = this.newRoleFormControl.valueChanges.pipe(
      startWith(null),
      map((role: string | null) => {
        return (role ? this._filter(role) : this.possibleRoles.slice());
      }),
    );
  }

  ngOnInit(): void {
    this.permissionService.fetchPossibleRoles(this.gameService.activeGame().Id).subscribe({
      next: (rolesP) => {
        this.possibleRoles = rolesP.filter((role) =>
          !this.adminUser.Roles.some(userRole => userRole.Id === role.Id)
        );

        this.uiState = UIStateEnum.ShowData;
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayDialogLevelErrorMessage(err);
      }
    })
  }

  private _filter(value: any): IRole[] {
    let filterValue = '';
    if (typeof value === 'string') {
      filterValue = value.toLowerCase();
    } else {
      filterValue = value.Name.toLowerCase();
    }
    return this.possibleRoles.filter(role => role.Name.toLowerCase().includes(filterValue));

  }

  remove(role: IRole): void {
    const index = this.rolesToAdd.indexOf(role);

    if (index >= 0) {
      this.rolesToAdd.splice(index, 1);
    }
  }

  add(event: any): void {
    const value = (event.value || '').trim();

    if (value) {
      this.rolesToAdd.push(value);
    }

    event.chipInput!.clear();
    this.newRoleFormControl.setValue(null);
  }

  selected(event: any): void {
    this.rolesToAdd.push(event.option.value);
    this.roleInput.nativeElement.value = '';
    this.newRoleFormControl.setValue(null);
  }

  updateUserPermissions() {
    if (this.rolesToAdd.length === 0) {
      this.newRoleFormControl.markAllAsTouched();
      return;
    }

    this.uiState = UIStateEnum.ShowLoading;
    this.permissionService.addNewUserRole({
      targetAdminId: this.adminUser?.Id,
      roleIds: this.rolesToAdd.map((role) => role.Id),
      gameId: this.gameService.activeGame()?.Id!,
      adminEmail: this.adminUser.Email,
      adminFirstName: this.adminUser.FirstName,
      adminLastName: this.adminUser.LastName,
      gameName: this.gameService.activeGame().Name,
      redirectUrl: `${window.location.origin}/login`
    }).subscribe({
      next: () => {
        this.matDialogRef.close(true);
        this.snackBarService.openSuccessfulSnackBar('Successfully added new user role(s)');
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayDialogLevelErrorMessage(err);
      }
    })
  }
}
