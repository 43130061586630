import {Component, OnInit} from '@angular/core';
import {UIStateEnum} from "../../enum/UIStateEnum";
import {FormControl, FormGroup} from "@angular/forms";
import {ColDef, GridApi, GridReadyEvent} from "ag-grid-community";
import {GameService} from "../../services/game.service";
import {IPlayer} from "../../interfaces/player/IPlayer";
import {PlayersService} from "../../services/players.service";
import {IPlayerSearchParams} from "../../interfaces/player/IPlayerSearchParams";
import {Router} from "@angular/router";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {PhoneFormatPipe} from "../../pipes/phone-format.pipe";

@Component({
  selector: 'app-choose-player',
  templateUrl: './choose-player.component.html',
  styleUrls: ['./choose-player.component.scss']
})
export class ChoosePlayerComponent implements OnInit {

  public uiState: UIStateEnum = UIStateEnum.ShowLoading;
  public uiStateEnumForTemplate = UIStateEnum;
  public phoneNumberControl: FormControl<string | null> = new FormControl<string| null>('');
  public playerSearchForm = new FormGroup(
    {
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      email: new FormControl(''),
      phoneNumber: this.phoneNumberControl,
    }
  );
  public players: IPlayer[] = [];
  public columnDefs: ColDef[] = [
    {
      headerName: 'Id',
      field: 'Id',
      resizable: true,
      sortable: true
    },
    {
      headerName: 'First Name',
      field: 'FirstName',
      resizable: true,
      sortable: true
    },
    {
      headerName: 'Last Name',
      field: 'LastName',
      resizable: true,
      sortable: true
    },
    {
      headerName: 'Email',
      field: 'Email',
      resizable: true,
      sortable: true
    },
    {
      headerName: 'Phone',
      field: 'Phone',
      valueFormatter: params => {
        return this.phoneFormatPipe.transform(params.value)
      },
      resizable: true,
      sortable: true
    },
  ];

  private gridApi!: GridApi<IPlayer>;

  constructor(private gameService: GameService,
              private playerService: PlayersService,
              private phoneFormatPipe: PhoneFormatPipe,
              private errorHandlingService: ErrorHandlingService,
              private router: Router) {
    this.phoneNumberControl.valueChanges.subscribe((value: string | null) => {
      if(value) {
        const formattedValue = this.phoneFormatPipe.transform(value);
        this.phoneNumberControl.setValue(formattedValue, { emitEvent: false });
        return;
      }
    });
  }

  ngOnInit(): void {
    this.retrieveUsersWithSearchParams({
      gameId: this.gameService.activeGame()?.Id!,
    });
  }

  private retrieveUsersWithSearchParams(paramsP: IPlayerSearchParams) {
    this.playerService.getPlayersBySearchParams(paramsP).subscribe({
      next: (res) => this.players = res,
      error: (error) => {
        this.errorHandlingService.displayPageLevelErrorMessage(error);
      }
    });
  }

  onResetClick() {
    this.playerSearchForm.reset();
    this.retrieveUsersWithSearchParams({
      gameId: this.gameService.activeGame()?.Id!,
    });
  }

  onFormSubmit() {
    if (!this.playerSearchForm.valid) {
      this.playerSearchForm.markAllAsTouched();
      return;
    }

    let searchParams: IPlayerSearchParams = {
      email: this.playerSearchForm.controls.email.value ? this.playerSearchForm.controls.email.value : '',
      gameId: this.gameService.activeGame()?.Id!,
      lastName: this.playerSearchForm.controls.lastName.value ? this.playerSearchForm.controls.lastName.value : '',
      firstName: this.playerSearchForm.controls.firstName.value ? this.playerSearchForm.controls.firstName.value : '',
      phoneNumber: this.phoneNumberControl.value ? this.phoneNumberControl.value?.replace(/-/g, '') : ''
    }
    this.retrieveUsersWithSearchParams(searchParams);
  }

  onGridReady(params: GridReadyEvent<IPlayer>) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit({
      defaultMinWidth: 100,
    });
  }

  onRowSelected(chosenPlayerP: any) {
    let player: IPlayer = chosenPlayerP.data;
    const gameId = this.gameService.activeGame()?.Id;

    if (player) {
      this.router.navigateByUrl(`${gameId}/manage-player/${player.Id}`);
    }
  }
}
