import {Component, Input} from '@angular/core';
import {ReportingService} from "../../../services/reporting.service";
import {GameService} from "../../../services/game.service";
import {IComprehensiveReport} from "../../../interfaces/reporting/IComprehensiveReport";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {ColDef, ColumnApi, GridReadyEvent} from "ag-grid-community";
import {FormatCurrencyPipe} from "../../../pipes/format-currency.pipe";
import {IDateRanges} from "../../../interfaces/reporting/IDateRanges";
import {Content, TDocumentDefinitions} from "pdfmake/interfaces";
import * as pdfMake from "pdfmake/build/pdfmake";
import {ICharityContributionsBreakdown} from "../../../interfaces/ICharityContributionsBreakdown";
import {PdfUtilityService} from "../../../services/pdf-utility.service";
import {DatePipe} from "@angular/common";
import {IPdfSorting} from "../../../interfaces/reporting/IPdfSorting";

@Component({
  selector: 'app-comprehensive-report-by-date-range',
  templateUrl: './comprehensive-report-by-date-range.component.html',
  styleUrls: ['../../../pages/reports/reports.scss', 'comprehensive-report-by-date-range.component.scss']
})
export class ComprehensiveReportByDateRangeComponent {

  @Input() set dateRangesSetter(valueP: IDateRanges) {
    this.dateRanges = valueP;
    this.reportingService.getComprehensiveGameReportData(
      this.gameService.activeGame().Id,
      valueP.StartDate,
      valueP.EndDate
    ).subscribe({
      next: (comprehensiveReportResP) => {
        this.comprehensiveReport = comprehensiveReportResP;
        this.uiState = UIStateEnum.ShowData;
      },
      error: () => {
        this.errorMessage = 'There was an issue retrieving your retention report. ' +
          'Please try refreshing the page or reaching out to a system administrator.';
        this.uiState = UIStateEnum.ShowData;
      }
    })
  }

  public groupBreakdownReportDefs: ColDef[] = [
    {
      headerName: 'Group',
      resizable: true,
      sortable: true,
      field: 'Name',
      flex: 1
    },
    {
      headerName: 'Total Amount',
      resizable: true,
      sortable: true,
      field: 'CharityContribution',
      flex: 1,
      valueFormatter: params => {
        const converted = this.currencyPipe.transform(params.value);
        return converted ? converted : '';
      }
    },
    {
      headerName: 'Number of Players',
      resizable: true,
      wrapHeaderText: true,
      sortable: true,
      flex: 1,
      field: 'TotalNumberOfPlayers'
    },
    {
      headerName: 'Number of Tickets',
      resizable: true,
      wrapHeaderText: true,
      sortable: true,
      flex: 1,
      field: 'TotalNumberOfTickets'
    }
  ];

  public uiState = UIStateEnum.ShowLoading;
  public uiStateForTemplate = UIStateEnum;
  public errorMessage = '';
  public comprehensiveReport!: IComprehensiveReport;
  public dateRanges!: IDateRanges;
  public columnApi!: ColumnApi;

  constructor(private reportingService: ReportingService,
              private currencyPipe: FormatCurrencyPipe,
              private pdfUtilityService: PdfUtilityService,
              private datePipe: DatePipe,
              private gameService: GameService) {
  }

  onGridReady(params: GridReadyEvent<ICharityContributionsBreakdown>) {
    this.columnApi = params.columnApi;
  }

  generatePDF() {
    const contentP: Content[] = [];
    if (this.comprehensiveReport) {

      contentP.push({
        text: `Date Range (UTC): ${this.datePipe.transform(this.dateRanges?.StartDate, 'yyyy-MM-dd', 'UTC')} - ${this.datePipe.transform(this.dateRanges?.EndDate
          , 'yyyy-MM-dd', 'UTC')}`,
        style: this.pdfUtilityService.PdfStyles.pageTitle,
        margin: [0, 0, 0, 8]
      });

      contentP.push(this.getFinancialDetails());
      contentP.push(this.pdfUtilityService.AddLineBreak());
      contentP.push(this.getGameCounts());
      contentP.push(this.pdfUtilityService.AddLineBreak());
      contentP.push(this.getPlayersAndNumbers());
      contentP.push(this.pdfUtilityService.AddLineBreak());
      contentP.push(this.getCharityDetails());
      contentP.push(this.pdfUtilityService.AddLineBreak());

      const docDef: TDocumentDefinitions = {
        pageMargins: [40, 40, 40, 40],
        footer: (currentPage, pageCount) => {
          return {
            text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
            style: this.pdfUtilityService.PdfStyles.footer
          };
        },
        content: contentP
      };

      const pdf = pdfMake.createPdf(docDef);

      pdf.open();
    }
  }

  private determineSortForPDF(): IPdfSorting | null {
    const sortedColumn = this.columnApi.getColumnState().find((col) => col.sort !== null);
    if (sortedColumn) {
      return {
        columnToSort: sortedColumn.colId,
        direction: sortedColumn.sort
      }
    }

    return null;
  }

  private sortColsForPdf(charityBreakdownP: ICharityContributionsBreakdown[], sortP: IPdfSorting): ICharityContributionsBreakdown[] {
    return charityBreakdownP.sort((a, b) => {
      // @ts-ignore
      const propA = a[sortP.columnToSort];
      // @ts-ignore
      const propB = b[sortP.columnToSort];

      if (sortP.direction === "asc") {
        return propA - propB;
      } else if (sortP.direction === "desc") {
        return propB - propA;
      } else {
        // If direction is not specified or null, no sorting is applied
        return 0;
      }
    });
  }

  private getCharityDetails(): Content[] {
    const charityDetails: Content[] = [];
    let charityBreakdown = this.comprehensiveReport?.CharityBreakdown;
    const getAnyColSorts = this.determineSortForPDF();
    if (getAnyColSorts && charityBreakdown) {
      charityBreakdown = this.sortColsForPdf(charityBreakdown, getAnyColSorts)
    }

    charityDetails.push(this.pdfUtilityService.generateSectionTitle('Group Breakdown'));
    charityDetails.push(this.pdfUtilityService.generateLabelValueRow('Total Group Contributions: ', `${this.currencyPipe.transform(this.comprehensiveReport.FinancialTotals.CharityContributionTotal)}`),);
    const charityBreakdownContent: Content = {
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 1,
        body: [
          [
            {
              text: 'Group',
              fontSize: 10
            },
            {
              text: 'Total Amount',
              fontSize: 10
            },
            {
              text: 'Number of Players',
              fontSize: 10
            },
            {
              text: 'Number of Tickets',
              fontSize: 10
            }
          ]
        ]
      },
      margin: [0, 16, 0, 16]
    };

    if (charityBreakdown && charityBreakdown?.length > 0) {
      charityBreakdown.forEach((contributionP) => {
        charityBreakdownContent.table.body.push([
          contributionP.Name, `${this.currencyPipe.transform(contributionP.CharityContribution)}`, contributionP.TotalNumberOfPlayers, contributionP.TotalNumberOfTickets
        ]);
      });
    }

    charityDetails.push(charityBreakdownContent);
    return charityDetails;
  }

  private getPlayersAndNumbers(): Content[] {
    const playersAndNumbers: Content[] = [];
    playersAndNumbers.push(this.pdfUtilityService.generateSectionTitle('Financial Details'));
    const columnOne = [
      this.pdfUtilityService.generateLabelValueRow('Count of Played Numbers: ', `${this.comprehensiveReport.TicketCounts.NumberOfTicketsPlayed}`),
      this.pdfUtilityService.generateLabelValueRow('Number of Active Users: ', `${this.comprehensiveReport.UserCounts.NumberOfActiveUsers}`)
    ];

    const columnTwo = [
      this.pdfUtilityService.generateLabelValueRow('Count of Non Played Numbers: ', `${this.comprehensiveReport.TicketCounts.NumberOfTicketsNotPlayed}`),
      this.pdfUtilityService.generateLabelValueRow('Number of Inactive Users: ', `${this.comprehensiveReport.UserCounts.NumberOfInactiveUsers}`)
    ];

    const gameColumns: Content = {
      columns: [
        columnOne,
        columnTwo
      ]
    };
    playersAndNumbers.push(gameColumns);
    return playersAndNumbers;
  }

  private getFinancialDetails(): Content[] {
    const financialDetails: Content[] = [];
    financialDetails.push(this.pdfUtilityService.generateSectionTitle('Financial Details'));
    const columnOne = [
      this.pdfUtilityService.generateLabelValueRow('Gross sales: ', `${this.currencyPipe.transform(this.comprehensiveReport.FinancialTotals.GrossTotalSales)}`),
      this.pdfUtilityService.generateLabelValueRow('Total Charity Host Admin Fees: ', `${this.currencyPipe.transform(this.comprehensiveReport.FinancialTotals.AdministrativeCommissionTotal)}`),
      this.pdfUtilityService.generateLabelValueRow('Total Taxes Collected: ', `${this.currencyPipe.transform(this.comprehensiveReport.FinancialTotals.TotalTaxes)}`)
    ];

    const columnTwo = [
      this.pdfUtilityService.generateLabelValueRow('Total Amount Raised For Charities: ', `${this.currencyPipe.transform(this.comprehensiveReport.FinancialTotals.CharityContributionTotal)}`),
      this.pdfUtilityService.generateLabelValueRow('Total Fees Collected: ', `${this.currencyPipe.transform(this.comprehensiveReport.FinancialTotals.TotalFees)}`),
      this.pdfUtilityService.generateLabelValueRow('Total Amount Contributed to Prizes: ', `${this.currencyPipe.transform(this.comprehensiveReport.FinancialTotals.PotContributionTotal)}`)
    ];

    const gameColumns: Content = {
      columns: [
        columnOne,
        columnTwo
      ]
    };
    financialDetails.push(gameColumns);
    return financialDetails;
  }

  private getGameCounts(): Content[] {
    const gameCounts: Content[] = [];
    gameCounts.push(this.pdfUtilityService.generateSectionTitle('Game Counts'));
    const columnOne = [
      this.pdfUtilityService.generateLabelValueRow('Completed Games: ', `${this.comprehensiveReport.GameWinnerData.TotalNumberOfCompletedGames}`),
      this.pdfUtilityService.generateLabelValueRow('Completed Games with Winner: ', `${this.comprehensiveReport.GameWinnerData.NumberOfCompletedGamesWithWinners}`)
    ];
    const columnTwo = [
      this.pdfUtilityService.generateLabelValueRow('Completed Games with no Winner: ', `${this.comprehensiveReport.GameWinnerData.NumberOfGamesWithNoWinners}`)
    ];

    gameCounts.push({
      columns: [
        columnOne,
        columnTwo
      ]
    });

    return gameCounts;
  }
}
