<app-component-level-error-message [errorMessage]="errorMessage"></app-component-level-error-message>
<ng-container *ngIf="uiState === uiStateEnumForTemplate.ShowLoading ">
  <app-loading-card-content></app-loading-card-content>
</ng-container>
<div *ngIf="uiState === uiStateEnumForTemplate.ShowData && standardGameReport">

  <div class="justify-space-between flex-row align-start">
    <div>
      <div class="section-header">Standard Game Report</div>
      <div class="ticket-type-header">Draw Date: {{standardGameReport.EndDate | date: 'MMM d, y HH:mm' : 'UTC'}}</div>
    </div>
    <div>
      <button mat-raised-button color="primary" (click)="generatePDF()">Generate
        PDF
      </button>
    </div>
  </div>

  <div class="margin-top-large">
    <h3>Game Details</h3>
    <div class="margin-left-med flex-row-wrap justify-space-between align-start gap-normal">
      <div class="flex-report-item bottom-border">
        <div class="report-label">Game End Date</div>
        <div class="report-value">{{standardGameReport.EndDate | date: 'MMM d, y HH:mm' : 'UTC'}}</div>
      </div>
      <div class="flex-report-item bottom-border">
        <div class="report-label">License #</div>
        <div class="report-value">{{standardGameReport.LicenseNumber}}</div>
      </div>
      <div class="flex-report-item bottom-border">
        <div class="report-label">Draw Time</div>
        <div class="report-value">{{standardGameReport.DrawTime | date: 'MMM d, y HH:mm' : 'UTC'}}</div>
      </div>
      <div class="flex-report-item bottom-border">
        <div class="report-label">Drawn #</div>
        <div class="report-value">{{standardGameReport.WinningNumber}}</div>
      </div>
      <div class="flex-report-item bottom-border" *ngIf="activeGame?.Type === gameTypes.GoldRush">
        <div class="report-label">Winner Played</div>
        <div class="report-value">{{standardGameReport.WinnerPlayed ? 'Yes' : 'No'}}</div>
      </div>
      <div class="flex-report-item bottom-border">
        <div class="report-label">Drawn Person</div>
        <div class="report-value">{{standardGameReport.WinnerName ? standardGameReport.WinnerName : ''}}</div>
      </div>
    </div>
    <h3>Pot Details</h3>
    <div class="margin-left-med flex-row-wrap justify-space-between align-start gap-normal">
      <div class="flex-report-item bottom-border">
        <div class="report-label">Seed Contribution to Pot</div>
        <div class="report-value">{{standardGameReport.SeedTotal | formatCurrency}}</div>
      </div>
      <div class="flex-report-item bottom-border">
        <div class="report-label">Players Contribution to Pot</div>
        <div class="report-value">{{standardGameReport.TotalPotContribution | formatCurrency}}</div>
      </div>
      <div class="flex-report-item bottom-border">
        <div class="report-label">Pot Total</div>
        <div class="report-value">{{standardGameReport.PotTotal | formatCurrency}}</div>
      </div>
      <div class="flex-report-item bottom-border" *ngIf="activeGame?.Type === gameTypes.GoldRush">
        <div class="report-label">Previous Non-Winning Pot</div>
        <div class="report-value">{{standardGameReport.PreviousPotTotal | formatCurrency}}</div>
      </div>
      <div class="flex-report-item bottom-border">
        <div class="report-label">Refund Total</div>
        <div class="report-value">{{0 | formatCurrency}}</div>
      </div>
      <div class="flex-report-item bottom-border">
        <div class="report-label">Total Tickets</div>
        <div class="report-value">{{standardGameReport.TicketsSold}}</div>
      </div>
      <div class="flex-report-item bottom-border">
        <div class="report-label">Net Total</div>
        <div class="report-value">{{standardGameReport.PotTotal | formatCurrency}}</div>
      </div>
      <div class="flex-report-item bottom-border">
        <div class="report-label">Winner Payout</div>
        <div class="report-value">{{standardGameReport.WinnerAmount | formatCurrency}}</div>
      </div>
    </div>
    <h3>Fee Details</h3>
    <div class="margin-left-med flex-row-wrap justify-space-between align-start gap-normal">
      <div class="flex-report-item bottom-border">
        <div class="report-label">Revenue before fees</div>
        <div class="report-value">{{standardGameReport.TotalPotContribution | formatCurrency}}</div>
      </div>
      <div class="flex-report-item bottom-border">
        <div class="report-label">Causable Fees</div>
        <div class="report-value">{{standardGameReport.TotalCausableFees | formatCurrency}}</div>
      </div>
      <div class="flex-report-item bottom-border">
        <div class="report-label">Stripe Fees</div>
        <div class="report-value">{{standardGameReport.TotalStripeFees | formatCurrency}}</div>
      </div>
      <div class="flex-report-item bottom-border">
        <div class="report-label">HST on Causable Fees</div>
        <div class="report-value">{{standardGameReport.HstOnCausableFees | formatCurrency}}</div>
      </div>
      <div class="flex-report-item bottom-border">
        <div class="report-label">Charity Host Admin Fee</div>
        <div class="report-value">{{standardGameReport.TotalAdministrationCommissionFees | formatCurrency}}</div>
      </div>
    </div>

    <h3>Group Breakdown</h3>
    <div class="margin-left-med">
      <div class="report-label">Total Charity Contribution</div>
      <div class="report-value">{{standardGameReport.TotalCharityContribution | formatCurrency}}</div>
    </div>

    <ag-grid-angular
      class="ag-theme-alpine margin-top-med margin-left-med"
      (gridReady)="onGridReady($event)"
      [animateRows]="true"
      [domLayout]="'autoHeight'"
      overlayNoRowsTemplate="<span>No tickets for this game</span>"
      [rowData]="standardGameReport.CharityContributionsBreakdown"
      [columnDefs]="groupBreakdownReportDefs">
    </ag-grid-angular>

  </div>
</div>
