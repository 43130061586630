import {Component, Input} from '@angular/core';
import {IActiveGameInstance} from "../../interfaces/IActiveGameInstance";
import {forkJoin, map, Observable, of, timer} from "rxjs";
import {UIStateEnum} from "../../enum/UIStateEnum";
import Chart, {ChartConfiguration} from "chart.js/auto";
import {CountdownService} from "../../services/countdown.service";
import {ReportingService} from "../../services/reporting.service";
import {IPlayersAndTicketsPerGameInstance} from "../../interfaces/reporting/IPlayersAndTicketsPerGameInstance";
import {DatePipe} from "@angular/common";
import {IManualAndAutoPlayTickets} from "../../interfaces/reporting/IManualAndAutoPlayTickets";
import {FormatCurrencyPipe} from "../../pipes/format-currency.pipe";
import {ISalesPerDay} from "../../interfaces/reporting/ISalesPerDay";
import {ICharitySalesPerDraw} from "../../interfaces/reporting/ICharitySalesPerDraw";

@Component({
  selector: 'app-display-active-game-instance',
  templateUrl: './display-active-game-instance.component.html',
  styleUrls: ['./display-active-game-instance.component.scss']
})
export class DisplayActiveGameInstanceComponent {

  @Input() set setSelectedGameInstance(gameInstanceP: IActiveGameInstance) {
    this.errorMessage = '';
    this.selectedGameInstance = gameInstanceP;
    forkJoin([
      this.reportingService.getManualVsAutoPlayTickets(this.selectedGameInstance.GameId, gameInstanceP.Id),
      this.reportingService.salesPerDay(this.selectedGameInstance.GameId, gameInstanceP.Id),
      this.reportingService.topTenCharityContributors(this.selectedGameInstance.GameId, gameInstanceP.Id),
      this.reportingService.getPastGamesPlayersAndTickets(
        this.selectedGameInstance.GameId,
        5)
    ])
      .subscribe({
        next: ([manualAutoplayTicketP, salesPerDayP, charitiesTotalsP, playersAndTicketsP]) => {
          this.createCountdownSubscription(new Date(this.selectedGameInstance.EndedOn));
          this.generateManualVsAutoPlayChart(manualAutoplayTicketP);
          this.generateTopCharityContributors(charitiesTotalsP);
          this.generateTicketChart();
          this.generateSalesPerDayChart(salesPerDayP);
          this.generatePlayersTicketsOverTimeLineChart(playersAndTicketsP);
        },
        error: () => {
          this.errorMessage = 'Looks like there was an issue retrieving data for this draw.' +
            ' Please reload the page or reach out to a system administrator.'
        }
      })
  }

  public selectedGameInstance!: IActiveGameInstance;
  public errorMessage: string = '';
  public manualAutoPlayChart!: Chart;
  public noManualAutoPlayChartData: boolean = false;
  public ticketsChart!: Chart;
  public noTicketChartData: boolean = false;
  public playersTicketsLineChart!: Chart;
  public salesPerDayLineChart!: Chart;
  public noSalesPerDayLineChartData: boolean = false;
  public charitySalesBarGraph!: Chart;
  public noCharitySalesBarGraphData: boolean = false;
  public endDateCountDown$: Observable<string> = of('');
  public uiState = UIStateEnum.ShowData;
  public uiStateForTemplate = UIStateEnum;

  constructor(private countdownService: CountdownService,
              private datePipe: DatePipe,
              private formatCurrencyPipe: FormatCurrencyPipe,
              private reportingService: ReportingService) {
  }

  private generateTopCharityContributors(charitiesTotalsP: ICharitySalesPerDraw[]) {
    if (this.charitySalesBarGraph) {
      this.charitySalesBarGraph.destroy();
    }

    if (charitiesTotalsP.length === 0) {
      this.noCharitySalesBarGraphData = true;
      return;
    }

    let salesCntxt = document.getElementById('charitySalesBarGraph') as HTMLCanvasElement;
    const salesData = {
      labels: charitiesTotalsP.map((perCharity) => perCharity.CharityName),
      datasets: [
        {
          label: 'Top Charity Contributors',
          data: charitiesTotalsP.map((perCharity) => perCharity.Sales),
          fill: false,
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(255, 205, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(201, 203, 207, 0.2)'
          ],
          borderColor: [
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)'
          ],
          borderWidth: 1
        }
      ]
    };

    const config: ChartConfiguration = {
      type: 'bar',
      data: salesData,
      options: {
        scales: {
          y: {
            ticks: {
              callback: (value) => {
                return this.formatCurrencyPipe.transform(Number(value));
              }
            }
          }
        },
        responsive: true,
        plugins: {
          tooltip: {
            callbacks: {
              label: (context: any) => {
                return this.formatCurrencyPipe.transform(context.raw);
              }
            }
          },
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Top Charity Contributors '
          }
        }
      }
    };

    if (salesCntxt) {
      this.charitySalesBarGraph = new Chart(salesCntxt, config)
    }
  }

  private generateSalesPerDayChart(salesPerDayP: ISalesPerDay[]) {
    if (this.salesPerDayLineChart) {
      this.salesPerDayLineChart.destroy();
    }

    if (salesPerDayP.length === 0) {
      this.noSalesPerDayLineChartData = true;
      return;
    }

    let salesCntxt = document.getElementById('salesPerDayLineChart') as HTMLCanvasElement;

    const salesData = {
      labels: salesPerDayP.map((perDay) => this.datePipe.transform(perDay.SaleDate, 'MMM d', 'UTC')),
      datasets: [
        {
          label: 'Tickets Sold',
          data: salesPerDayP.map((perDay) => perDay.TicketsSold),
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        }
      ]
    };

    const config: ChartConfiguration = {
      type: 'line',
      data: salesData,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Sales Per Day'
          }
        }
      }
    };

    if (salesCntxt) {
      this.salesPerDayLineChart = new Chart(salesCntxt, config)
    }

  }

  private generatePlayersTicketsOverTimeLineChart(playersAndTicketsP: IPlayersAndTicketsPerGameInstance[]) {
    if (this.playersTicketsLineChart) {
      this.playersTicketsLineChart.destroy();
    }


    let playersCntxt = document.getElementById('playersTicketsLineChart') as HTMLCanvasElement;

    const playersData = {
      labels: playersAndTicketsP.map((perGame) => this.datePipe.transform(perGame.DrawDate, 'MMM d', 'UTC')),
      datasets: [
        {
          label: 'Players in draw',
          data: playersAndTicketsP.map((perGame) => perGame.TotalPaidPlayers),
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        },
        {
          label: 'Tickets in draw',
          data: playersAndTicketsP.map((perGame) => perGame.TotalPaidTickets),
          fill: false,
          borderColor: 'rgb(125, 100, 150)',
          tension: 0.1
        }
      ]
    };

    const config: ChartConfiguration = {
      type: 'line',
      data: playersData,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Players/Tickets Last 5 Games'
          }
        }
      }
    };

    if (playersCntxt) {
      this.playersTicketsLineChart = new Chart(playersCntxt, config)
    }

  }

  public generateManualVsAutoPlayChart(manualAutoPlayReportP: IManualAndAutoPlayTickets) {
    if (this.manualAutoPlayChart) {
      this.manualAutoPlayChart.destroy();
    }

    if (!manualAutoPlayReportP.AutoPlayedTicketsSales && !manualAutoPlayReportP.ManuallyPurchasedTicketsSales) {
      this.noManualAutoPlayChartData = true;
      return;
    }

    let ticketsCntxt = document.getElementById('manualAutoPlayChart') as HTMLCanvasElement;

    const config: any = {
      type: 'pie',
      data: {
        datasets: [{
          data: [
            manualAutoPlayReportP.AutoPlayedTicketsSales,
            manualAutoPlayReportP.ManuallyPurchasedTicketsSales],
          backgroundColor: [
            'rgb(255, 205, 86)',
            'rgb(54, 162, 235)',
          ],
        }],
        labels: ['Auto Play Sales ($)', 'Manual Sales ($)'],
      },
      options: {
        responsive: true,
        plugins: {
          tooltip: {
            callbacks: {
              label: (context: any) => {
                return this.formatCurrencyPipe.transform(context.parsed);
              }
            }
          },
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Autoplay vs Manual Ticket Sales'
          }
        }
      }
    };

    if (ticketsCntxt) {
      this.manualAutoPlayChart = new Chart(ticketsCntxt, config);
    }

  }

  public generateTicketChart() {
    if (this.ticketsChart) {
      this.ticketsChart.destroy();
    }

    if (!this.selectedGameInstance.TotalPaidTickets && !this.selectedGameInstance.TotalUnpaidTickets) {
      this.noTicketChartData = true;
      return;
    }

    let ticketsCntxt = document.getElementById('ticketsChart') as HTMLCanvasElement;

    const ticketsData = {
      labels: ['Paid Tickets', 'Unpaid Tickets'],
      datasets: [
        {
          data: [
            this.selectedGameInstance.TotalPaidTickets,
            this.selectedGameInstance.TotalUnpaidTickets],
          backgroundColor: ['#9BD0F5', '#e25d22'],
        }
      ]
    };

    const config: ChartConfiguration = {
      plugins: [],
      type: 'pie',
      data: ticketsData,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Tickets'
          }
        }
      }
    };

    if (ticketsCntxt) {
      this.ticketsChart = new Chart(ticketsCntxt, config);
    }

  }

  private createCountdownSubscription(dateP: Date): void {
    this.endDateCountDown$ = timer(0, 1000).pipe(map(() => {
      const end_date = dateP;
      if (end_date) {
        return this.countdownService.formatRemainingTimeUntilDraw(end_date);
      }

      return '';
    }));
  }

}
