<div class="page-container" *ngIf="activeGameInstance$ | async as gameInstance">
  <mat-card class="text-center">
    <div class="bold-text ready-for-draw">Pot total</div>
    <div class="bold-text ready-for-draw margin-top-small">{{gameInstance.PotTotal | formatCurrency}}</div>

    <ng-container *ngIf="uiState == uiStateForTemplate.ShowData">
      <ng-container *ngIf="gameInstance.State == gameInstanceStates.ReadyForDraw">
        <div>
          <div class="bold-text ready-for-draw margin-top-med">Looks like the draw is ready!!</div>
          <button
            mat-raised-button
            class="margin-top-med primary-button"
            (click)="drawWinner()">
            Draw Winner
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="gameInstance.State != gameInstanceStates.ReadyForDraw && !showEndDatePassedButNotReadyMessage">
        <div class="text-center">
          <p class="bold-text ready-for-draw">Looks like the draw is not ready yet. Please wait for the game to end
            before attempting to draw a winner.</p>
        </div>
      </ng-container>

      <ng-container *ngIf="gameInstance.State != gameInstanceStates.ReadyForDraw && showEndDatePassedButNotReadyMessage">
        <div class="bold-text ready-for-draw margin-top-med">{{endDatePassedButNotReadyMessage}}</div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="uiState == uiStateForTemplate.ShowLoading">
      <div class="bold-text ready-for-draw margin-top-med">The draw is now taking place. Please wait while we determine
        the winner.
      </div>
      <mat-spinner class="center-margin"></mat-spinner>
    </ng-container>

    <ng-container *ngIf="winnerResponse">
      <ng-container *ngIf="winnerResponse.Paid; else noWinner">
        <div class="text-center draw-results-title">CONGRATS !!!!</div>
        <div class="text-center win-gold">{{winnerResponse.PlayerName}}</div>
        <div class="text-center draw-results-title">Winning Number</div>
        <div class="text-center ticket margin-top-med">{{winnerResponse.TicketNumber}}</div>
      </ng-container>
      <ng-template #noWinner>
        <div class="text-center draw-results-title">SORRY !!!!</div>
        <div class="text-center win-gold">{{winnerResponse.PlayerName}}</div>
        <div class="text-center draw-results-title">Drawn Number</div>
        <div class="text-center ticket">{{winnerResponse.TicketNumber}}</div>
        <div class="text-center">Looks like this weeks winning ticket number was not played. Better luck next time</div>
      </ng-template>
    </ng-container>

  </mat-card>

</div>


