<div class="container flex-col justify-space-between" [@onSideNavChange]="sideNavShouldOpen ? 'open' : 'close'">
  <mat-nav-list>
    <ng-container *ngIf="(activeUser$ | async)?.Id">
      <ng-container *ngIf="activeGameId$ | async as gameId">
        <mat-list-item [routerLink]="[gameId, 'active-game-instances']"
                       [matTooltipDisabled]="sideNavShouldOpen"
                       data-cy="current-draw-page-link"
                       matTooltip="Current Draw"
                       matTooltipPosition="right">
          <mat-icon matListItemIcon>games</mat-icon>
          <div matListItemLine [@animateText]="linkText ? 'show' : 'hide'">Current Draw</div>
        </mat-list-item>

        <mat-list-item [routerLink]="[gameId, 'manage-game']"
                       [matTooltipDisabled]="sideNavShouldOpen"
                       *hasPermission="permissionTypes.ViewGameSettings"
                       data-cy="manage-game-page-link"
                       matTooltip="Manage Game"
                       matTooltipPosition="right">
          <mat-icon matListItemIcon>settings</mat-icon>
          <div matListItemLine [@animateText]="linkText ? 'show' : 'hide'">Game Settings</div>
        </mat-list-item>
        <mat-list-item [routerLink]="[gameId, 'reports']"
                       [matTooltipDisabled]="sideNavShouldOpen"
                       *hasPermission="permissionTypes.ViewReports"
                       data-cy="reports-page-link"
                       matTooltip="Reports"
                       matTooltipPosition="right">
          <mat-icon matListItemIcon>request_page</mat-icon>
          <div matListItemLine [@animateText]="linkText ? 'show' : 'hide'">Reports</div>
        </mat-list-item>
        <mat-list-item [routerLink]="[gameId, 'manage-charities']"
                       [matTooltipDisabled]="sideNavShouldOpen"
                       data-cy="manage-charities-page-link"
                       *hasPermission="permissionTypes.ViewCharities"
                       matTooltip="Manage Charities"
                       matTooltipPosition="right">
          <mat-icon matListItemIcon>volunteer_activism</mat-icon>
          <div matListItemLine [@animateText]="linkText ? 'show' : 'hide'">Charities</div>
        </mat-list-item>
        <mat-list-item [routerLink]="[gameId, 'player-search']"
                       [matTooltipDisabled]="sideNavShouldOpen"
                       data-cy="player-search-page-link"
                       *hasPermission="permissionTypes.ViewPlayerInfo"
                       matTooltip="Manage Players"
                       matTooltipPosition="right">
          <mat-icon matListItemIcon>manage_accounts</mat-icon>
          <div matListItemLine [@animateText]="linkText ? 'show' : 'hide'">Players</div>
        </mat-list-item>

<!--        Disabled features-->
        <ng-container *ngIf="enabledRoute">
          <mat-list-item [routerLink]="[gameId, 'charity-payouts']"
                         [matTooltipDisabled]="sideNavShouldOpen"
                         data-cy="charity-payouts-page-link"
                         *hasPermission="permissionTypes.ModifyCharities"
                         matTooltip="Charity Payouts"
                         matTooltipPosition="right">
            <mat-icon matListItemIcon>attach_money</mat-icon>
            <div matListItemLine [@animateText]="linkText ? 'show' : 'hide'">volunteer_activism</div>
          </mat-list-item>
          <mat-list-item [routerLink]="[gameId, 'draw-winner']"
                         [matTooltipDisabled]="sideNavShouldOpen"
                         data-cy="draw-winner-page-link"
                         *ngIf="enabledRoute"
                         matTooltip="Draw Winner"
                         matTooltipPosition="right">
            <mat-icon matListItemIcon>celebration</mat-icon>
            <div matListItemLine [@animateText]="linkText ? 'show' : 'hide'">Draw Winner</div>
          </mat-list-item>
          <mat-list-item [routerLink]="[gameId, 'winner-payouts']"
                         data-cy="winner-payouts-page-link"
                         [matTooltipDisabled]="sideNavShouldOpen"
                         *hasPermission="permissionTypes.ModifyGameSettings"
                         matTooltip="Winner Payment"
                         matTooltipPosition="right">
            <mat-icon matListItemIcon>attach_money</mat-icon>
            <div matListItemLine [@animateText]="linkText ? 'show' : 'hide'">Winner Payouts</div>
          </mat-list-item>
          <mat-list-item [routerLink]="[gameId, 'auditing']"
                         [matTooltipDisabled]="sideNavShouldOpen"
                         matTooltip="Auditing"
                         data-cy="auditing-page-link"
                         matTooltipPosition="right">
            <mat-icon matListItemIcon>edit</mat-icon>
            <div matListItemLine [@animateText]="linkText ? 'show' : 'hide'">Auditing</div>
          </mat-list-item>
        </ng-container>

        <mat-list-item [routerLink]="[gameId, 'manage-users']"
                       [matTooltipDisabled]="sideNavShouldOpen"
                       data-cy="manage-users-page-link"
                       *hasPermission="permissionTypes.ViewAdminUsers"
                       matTooltip="Manage Users"
                       matTooltipPosition="right">
          <mat-icon matListItemIcon>supervised_user_circle</mat-icon>
          <div matListItemLine [@animateText]="linkText ? 'show' : 'hide'">Admin Users</div>
        </mat-list-item>
        <mat-list-item [routerLink]="[gameId, 'manage-permissions']"
                       [matTooltipDisabled]="sideNavShouldOpen"
                       data-cy="manage-permissions-page-link"
                       *hasPermission="permissionTypes.ViewRolesAndPermissions"
                       matTooltip="Manage Permissions"
                       matTooltipPosition="right">
          <mat-icon matListItemIcon>supervised_user_circle</mat-icon>
          <div matListItemLine [@animateText]="linkText ? 'show' : 'hide'">Manage Permissions</div>
        </mat-list-item>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="(userIsLoggedIn$ | async) else showLogin">
      <mat-list-item [routerLink]="['choose-game']"
                     *ngIf="(availableGamesToUser$ | async)?.length! > 1"
                     [matTooltipDisabled]="sideNavShouldOpen"
                     data-cy="change-game-page-link"
                     matTooltip="Change Game"
                     matTooltipPosition="right">
        <mat-icon matListItemIcon>change_circle</mat-icon>
        <div matListItemLine [@animateText]="linkText ? 'show' : 'hide'">Change Game</div>
      </mat-list-item>
    </ng-container>

  </mat-nav-list>
  <mat-nav-list>
    <mat-list-item (click)="onSideNavToggle()">
      <mat-icon matListItemIcon>{{linkText ? 'keyboard_double_arrow_left' : 'keyboard_double_arrow_right'}}</mat-icon>
      <div matListItemLine [@animateText]="linkText ? 'show' : 'hide'">Collapse</div>
    </mat-list-item>
  </mat-nav-list>

</div>

<ng-template #showLogin>
  <mat-list-item routerLink="/login"
                 [matTooltipDisabled]="sideNavShouldOpen"
                 matTooltip="Login"
                 matTooltipPosition="right">
    <mat-icon matListItemIcon>login</mat-icon>
    <div matListItemLine [@animateText]="linkText ? 'show' : 'hide'">Login</div>
  </mat-list-item>
</ng-template>
