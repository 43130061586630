<app-dialog-base [showLoadingSpinner]="uiState == uiStateEnumForTemplate.ShowLoading">
  <span dialog-title>Create New Role</span>
  <ng-container dialog-content>
    <form [formGroup]="createRoleForm">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Enter New Role Name</mat-label>
        <input
          formControlName="roleName"
          type="text"
          matInput
          data-cy="new-role-name-input"
          placeholder="enter role name">
        <mat-error *ngIf="roleNameControl.hasError('required')">
          name is <span class="bold-text">required</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Enter New Role Description</mat-label>
        <input
          formControlName="roleDescription"
          type="text"
          matInput
          data-cy="new-role-description-input"
          placeholder="enter role description">
        <mat-error *ngIf="roleDescriptionControl.hasError('required')">
          role description is <span class="bold-text">required</span>
        </mat-error>
      </mat-form-field>
    </form>
  </ng-container>
  <ng-container actions>
    <button
      data-cy="submit-new-role-button"
      mat-button
      (click)="submitNewRole()"
      [disabled]="uiState == uiStateEnumForTemplate.ShowLoading">Create Role
    </button>
  </ng-container>
</app-dialog-base>
