<div class="user-menu">
  <mat-icon
    data-cy="user-menu-button"
    class="user-icon"
    [matMenuTriggerFor]="menu"
    role="button"
    aria-label="click here to access user options">account_circle
  </mat-icon>
  <mat-menu #menu="matMenu">
    <ng-container *ngIf="(activeUser$ | async) as user">

      <div class="flex-col align-center" *ngIf="(activeUser$ | async) as user">
        <mat-icon class="user-icon">
          account_circle
        </mat-icon>
        <div class="flex-col justify-space-around align-center padding-med">
          <span>{{user.FirstName}} {{user.LastName}}</span>
          <span data-cy="current-user-email-address">{{user.Email}}</span>
          <button
            data-cy="user-menu-logout-button"
            mat-raised-button
            class="margin-top-med"
            (click)="onLogoutClick()">Logout</button>
        </div>

        <div class="divider"></div>

        <button
          data-cy="navigate-to-change-email-page"
          mat-menu-item
          [routerLink]="['update-email-address']"><mat-icon>email</mat-icon>Change Email Address</button>
        <button
          data-cy="navigate-to-change-password-page"
          mat-menu-item
          [routerLink]="['update-password']"><mat-icon>password</mat-icon>Change Password</button>

      </div>
    </ng-container>
  </mat-menu>
</div>
