<div class="page-container">
  <h2>Manage Roles and Permissions</h2>
  <button mat-raised-button
          class="margin-bottom-med margin-top-med"
          *ngIf="chosenRole"
          data-cy="back-to-roles-button"
          (click)="chosenRole = undefined">
    <mat-icon>arrow_back_ios</mat-icon>
    Back to Roles
  </button>
  <mat-spinner *ngIf="uiState === uiStateEnumForTemplate.ShowLoading"></mat-spinner>
  <ng-container *ngIf="uiState === uiStateEnumForTemplate.ShowData && !chosenRole">
    <button mat-raised-button
            data-cy="add-new-role-button"
            *hasPermission="permissionTypes.ModifyRolesAndPermissions"
            class="primary-button margin-top-large margin-bottom-large float-right"
            (click)="onAddNewRoleClick()">Add New Role
    </button>
    <table mat-table [dataSource]="rolesWithPermissions" data-cy="roles-table" class="margin-top-large">
      <ng-container matColumnDef="roleName">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element"> {{element.Role.Name}} </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let element"> {{element.Role.Description}} </td>
      </ng-container>

      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef>Active</th>
        <td mat-cell *matCellDef="let element">{{element.Role.Active}}</td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button
            *ngIf="element"
            [matMenuTriggerFor]="menu"
            [matMenuTriggerData]="{role: element}"
            data-cy="edit-role-button"
            mat-icon-button
            matTooltip="edit this role">
            <mat-icon>more_horiz</mat-icon>
          </button>
        </td>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedRolesColumns"></tr>
      <tr data-cy="manage-roles-row" mat-row *matRowDef="let row; columns: displayedRolesColumns;"></tr>
    </table>
  </ng-container>

  <ng-container *ngIf="chosenRole">
    <h3>{{chosenRole.Role.Name}}</h3>

    <mat-tab-group dynamicHeight>
      <mat-tab label="Settings" labelClass="role-settings-tab">
        <div class="padding-med">
          <mat-spinner *ngIf="uiState === uiStateEnumForTemplate.ShowLoading"></mat-spinner>
          <form [formGroup]="roleManagementSettingsFormGroup" class="margin-top-large"
                *ngIf="uiState == uiStateEnumForTemplate.ShowData">
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Name</mat-label>
              <input
                [readonly]="!userHasEditPermission"
                data-cy="edit-role-name"
                matInput
                formControlName="roleName"
              >
              <mat-error *ngIf="roleNameControl.hasError('required')">
                role name is <span class="bold-text">required</span>
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-width margin-top-med">
              <mat-label>Description</mat-label>
              <input
                [readonly]="!userHasEditPermission"
                data-cy="edit-role-description"
                matInput
                formControlName="description"
              >
            </mat-form-field>
            <mat-error *ngIf="descriptionControl.hasError('required')">
              description is <span class="bold-text">required</span>
            </mat-error>

          </form>

          <div class="float-right margin-bottom-large">
            <button mat-raised-button
                    (click)="updateRoleSettings()"
                    data-cy="save-role-update-button"
                    [disabled]="!roleManagementSettingsFormGroup.enabled || !roleManagementSettingsFormGroup.valid || !roleManagementSettingsFormGroup.dirty"
                    *hasPermission="permissionTypes.ModifyRolesAndPermissions">
              Save Changes
            </button>
          </div>

        </div>

      </mat-tab>
      <mat-tab label="Permissions" labelClass="role-permissions-tab">
        <button mat-raised-button
                *hasPermission="permissionTypes.ModifyRolesAndPermissions"
                class="primary-button margin-top-large margin-bottom-large float-right"
                (click)="onAddPermissionsToRoleClick()">Add Permissions to Role
        </button>
        <table mat-table [dataSource]="chosenRole.Permissions" data-cy="role-permissions-table"
               class="margin-top-large">
          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef>Value</th>
            <td mat-cell *matCellDef="let element"> {{element.Value}} </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
          </ng-container>

          <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>Active</th>
            <td mat-cell *matCellDef="let element">{{element.Active}}</td>
          </ng-container>

          <ng-container matColumnDef="remove">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <button
                data-cy="remove-role-button"
                *hasPermission="permissionTypes.ModifyRolesAndPermissions"
                mat-icon-button
                matTooltip="remove this permission"
                (click)="onRemovePermissionFromRoleClick(element)">
                <mat-icon class="red-text">delete</mat-icon>
              </button>
            </td>

          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedPermissionsPerRoleColumns"></tr>
          <tr data-cy="user-roles-row" mat-row *matRowDef="let row; columns: displayedPermissionsPerRoleColumns;"></tr>
        </table>

      </mat-tab>
      <mat-tab label="Users" labelClass="role-users-tab">
        <div>
          <ag-grid-angular
            id="roleUsersGrid"
            class="ag-theme-alpine margin-top-med margin-bottom-med"
            [tooltipShowDelay]=0
            domLayout='autoHeight'
            [rowHeight]="50"
            rowClass="table-row"
            (firstDataRendered)="onFirstDataRendered($event)"
            (gridSizeChanged)="gridSizeChanged($event)"
            overlayNoRowsTemplate="<span>No game admins for this game</span>"
            [rowData]="chosenRole.AdminUsers"
            [columnDefs]="genericAdminColDefs">
          </ag-grid-angular>
        </div>

      </mat-tab>
    </mat-tab-group>
  </ng-container>

</div>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-role="role">
    <button mat-menu-item
            data-cy="view-role-details"
            (click)="viewRoleDetailsClick(role)">
      <mat-icon class="dark-grey menu-icon">settings</mat-icon>
      <span class="dark-grey menu-text">View Details</span>
    </button>
  </ng-template>
</mat-menu>
