import {Injectable} from "@angular/core";
import {SnackBarComponent} from "../shared/snack-bar/snack-bar.component";
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {
  }

  openSuccessfulSnackBar(message: string) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      data: message,
      duration: 3000,
      panelClass: 'successful-snackbar'
    });
  }
}
