import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {forkJoin, map} from "rxjs";
import {AuditingService} from "../services/auditing.service";
import {catchError} from "rxjs/operators";
import {DateService} from "../services/date.service";
import {IPaginationFilter} from "../interfaces/IPaginationFilter";

@Injectable({
  providedIn: 'root'
})
export class AuditRouteGuard implements CanActivate {
  constructor(private auditingService: AuditingService,
              private dateService: DateService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const defaultFilter: IPaginationFilter = {
      fromDate: this.dateService.twoWeeksAgo(),
      toDate: new Date(),
      eventTypeValue: null,
      pageSize: 15,
      pageNumber: 1
    };

    const auditingData = forkJoin([
      this.auditingService.retrieveAdminAudits(defaultFilter),
      this.auditingService.retrieveSystemAudits(defaultFilter),
      this.auditingService.retrievePlayerAudits(defaultFilter),
      this.auditingService.retrieveAllEventTypes()
    ])

    return auditingData.pipe(map(() => {
      return true;
    }), catchError((err) => {
      throw err;
    }))

  }
}
