import {Component} from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
import {IAdminQueryResult} from "../../../interfaces/IAdminQueryResult";
import {Router} from "@angular/router";
import {GameService} from "../../../services/game.service";
import {PermissionTypes} from "../../../enum/PermissionTypes";

@Component({
  selector: 'app-game-admin-settings',
  templateUrl: './generic-admin-settings.component.html',
  styleUrls: ['./generic-admin-settings.component.scss']
})
export class GenericAdminSettingsComponent implements ICellRendererAngularComp {

  public adminUser!: IAdminQueryResult;
  public permissionTypes = PermissionTypes;

  constructor(private gameService: GameService,
              private router: Router) {
  }

  agInit(params: ICellRendererParams): void {
    this.adminUser = params.data;
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }

  onViewDetailsClick() {
    let activeGame = this.gameService.activeGame().Id;
    this.router.navigateByUrl(`${activeGame}/user-details/${this.adminUser.Id}`);
  }
}
