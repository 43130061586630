import {Component} from '@angular/core';
import {GameInstanceService} from "../../services/game-instance.service";
import {GameService} from "../../services/game.service";
import {IGameQueryResult} from "../../interfaces/IGameQueryResult";
import {Observable} from "rxjs";
import {IActiveGameInstance} from "../../interfaces/IActiveGameInstance";

@Component({
  selector: 'app-active-game-instances',
  templateUrl: './active-game-instances.component.html',
  styleUrls: ['./active-game-instances.component.scss']
})
export class ActiveGameInstancesComponent {
  public activeGame: IGameQueryResult | undefined = this.gameService.activeGame();
  public activeGameInstance$: Observable<IActiveGameInstance> = this.gameInstanceService.selectActiveGameInstance();

  constructor(private gameInstanceService: GameInstanceService,
              private gameService: GameService) {
  }

}
