<div>
  <mat-progress-bar mode="indeterminate" class="loading"></mat-progress-bar>

  <mat-progress-bar mode="indeterminate" class="loading"></mat-progress-bar>

  <mat-progress-bar mode="indeterminate" class="loading"></mat-progress-bar>

  <mat-progress-bar mode="indeterminate" class="loading"></mat-progress-bar>

</div>
