<div class="page-container">
  <h2>Manage Game Settings
    <button mat-icon-button
            data-cy="open-manage-game-options-menu-button"
            id="charity-menu-button"
            *hasPermission="permissionTypes.RecalculateGameTotals"
            [matMenuTriggerFor]="menu">
      <mat-icon matTooltipPosition="right"
                matTooltip="open admin user menu"
      >more_vertical
      </mat-icon>
    </button>
  </h2>

  <ng-container *ngIf="activeGame$ | async as activeGame">
    <mat-tab-group data-cy="game-settings-tab-group">
      <mat-tab label="General Settings" labelClass="general-settings-tab">

        <div class="custom-card-border margin-top-med">
          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Game Name</div>
            <div class="flex-66">{{activeGame.Name}}</div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Game Subdomain</div>
            <div class="flex-66">{{activeGame.Subdomain}}</div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Game Type</div>
            <div class="flex-66">{{activeGame.Type | gameType}}</div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Game Time Zone</div>
            <div class="flex-66">{{activeGame.Timezone}}</div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">License Number</div>
            <div class="flex-66">
              <span data-cy="game-settings-license-number">{{activeGame.LicenseNumber}}</span>
              <mat-icon
                [matTooltip]="'click here to modify the license number'"
                *hasPermission="permissionTypes.ModifySensitiveGameSettings"
                data-cy="edit-license-number-button"
                class="edit-game-icon"
                (click)="onEditSensitiveSettingsClick()">edit
              </mat-icon>
            </div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Date of First Game</div>
            <div class="flex-66">{{activeGame.DateOfFirstInstance | date: 'MMM d, y'}}</div>
          </div>
          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Date of Final Game</div>
            <div
              class="flex-66">{{activeGame.DateOfFinalInstance ? (activeGame.DateOfFinalInstance | date: 'MMM d, y') : '-----'}}</div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Game Created On</div>
            <div class="flex-66">{{activeGame.CreatedOn | date: 'MMM d, y'}}</div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Active</div>
            <div class="flex-66">
              <mat-icon [class]="activeGame.Active ? 'green-text': 'red-text'">{{activeGame.Active ? 'check' : 'clear'}}
                check
              </mat-icon>
            </div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">AutoPlay Enabled</div>
            <div class="flex-66">
              <mat-icon
                [class]="activeGame.AutoplayEnabled ? 'green-text': 'red-text'">{{activeGame.AutoplayEnabled ? 'check' : 'clear'}}
                check
              </mat-icon>
            </div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Collect Player Address</div>
            <div class="flex-66">
              <mat-icon
                [class]="activeGame.CollectPlayerAddress ? 'green-text': 'red-text'">{{activeGame.CollectPlayerAddress ? 'check' : 'clear'}}
                check
              </mat-icon>
            </div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Lock Players to Region</div>
            <div class="flex-66">
              <mat-icon
                [class]="activeGame.RegionLockPlayerGeolocation ? 'green-text': 'red-text'">{{activeGame.RegionLockPlayerGeolocation ? 'check' : 'clear'}}
                check
              </mat-icon>
            </div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Lock Players to Postal Code</div>
            <div class="flex-66">
              <mat-icon
                [class]="activeGame.RegionLockPlayerPostalCode ? 'green-text': 'red-text'">{{activeGame.RegionLockPlayerPostalCode ? 'check' : 'clear'}}
                check
              </mat-icon>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Asset Settings" labelClass="assets-settings-tab">
        <div class="custom-card-border margin-top-med">
          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Style Sheet Asset Location</div>
            <div class="flex-66">{{cdnRoot + activeGame.ColorThemeUrl}}</div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Login Theme Url</div>
            <div class="flex-66">{{cdnRoot + activeGame.LoginThemeUrl}}</div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Login More Info Url</div>
            <div class="flex-66">{{activeGame.LoginMoreInfoUrl}}</div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Login Text</div>
            <div class="flex-66">{{activeGame.LoginText}}</div>
          </div>


          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">FAQ Url</div>
            <div class="flex-66">
              <span data-cy="game-settings-faq-url">{{activeGame.FaqUrl}}</span>
              <mat-icon
                [matTooltip]="'click here to modify the FAQ Url'"
                *hasPermission="permissionTypes.ModifySensitiveGameSettings"
                class="edit-game-icon"
                data-cy="edit-faq-url-button"
                (click)="onEditGenericSettingsClick()">edit
              </mat-icon></div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Status Screen Url</div>
            <div class="flex-66">{{activeGame.StatusScreenUrl}}</div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Status Screen Text</div>
            <div class="flex-66">{{activeGame.StatusScreenUrlText}}</div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Combined Game Logo</div>
            <div class="flex-66">
              <img class="img-section padding-med" alt="combined game logo"
                   [src]="cdnRoot + activeGame.CombinedLogoUrl">
            </div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Game Logo</div>
            <div class="flex-66">
              <img class="img-section padding-med" alt="game logo" [src]="cdnRoot + activeGame.LogoUrl">
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Draw / Ticket Settings" labelClass="draw-ticket-settings-tab">
        <div class="custom-card-border margin-top-med">

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Day of Draw</div>
            <div class="flex-66">{{activeGame.DrawDayOfWeek | drawDayOfWeek}}</div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Time of Draw</div>
            <div class="flex-66">{{activeGame.DrawTimeMinutesFromMidnightLocal | minutesFromMidnightToHours}}</div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Notifications Start Time</div>
            <div
              class="flex-66">{{activeGame.NotificationsStartMinutesFromMidnightLocal | minutesFromMidnightToHours}}</div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Draw Frequency</div>
            <div class="flex-66">{{activeGame.InstanceIntervalType | drawInterval}}</div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Max Tickets Per Draw</div>
            <div class="flex-66">{{activeGame.MaxTicketsPerDraw}}</div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Max Tickets Per Player</div>
            <div class="flex-66">{{activeGame.MaxNumbersPlayerCanPurchase}}</div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Number of Tickets in Strip</div>
            <div class="flex-66">{{activeGame.NumberOfTicketsInGroupPerPurchase}}</div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Price per Ticket Strip</div>
            <div class="flex-66">{{activeGame.GroupPrice | formatCurrency}}</div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Game is Drawn Manually</div>
            <div class="flex-66">
              <mat-icon *ngIf="activeGame.ManualDraw">check</mat-icon>
              <mat-icon *ngIf="!activeGame.ManualDraw">clear</mat-icon>
            </div>
          </div>

          <div class="flex-row justify-space-between align-center custom-border-bottom">
            <div class="flex-33 bold-text">Assign Numbers on Registration</div>
            <div class="flex-66">
              <mat-icon *ngIf="activeGame.AssignNumbersOnRegistration">check</mat-icon>
              <mat-icon *ngIf="!activeGame.AssignNumbersOnRegistration">clear</mat-icon>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
    <ng-container *ngIf="chosenGameInstance">
      <div class="margin-bottom-med">
        <button mat-raised-button (click)="resetActiveGameInstance()">
          <mat-icon>arrow_back_ios</mat-icon>
          Back
        </button>
      </div>
      <mat-card class="margin-bottom-med">
        <mat-card-header>
          <mat-card-title>Draw Settings</mat-card-title>
        </mat-card-header>
        <mat-card-content class="margin-top-large">
          <div class="flex-row-wrap justify-start align-start">
            <div class="detail-section">
              <div class="detail-label">Game State</div>
              <div class="detail-value">{{chosenGameInstance.State | gameInstanceStatus}}</div>
            </div>

            <div class="detail-section">
              <div class="detail-label">Starting Pot</div>
              <div class="detail-value">{{chosenGameInstance.StartingPot | formatCurrency}}</div>
            </div>

            <div class="detail-section">
              <div class="detail-label">Created On</div>
              <div class="detail-value">{{chosenGameInstance.CreatedOn | date: 'MMM d, y'}}</div>
            </div>

            <div class="detail-section">
              <div class="detail-label">Started On</div>
              <div class="detail-value">{{chosenGameInstance.StartedOn | date: 'MMM d, y HH:mm': 'UTC'}}</div>
            </div>

            <div class="detail-section">
              <div class="detail-label">Draw Date & Time</div>
              <div class="detail-value">{{chosenGameInstance.EndedOn | date: 'MMM d, y HH:mm': 'UTC'}}</div>
            </div>

            <div class="detail-section">
              <div class="detail-label">Price Per Group of Tickets</div>
              <div class="detail-value">{{chosenGameInstance.GroupPrice | formatCurrency}}</div>
            </div>

            <div class="detail-section">
              <div class="detail-label">Causable Fee Multiplier</div>
              <div class="detail-value">{{chosenGameInstance.CausableFeeMultiplier | percent}}</div>
            </div>

            <div class="detail-section">
              <div class="detail-label">Starting Charity Contribution</div>
              <div class="detail-value">{{chosenGameInstance.StartingCharityContribution | formatCurrency}}</div>
            </div>

            <div class="detail-section">
              <div class="detail-label">Starting Payment Fees</div>
              <div class="detail-value">{{chosenGameInstance.StartingPaymentFees | formatCurrency}}</div>
            </div>

            <div class="detail-section">
              <div class="detail-label">Tax On Starting Payments</div>
              <div class="detail-value">{{chosenGameInstance.StartingPaymentFeesTax | formatCurrency}}</div>
            </div>

          </div>
        </mat-card-content>
      </mat-card>

    </ng-container>
  </ng-container>
</div>
<mat-menu #menu="matMenu">
  <button mat-menu-item
          (click)="onRecalculateGameClick()"
          data-cy="edit-category-name-option">
    <mat-icon class="dark-grey menu-icon">edit</mat-icon>
    <span class="dark-grey menu-text">Recalculate Game Totals</span>
  </button>
</mat-menu>
