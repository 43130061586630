import {Component} from '@angular/core';
import {OidcService} from "../../../services/oidc.service";

@Component({
  selector: 'causable-logout-page',
  styleUrls: ['logout.page.scss'],
  templateUrl: 'logout.page.html'
})
export class LogoutPage
{
  constructor(private oidcService: OidcService)
  {}

  onLogoutClick(): void
  {
    this.oidcService.logout();
  }
}
