<div class="page-container">
  <h2>Charity Payouts</h2>
  <h3>{{activeGame.Name}} - Charity Balances</h3>
  <ng-container *ngIf="uiState === uiStateForTemplate.ShowData else showLoading">
    <div class="text-end">
      <button mat-button class="primary-button" (click)="onMakePaymentClick()" matTooltip="Make Charity Payment"
              matTooltipShowDelay="500">
        <mat-icon>payment</mat-icon>
        Pay Charities
      </button>
    </div>
    <ag-grid-angular
      class="ag-theme-alpine margin-top-med margin-bottom-med"
      [tooltipShowDelay]=0
      domLayout='autoHeight'
      overlayNoRowsTemplate="<span>No charities for this game</span>"
      (gridReady)="onCharityBreakdownGridReady($event)"
      [rowData]="charityBreakdown"
      [columnDefs]="charityBreakdownDefs">
    </ag-grid-angular>

  </ng-container>

  <ng-template #showLoading>
    <app-loading-card-content></app-loading-card-content>
  </ng-template>
</div>
