import {Component} from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";

@Component({
  selector: 'app-copy-value',
  templateUrl: './copy-value.component.html',
  styleUrls: ['./copy-value.component.scss']
})
export class CopyValueComponent implements ICellRendererAngularComp {

  public valueToCopy!: any;

  constructor() {
  }

  agInit(params: ICellRendererParams): void {
    this.valueToCopy = params.value;
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }
}
