<div class="text-center" *hasPermission="permissionTypes.ModifyAdminUsers">
  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon matTooltipPosition="right"
              matTooltip="open admin user menu"
    >more_horiz
    </mat-icon>
  </button>
</div>

<mat-menu #menu="matMenu">
  <button mat-menu-item
          (click)="onDeactivateUserClick()"
          *ngIf="rowData.Active">
    <mat-icon>do_disturb</mat-icon>
    <span>Deactivate</span>
  </button>
  <button mat-menu-item
          (click)="onReactiveUserClick()"
          *ngIf="!rowData.Active">
    <mat-icon>refresh</mat-icon>
    <span>Reactivate</span>
  </button>
</mat-menu>
