import {Component, Input} from '@angular/core';
import {IPlayerNumberQueryResult} from "../../interfaces/player/IPlayerNumberQueryResult";
import {MatDialog} from "@angular/material/dialog";
import {PlayersService} from "../../services/players.service";
import {IPlayerNumbersByGroupId} from "../../interfaces/player/IPlayerNumbersByGroupId";
import {IPlayerPaymentTransactions} from "../../interfaces/player/IPlayerPaymentTransactions";
import {UIStateEnum} from "../../enum/UIStateEnum";
import {ColDef, GridApi, GridReadyEvent} from "ag-grid-community";
import {DatePipe} from "@angular/common";
import {FormatCurrencyPipe} from "../../pipes/format-currency.pipe";
import {IPlayerNumberGroupRow} from "../../interfaces/player/IPlayerNumberGroupRow";
import {
  UpdateTicketActivationRendererComponent
} from "../cell-renderers/update-ticket-activation-renderer/update-ticket-activation-renderer.component";
import {mergeMap} from "rxjs";
import {GameService} from "../../services/game.service";
import {IGameQueryResult} from "../../interfaces/IGameQueryResult";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {FormControl, FormGroup} from "@angular/forms";
import {DateService} from "../../services/date.service";
import {PermissionTypes} from "../../enum/PermissionTypes";
import {PermissionsService} from "../../services/permissions.service";
import {IPlayerPerGameQueryResult} from "../../interfaces/player/IPlayerPerGameQueryResult";
import {
  ConfirmationActionDialogComponent
} from "../dialogs/confirmation-action-dialog/confirmation-action-dialog.component";
import {IDialogGenericData} from "../../interfaces/IDialogGenericData";
import {IDeactivatePlayerNumberGroup} from "../../interfaces/player/IDeactivatePlayerNumberGroup";
import {DialogFunctionService} from "../../services/dialog-function.service";

@Component({
  selector: 'app-player-game-info',
  templateUrl: './player-game-info.component.html',
  styleUrls: ['./player-game-info.component.scss']
})
export class PlayerGameInfoComponent {

  @Input() set chosenPlayerSetter(playerP: IPlayerPerGameQueryResult) {
    this.chosenPlayer = playerP;
    this.addActionsColIfHasPermission();
    this.getGameDataForPlayer();
  }

  public transactionSearchForm = new FormGroup(
    {
      stripeId: new FormControl(''),
      transactionDate: new FormControl(''),
      ticketNumber: new FormControl('')
    }
  );

  public chosenPlayer: IPlayerPerGameQueryResult | null = null;
  public permissionTypes = PermissionTypes;
  public userHasActiveStrips = false;
  public activeGame: IGameQueryResult | undefined = this.gameService.activeGame();
  public playerTransactionsPerGame: IPlayerPaymentTransactions[] = [];
  public filteredTransactionsPerGame: IPlayerPaymentTransactions[] = [];
  public playerNumberGroupRows: IPlayerNumberGroupRow[] = [];
  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateForTemplate = UIStateEnum;

  public ticketsGridApi!: GridApi<IPlayerNumberGroupRow>;
  public ticketsColumnDefs: ColDef[] = [
    {
      headerName: 'Ticket Numbers',
      field: 'TicketNumbers',
      resizable: true,
      sortable: true
    },
    {
      headerName: 'Status',
      resizable: true,
      sortable: true,
      field: 'Active',
      valueFormatter: params => {
        return params.value ? 'Active' : 'Deactivated'
      }
    },
    {
      headerName: 'Autoplay Enabled',
      resizable: true,
      sortable: true,
      field: 'Autoplay',
      valueFormatter: params => {
        return params.value ? 'Yes' : 'No'
      }
    }
  ];

  public actionsColDef: ColDef =
    {
      headerName: 'Actions',
      resizable: true,
      cellRenderer: UpdateTicketActivationRendererComponent
    }

  public transactionGridApi!: GridApi<IPlayerPaymentTransactions>;
  public transactionColumnDefs: ColDef[] = [
    {
      headerName: 'Stripe Charge Id',
      width: 250,
      sortable: true,
      field: 'StripeChargeId',
      resizable: true
    },
    {
      headerName: 'Total ($)',
      resizable: true,
      width: 95,
      field: 'Total',
      sortable: true,
      valueFormatter: params => {
        const convertedDate = this.formatCurrencyPipe.transform(params.value);
        return convertedDate ? convertedDate : '';
      }
    },
    {
      headerName: 'Transaction Date',
      resizable: true,
      sortable: true,
      width: 150,
      field: 'CreatedOn',
      valueFormatter: params => {
        const convertedDate = this.datePipe.transform(params.value, 'MMM d, y HH:mm', 'UTC');
        return convertedDate ? convertedDate : '';
      }
    },
    {
      headerName: 'Ticket Numbers',
      resizable: true,
      sortable: true,
      width: 120,
      field: 'TicketNumbers',
      valueFormatter: params => {
        return params.value.toString();
      }
    },
    {
      headerName: 'Draw Date',
      resizable: true,
      sortable: true,
      width: 250,
      valueFormatter: params => {
        const convertedEndDate = this.datePipe.transform(params.data.GameInstanceEndedOn, 'MMM d, y', 'UTC');
        if (convertedEndDate) {
          return convertedEndDate;
        }
        return '';
      }
    },
  ];

  constructor(private matDialog: MatDialog,
              private datePipe: DatePipe,
              private gameService: GameService,
              private dateService: DateService,
              private permissionService: PermissionsService,
              private dialogFunctionService: DialogFunctionService,
              private errorHandlingService: ErrorHandlingService,
              private formatCurrencyPipe: FormatCurrencyPipe,
              private playerService: PlayersService) {
  }

  getGameDataForPlayer() {
    this.getTicketNumbers();
    this.uiState = UIStateEnum.ShowLoading;
    this.playerService.getPlayerTransactionsByGameId(this.chosenPlayer?.Id!, this.activeGame?.Id!).subscribe({
      next: (playerTransactionsP) => {
        this.uiState = UIStateEnum.ShowData;
        this.playerTransactionsPerGame = playerTransactionsP;
        this.filteredTransactionsPerGame = playerTransactionsP.sort((a, b) =>
          new Date(a.CreatedOn).getTime() - new Date(b.CreatedOn).getTime()
        );
      }, error: (err) => {
        this.errorHandlingService.displayPageLevelErrorMessage(err, 'Looks there was an issue retrieving player transactions.' +
          ' Please try reloading and if the problem persists contact a system administrator');
      }
    });
  }

  getTicketNumbers() {
    this.uiState = UIStateEnum.ShowLoading;
    // when subject gets pushed a value (will happen on de-active ticket numbers) get updated ticket data from api.
    this.playerService.fetchUpdatedTicketNumbersSubject$
      .pipe(
        mergeMap(() => this.playerService.getPlayerNumbersByGameId(this.chosenPlayer?.Id!, this.activeGame?.Id!))
      ).subscribe({
      next: (playerNumbersP) => {
        if (playerNumbersP && playerNumbersP.length > 0) {
          this.uiState = UIStateEnum.ShowData;
          this.playerNumberGroupRows = this.formatNumbersTableData(playerNumbersP);
          this.userHasActiveStrips = this.playerNumberGroupRows.some((groupRow) => groupRow.Active)
        }
      },
      error: (err) => {
        this.errorHandlingService.displayPageLevelErrorMessage(err, 'Looks there was an issue retrieving player tickets.' +
          ' Please try reloading and if the problem persists contact a system administrator');
      }
    })
  }

  onTransactionGridReady(params: GridReadyEvent<IPlayerPaymentTransactions>) {
    this.transactionGridApi = params.api;
    this.transactionGridApi.sizeColumnsToFit({
      defaultMinWidth: 100,
    });
  }

  onTicketGridReady(params: GridReadyEvent<IPlayerNumberGroupRow>) {
    this.ticketsGridApi = params.api;
    this.ticketsGridApi.sizeColumnsToFit({
      defaultMinWidth: 100,
    });
  }

  getTicketNumbersFromListOfTickets(ticketsP: IPlayerNumberQueryResult[]) {
    let groupTicketNumbers: number[] = ticketsP.map((ticket) => ticket.TicketNumber);
    return groupTicketNumbers.toString();
  }

  formatNumbersTableData(numberGroupsP: IPlayerNumbersByGroupId[]): IPlayerNumberGroupRow[] {
    let numberGroupRows: IPlayerNumberGroupRow[] = [];

    numberGroupsP.forEach(group => {
      numberGroupRows.push({
        TicketNumbers: this.getTicketNumbersFromListOfTickets(group.PlayerNumbers),
        TicketNumberIds: group.PlayerNumbers.map((ticket) => ticket.Id),
        Active: group.PlayerNumbers[0].Active,
        Autoplay: group.PlayerNumbers[0].Autoplay,
        PlayerId: this.chosenPlayer?.Id!,
        GroupId: group.GroupId
      })
    });
    return numberGroupRows;
  }

  onResetClick() {
    this.transactionSearchForm.reset();
    this.filteredTransactionsPerGame = this.playerTransactionsPerGame;
  }

  onFormSubmit() {
    if (!this.transactionSearchForm.valid) {
      this.transactionSearchForm.markAllAsTouched();
      return;
    }
    this.filteredTransactionsPerGame = this.playerTransactionsPerGame.filter((transactionP) => {
      let ticketNumberFilterPasses: boolean = true;
      let stripeIdFilterPasses: boolean = true;
      let dateFilterPasses: boolean = true;

      if (this.transactionSearchForm.value.ticketNumber) {
        ticketNumberFilterPasses = transactionP.TicketNumbers.includes(Number(this.transactionSearchForm.value.ticketNumber));
      }

      if (this.transactionSearchForm.value.stripeId) {
        stripeIdFilterPasses = transactionP.StripeChargeId.includes(this.transactionSearchForm.value.stripeId);
      }

      if (this.transactionSearchForm.value.transactionDate) {
        const parsedDate = new Date(this.transactionSearchForm.value.transactionDate);
        dateFilterPasses = this.dateService.areDatesSameDay(transactionP.CreatedOn, parsedDate)
      }


      return ticketNumberFilterPasses && stripeIdFilterPasses && dateFilterPasses;
    })
  }

  private addActionsColIfHasPermission() {
    if (this.permissionService.userHasPermission(PermissionTypes.ModifyPlayerNumbers)) {
      this.ticketsColumnDefs.push(this.actionsColDef)
    }
  }

  public onDeactivateAllTicketsPlayerClick() {
    this.dialogFunctionService.setCallback(() => {
      let deactivatePlayerCommand: IDeactivatePlayerNumberGroup = {
        PlayerId: this.chosenPlayer?.Id!,
        PlayerNumberIds: this.playerNumberGroupRows
          .filter(rowP => rowP.Active)
          .flatMap(rowP => rowP.TicketNumberIds),
        GameId: this.gameService.activeGame().Id
      };
      return this.playerService.deactivatePlayerNumbersByGroup(deactivatePlayerCommand)
    });


    const dialogData: IDialogGenericData = {
      playerData: {
        firstName: this.chosenPlayer?.FirstName!,
        lastName: this.chosenPlayer?.LastName!,
        email: this.chosenPlayer?.Email!
      },
      successMessage: 'Successfully deactivated all ticket Strips',
      message: 'Are you sure that you want to deactivate all ticket strips for the following user:',
      title: 'Deactivate All Ticket Strips',
      extraDetails: `This deactivates ${this.activeGame?.Name}'s ticket strips only, leaving other game strips unaffected.`,
      dataCyTag: 'confirm-deactivate-numbers'
    }

    const dialogRef = this.matDialog.open(ConfirmationActionDialogComponent, {
      data: dialogData,
      width: '650px'
    });

    dialogRef.afterClosed().subscribe(
      {
        next: (updateSuccessful: boolean) => {
          if (updateSuccessful) {
            this.getTicketNumbers()
          }
        }
      })
  }
}
