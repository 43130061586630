<div class="page-container">
  <ng-container *ngIf="chosenPlayerInfo">
    <h2>{{chosenPlayerInfo.FirstName}} {{chosenPlayerInfo.LastName}}
      <small>({{chosenPlayerInfo.Id}})</small>
      <button mat-icon-button
              data-cy="open-manage-game-options-menu-button"
              id="charity-menu-button"
              *hasPermission="permissionTypes.UpdatePlayerActivationStatus"
              [matMenuTriggerFor]="menu">
        <mat-icon matTooltipPosition="right"
                  matTooltip="open admin user menu"
        >more_vertical
        </mat-icon>
      </button>
    </h2>
    <div class="margin-bottom-med">
      <button mat-raised-button (click)="chooseNewPlayerClick()">
        <mat-icon>arrow_back_ios</mat-icon>
        Back
      </button>
    </div>

    <mat-card class="margin-bottom-med">
      <mat-card-header>
        <mat-card-title>Player General Info</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <ng-container *ngIf="uiState === uiStateForTemplate.ShowData">
          <div class="flex-row-wrap justify-start gap-normal margin-bottom-med margin-top-med">
            <div class="detail-section">
              <div class="detail-label">Player Name</div>
              <div class="detail-value"
                   data-cy="manage-player-name">{{chosenPlayerInfo.FirstName}} {{chosenPlayerInfo.LastName}}</div>
            </div>

            <div class="detail-section">
              <div class="detail-label">E-mail</div>
              <div class="detail-value" data-cy="manage-player-email">{{chosenPlayerInfo.Email}}</div>
            </div>

            <div class="detail-section">
              <div class="detail-label">Player Status</div>
              <div class="detail-value" data-cy="manage-player-status">
                <span
                  [class]="chosenPlayerInfo.Active ? 'green-text': 'red-text'">{{chosenPlayerInfo.Active ? 'Active' : 'Deactivated'}}</span>
              </div>
            </div>

            <div class="detail-section">
              <div class="detail-label">Phone</div>
              <div class="detail-value">{{chosenPlayerInfo.Phone}}</div>
            </div>

            <div class="detail-section">
              <div class="detail-label">Player Since</div>
              <div class="detail-value">{{chosenPlayerInfo.CreatedOn | date: 'yyyy-MM-dd h:mm a'}}</div>
            </div>
          </div>

          <div class="flex-row-wrap justify-start gap-normal margin-bottom-med margin-top-med">

            <div class="detail-section">
              <div class="detail-label">Agreed to Terms of Service</div>
              <div class="detail-value">
                <mat-icon
                  [class]="chosenPlayerInfo.AgreedToTermsOfService ? 'green-text': 'red-text'">{{chosenPlayerInfo.AgreedToTermsOfService ? 'check' : 'clear'}}
                  check
                </mat-icon>
              </div>
            </div>

            <div class="detail-section">
              <div class="detail-label">Agreed To Terms Of Service On</div>
              <div class="detail-value">{{chosenPlayerInfo.AgreedToTermsOfServiceOn | date: 'yyyy-MM-dd h:mm a'}}</div>
            </div>
          </div>

          <ng-container *ngIf="chosenPlayerInfo.PlayerCharityPledge">
            <h4>Charity Pledge</h4>

            <div class="flex-row-wrap justify-start gap-normal margin-bottom-large">
              <div class="detail-section">
                <div class="detail-label">Charity Name</div>
                <div class="detail-value"
                     data-cy="manage-player-charity-name">{{chosenPlayerInfo.PlayerCharityPledge.CharityName}}</div>
              </div>

              <div class="detail-section">
                <div class="detail-label">Charity Id</div>
                <div class="detail-value"
                     data-cy="manage-player-charity-id">{{chosenPlayerInfo.PlayerCharityPledge.CharityId}}</div>
              </div>

              <div class="detail-section">
                <div class="detail-label">Last Updated On</div>
                <div
                  class="detail-value">{{chosenPlayerInfo.PlayerCharityPledge.CreatedOn | date: 'MMM d, y HH:mm': 'UTC'}}</div>
              </div>
            </div>
          </ng-container>

          <app-player-game-info [chosenPlayerSetter]="chosenPlayerInfo"></app-player-game-info>

        </ng-container>

      </mat-card-content>
    </mat-card>
    <ng-container *ngIf="uiState === uiStateForTemplate.ShowLoading">
      <mat-spinner></mat-spinner>
    </ng-container>
  </ng-container>
</div>
<mat-menu #menu="matMenu">
  <ng-container *ngIf="chosenPlayerInfo">
    <button mat-menu-item
            *hasPermission="permissionTypes.AdminUpdatePlayerBasicInfo"
            (click)="onUpdateBasicInfoClick()"
            data-cy="edit-category-name-option">
      <mat-icon class="dark-grey menu-icon">edit</mat-icon>
      <span class="dark-grey menu-text">Edit Basic Info</span>
    </button>
    <ng-container *hasPermission="permissionTypes.UpdatePlayerActivationStatus">
      <button
        mat-menu-item
        data-cy="deactivate-player-button"
        *ngIf="chosenPlayerInfo.Active"
        class="red-text float-right"
        (click)="onDeactivatePlayerClick()">
        Deactivate player
        <mat-icon class="red-text">do_disturb</mat-icon>
      </button>
      <button
        mat-menu-item
        data-cy="reactivate-player-button"
        *ngIf="!chosenPlayerInfo.Active"
        class="green-text float-right"
        (click)="onReactivatePlayerClick()">
        Reactivate player
        <mat-icon>refresh</mat-icon>
      </button>
    </ng-container>
  </ng-container>
</mat-menu>
