import {Component} from '@angular/core';
import {OidcService} from "../../services/oidc.service";

@Component({
  selector: 'app-user-is-not-admin-page',
  templateUrl: './user-is-not-admin-page.component.html',
  styleUrls: ['./user-is-not-admin-page.component.scss']
})
export class UserIsNotAdminPageComponent {

  constructor(private oidcService: OidcService) {
  }

  onLogoutClick(): void {
    this.oidcService.logout();
  }

}
