import {Injectable} from '@angular/core';
import {APIService} from "./api.service";
import {ICharitiesByCategoryResult} from "../interfaces/charity/ICharitiesByCategoryResult";
import {BehaviorSubject, concatMap, map, Observable, of, tap} from "rxjs";
import {IUpdateCharityCategorySortOrder} from "../interfaces/charity/IUpdateCharityCategorySortOrder";
import {IUpdateCharitySortOrder} from "../interfaces/charity/IUpdateCharitySortOrder";
import {ICharityCategory} from "../interfaces/charity/ICharityCategory";
import {ICreateCharityCommand} from "../interfaces/charity/ICreateCharityCommand";
import {IApiResponseBase} from "../interfaces/IApiResponseBase";
import {IUpdateCharityCommand} from "../interfaces/charity/IUpdateCharityCommand";
import {ICreateCharityCategoryCommand} from "../interfaces/charity/ICreateCharityCategoryCommand";
import {ICreateCharityCategoryResponse} from "../interfaces/charity/ICreateCharityCategoryResponse";
import {ICharityPaymentBalance} from "../interfaces/reporting/ICharityPaymentBalance";
import {ICharityPayments} from "../interfaces/reporting/ICharityPayments";
import {ICharityContacts} from "../interfaces/reporting/ICharityContacts";
import {IUpdateCharityCategoryNameCommand} from "../interfaces/charity/IUpdateCharityCategoryNameCommand";
import {AddNewCharityComponent} from "../components/dialogs/add-new-charity/add-new-charity.component";
import {MatDialog} from "@angular/material/dialog";
import {createStore} from "@ngneat/elf";
import {
  selectActiveEntity,
  selectAllEntities,
  setActiveId,
  setEntities,
  withActiveId,
  withEntities
} from "@ngneat/elf-entities";
import {withRequestsCache} from "@ngneat/elf-requests";
import {CharityScreens} from "../enum/CharityScreens";
import {ICharityWithContributionTotals} from "../interfaces/charity/ICharityWithContributionTotals";
import {IDeactivateCharity} from "../interfaces/charity/IDeactivateCharity";
import {IReactivateCharity} from "../interfaces/charity/IReactivateCharity";
import {IMoveCharity} from "../interfaces/charity/IMoveCharity";
import {IReactivateCharityCategory} from "../interfaces/charity/IReactivateCharityCategory";
import {IDeactivateCharityCategory} from "../interfaces/charity/IDeactivateCharityCategory";

@Injectable({
  providedIn: 'root'
})
export class CharityService {

  private mockCharityBreakdown: ICharityPaymentBalance[] = [
    {
      CharityId: '1565108f-351f-4947-9e16-20de0d3301da',
      CharityName: 'Artistic Swimming (Synchro) PEI',
      TotalContributions: 100000,
      TotalOwedToCharity: 60000,
      TotalPaidToCharity: 40000
    },
    {
      CharityId: '137cb3d0-898f-41da-97b0-50272df8bc1b',
      CharityName: 'Athletics PEI',
      TotalContributions: 50000,
      TotalOwedToCharity: 20000,
      TotalPaidToCharity: 30000
    },
    {
      CharityId: 'c58dd0a9-418d-4515-8534-113266d5611b',
      CharityName: 'Basketball PEI Provincial Programs',
      TotalContributions: 80000,
      TotalOwedToCharity: 25000,
      TotalPaidToCharity: 55000
    },
    {
      CharityId: 'f46474c4-0828-424f-8a39-cac7b9c7ab4d',
      CharityName: 'Charlottetown Ringette Association',
      TotalContributions: 80000,
      TotalOwedToCharity: 60000,
      TotalPaidToCharity: 20000
    },
    {
      CharityId: 'db5c9e3c-a317-4a5b-ad7b-4ff7149997de',
      CharityName: 'Cornwall Minor Baseball Association',
      TotalContributions: 120000,
      TotalOwedToCharity: 100000,
      TotalPaidToCharity: 20000
    }
  ];

  public charityPayments: ICharityPayments[] = [
    {
      DateOfPayment: new Date('2022-12-07'),
      CreatedByUser: 'nwpeconi',
      PaymentAmount: 6000
    },
    {
      DateOfPayment: new Date('2022-12-14'),
      CreatedByUser: 'nwpeconi',
      PaymentAmount: 125000
    },
    {
      DateOfPayment: new Date('2022-12-21'),
      CreatedByUser: 'nwpeconi',
      PaymentAmount: 65000
    },
    {
      DateOfPayment: new Date('2022-12-28'),
      CreatedByUser: 'nwpeconi',
      PaymentAmount: 60000
    },
    {
      DateOfPayment: new Date('2023-01-04'),
      CreatedByUser: 'nwpeconi',
      PaymentAmount: 2700
    }
  ];

  public charityContacts: ICharityContacts[] = [
    {
      FirstName: 'Steve',
      Email: 'stevewhatver@gmail.com',
      LastName: 'Whatever',
      Phone: '902-909-0090'
    },
    {
      FirstName: 'Layla',
      Email: 'laylaFaro@gmail.com',
      LastName: 'Faro',
      Phone: '902-123-7865'
    },
    {
      FirstName: 'Doris',
      Email: 'dorisH@gmail.com',
      LastName: 'Hildr',
      Phone: '902-453-9820'
    }
  ];

  private charitiesByCategoryStore = createStore(
    {name: 'charities-by-category-state'},
    withEntities<ICharitiesByCategoryResult, 'CategoryId'>({idKey: 'CategoryId'}),
    withActiveId(),
    withRequestsCache<'charities-by-category'>()
  );

  public activeCharityScreenBehaviourSubject: BehaviorSubject<CharityScreens> = new BehaviorSubject<any>(CharityScreens.AllCategories);
  public activeCharityBehaviourSubject: BehaviorSubject<ICharityWithContributionTotals | undefined> = new BehaviorSubject<any>(undefined);
  public activeCharityScreen$: Observable<any> = this.activeCharityScreenBehaviourSubject.asObservable();
  public activeCharity$: Observable<ICharityWithContributionTotals | undefined> = this.activeCharityBehaviourSubject.asObservable();
  public charityCategories$: Observable<ICharitiesByCategoryResult[]> = this.charitiesByCategoryStore.pipe(selectAllEntities());

  constructor(private apiService: APIService,
              private matDialog: MatDialog) {
  }

  public openAddCharityDialog() {
    this.matDialog.open(AddNewCharityComponent, {
      width: '650px'
    });
  }

  public charityCategoriesByGame(gameIdP: string): Observable<ICharityCategory[]> {
    return this.apiService.MakeGetRequest<{
      CharityCategories: ICharityCategory[]
    }>(`charity/categories-by-game/${gameIdP}`)
      .pipe(map((res) => res.CharityCategories));
  }

  public createNewCharity(createCharityCommandP: ICreateCharityCommand): Observable<IApiResponseBase> {
    return this.apiService.MakePostRequest<IApiResponseBase>(`charity/create-multiple`, createCharityCommandP).pipe(concatMap((createRes) => {
      return this.charitiesPerGameByCategory(createCharityCommandP.gameId).pipe(map(() => createRes));
    }));
  }

  public createNewCharityCategory(createCharityCategoryCommandP: ICreateCharityCategoryCommand): Observable<ICreateCharityCategoryResponse> {
    return this.apiService.MakePostRequest<ICreateCharityCategoryResponse>(`charity/category/create`, createCharityCategoryCommandP).pipe(concatMap((createRes) => {
      return this.charitiesPerGameByCategory(createCharityCategoryCommandP.gameId).pipe(map(() => createRes));
    }));
  }

  public updateCharity(updateCharityCommandP: IUpdateCharityCommand): Observable<ICharitiesByCategoryResult[]> {
    return this.apiService.MakePutRequest<IApiResponseBase>(`charity`, updateCharityCommandP).pipe(concatMap(() => {
      return this.charitiesPerGameByCategory(updateCharityCommandP.gameId).pipe(tap((charitiesByGroupP) => {
        let activeCharity = charitiesByGroupP.flatMap((charityGroup) => charityGroup.Charities).find((charityP) => charityP.Id === updateCharityCommandP.charityId);
        this.activeCharityBehaviourSubject.next(activeCharity);
      }));
    }));
  }

  public deactivateCharity(deactivateCharityCommandP: IDeactivateCharity): Observable<ICharitiesByCategoryResult[]> {
    return this.apiService.MakePutRequest<IApiResponseBase>(`charity/deactivate`, deactivateCharityCommandP).pipe(concatMap(() => {
      return this.updateActiveCharityState(deactivateCharityCommandP.charityId, deactivateCharityCommandP.gameId);
    }));
  }

  public moveCharity(moveCharityCommandP: IMoveCharity): Observable<ICharitiesByCategoryResult[]> {
    return this.apiService.MakePutRequest<IApiResponseBase>(`charity/move`, moveCharityCommandP).pipe(concatMap(() => {
      return this.updateActiveCharityState(moveCharityCommandP.charityId, moveCharityCommandP.gameId);
    }));
  }

  public reactivateCharity(reactivateCharityCommandP: IReactivateCharity): Observable<ICharitiesByCategoryResult[]> {
    return this.apiService.MakePutRequest<IApiResponseBase>(`charity/reactivate`, reactivateCharityCommandP).pipe(concatMap(() => {
      return this.updateActiveCharityState(reactivateCharityCommandP.charityId, reactivateCharityCommandP.gameId)
    }));
  }

  public reactivateCharityCategory(reactivateCharityCommandP: IReactivateCharityCategory): Observable<ICharitiesByCategoryResult[]> {
    return this.apiService.MakePutRequest<IApiResponseBase>(`charity/category-reactivate`, reactivateCharityCommandP).pipe(concatMap(() => {
      return this.charitiesPerGameByCategory(reactivateCharityCommandP.gameId);
    }))
  }

  public deactivateCharityCategory(deactivateCharityCommandP: IDeactivateCharityCategory): Observable<ICharitiesByCategoryResult[]> {
    return this.apiService.MakePutRequest<IApiResponseBase>(`charity/category-deactivate`, deactivateCharityCommandP).pipe(concatMap(() => {
      return this.charitiesPerGameByCategory(deactivateCharityCommandP.gameId);
    }))
  }

  public updateCharityCategoryName(updateCharityCommandP: IUpdateCharityCategoryNameCommand): Observable<ICharitiesByCategoryResult[]> {
    return this.apiService.MakePutRequest('charity/category', updateCharityCommandP).pipe(concatMap(() => {
      return this.charitiesPerGameByCategory(updateCharityCommandP.gameId);
    }))
  }

  private updateActiveCharityState(charityIdP: string, gameIdP: string) {
    return this.charitiesPerGameByCategory(gameIdP).pipe(tap((charitiesByGroupP) => {
      let activeCharity = charitiesByGroupP.flatMap((charityGroup) => charityGroup.Charities).find((charityP) => charityP.Id === charityIdP);
      this.activeCharityBehaviourSubject.next(activeCharity);
    }));
  }

  public charitiesPerGameByCategory(gameIdP: string): Observable<ICharitiesByCategoryResult[]> {
    return this.apiService.MakeGetRequest<{
      CharitiesByCategory: ICharitiesByCategoryResult[]
    }>(`charity/by-category/for-game/${gameIdP}/${true}`)
      .pipe(map((res) => {
        const categories = res.CharitiesByCategory.sort((a, b) => a.Category.SortOrder - b.Category.SortOrder);
        this.charitiesByCategoryStore.update(setEntities(categories));
        return res.CharitiesByCategory;
      }));
  }

  public setActiveCharityCategory(idP: string): void {
    this.charitiesByCategoryStore.update(setActiveId(idP));
    this.activeCharityScreenBehaviourSubject.next(CharityScreens.Category);
  }

  public selectActiveCategory(): Observable<ICharitiesByCategoryResult | undefined> {
    return this.charitiesByCategoryStore.pipe(selectActiveEntity());
  }

  public updateCharityCategorySortOrder(updateSortOrderP: IUpdateCharityCategorySortOrder): Observable<ICharitiesByCategoryResult[]> {
    return this.apiService.MakePutRequest('charity/update-charity-category-sort-order', updateSortOrderP).pipe(concatMap(() => {
      return this.charitiesPerGameByCategory(updateSortOrderP.gameId);
    }))
  }

  public updateCharitySortOrder(updateSortOrderP: IUpdateCharitySortOrder): Observable<ICharitiesByCategoryResult[]> {
    return this.apiService.MakePutRequest('charity/update-charity-sort-order', updateSortOrderP).pipe(concatMap(() => {
      return this.charitiesPerGameByCategory(updateSortOrderP.gameId);
    }))
  }

  //TODO: Tie in real API logic
  public charityBalancesPerGame(gameIdP: string): Observable<ICharityPaymentBalance[]> {
    return of(this.mockCharityBreakdown)
    // return this.apiService.MakeGetRequest(`charity/balances-per-game/${gameIdP}`)
  }

  public paymentsPerCharity(charityIdP: string): Observable<ICharityPayments[]> {
    return of(this.charityPayments)
    // return this.apiService.MakeGetRequest(`charity/balances-per-game/${gameIdP}`)
  }

  public contactsPerCharity(charityIdP: string): Observable<ICharityContacts[]> {
    return of(this.charityContacts)
    // return this.apiService.MakeGetRequest(`charity/balances-per-game/${gameIdP}`)
  }


}
