import {Component, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UsersService} from "../../../services/users.service";
import {IInviteGameAdminUserRequest} from "../../../interfaces/IInviteGameAdminUserRequest";
import {SnackbarService} from "../../../services/snackbar.service";
import {GameService} from "../../../services/game.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AdminInviteType} from "../../../enum/AdminInviteType";
import {Observable} from "rxjs";
import {ValidateAdminEmailInviteResult} from "../../../interfaces/ICheckIfUserExistsResult";
import {InvalidEmailEntryReasonType} from "../../../enum/InvalidEmailEntryReasonType";
import {GameAdminInviteUIEnum} from "../../../enum/GameAdminInviteUIEnum";
import {IGenericAdminUserRequest} from "../../../interfaces/IGenericAdminUserRequest";
import {IAdminQueryResult} from "../../../interfaces/IAdminQueryResult";
import {IAddNewRoleCommand} from "../../../interfaces/IAddNewRoleCommand";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {IRole} from "../../../interfaces/IRole";
import {PermissionsService} from "../../../services/permissions.service";

@Component({
  selector: 'app-add-new-admin',
  templateUrl: './add-new-admin.component.html',
  styleUrls: ['./add-new-admin.component.css']
})
export class AddNewAdminComponent {

  public uiState: GameAdminInviteUIEnum = GameAdminInviteUIEnum.ValidateEmail;
  public uiStateEnumForTemplate = GameAdminInviteUIEnum;
  public newUserVerificationCode: string = '';
  public invalidEmailReason: InvalidEmailEntryReasonType | undefined;
  public existingAdmin: IAdminQueryResult | undefined;
  public possibleRoles: IRole[] = [];
  public adminInviteTypeForTemplate = AdminInviteType;
  public newUserEmail: string = '';
  public addAdminForm = new FormGroup({
    firstName: new FormControl<string>('', Validators.required),
    lastName: new FormControl<string>('', Validators.required),
    newRoles: new FormControl<string[]>([])
  });

  public updateExistingAdminForm = new FormGroup({
    newRolesForExistingAdmin: new FormControl<string[]>([])
  });

  constructor(private usersService: UsersService,
              private gameService: GameService,
              private permissionService: PermissionsService,
              private matDialogRef: MatDialogRef<AddNewAdminComponent>,
              private errorHandlingService: ErrorHandlingService,
              private snackBarService: SnackbarService,
              @Inject(MAT_DIALOG_DATA) public adminInviteType: AdminInviteType) {
    if (adminInviteType == AdminInviteType.GameAdminInvite) {
      this.permissionService.fetchPossibleRoles(this.gameService.activeGame().Id).subscribe({
        next: (rolesP) => {
          this.possibleRoles = rolesP;
        },
        error: (error) => {
          this.errorHandlingService.displayDialogLevelErrorMessage(error);
        }
      })
    }
  }

  validateEmail() {
    this.uiState = GameAdminInviteUIEnum.ShowLoading;
    this.invalidEmailReason = undefined;
    const gameId = this.gameService.activeGame()?.Id;

    if (gameId) {
      let validateEmailApiCall: Observable<ValidateAdminEmailInviteResult>;

      switch (this.adminInviteType) {
        case AdminInviteType.GameAdminInvite: {
          validateEmailApiCall = this.usersService.validateEmailForGameAdminInvite(this.newUserEmail, gameId);
          break;
        }
        case AdminInviteType.CausableInvite: {
          validateEmailApiCall = this.usersService.validateEmailForCausableAdminInvite(this.newUserEmail);
          break;
        }
        default: {
          return;
        }
      }

      validateEmailApiCall!.subscribe({
        next: (res) => {
          if (res.CanAddUserAsAdmin) {
            // email passes check so the existing admin can be added to this game/org
            if (res.ExistingAdminData) {
              this.existingAdmin = res.ExistingAdminData;
              this.uiState = GameAdminInviteUIEnum.AddExistingUser;
            } else {
              // admin does not exist yet so the email can be added to admin app
              this.uiState = GameAdminInviteUIEnum.InputUserData;
            }
          } else {
            // user email cannot be used
            this.invalidEmailReason = res?.ReasoningMessageType;
            this.uiState = GameAdminInviteUIEnum.ValidateEmail;
          }
        },
        error: (error) => {
          this.uiState = GameAdminInviteUIEnum.ValidateEmail;
          this.errorHandlingService.displayDialogLevelErrorMessage(error);
        }
      })
    }
  }

  sendInvite() {
    if (this.adminInviteType === AdminInviteType.GameAdminInvite) {
      this.sendGameAdminInvite();
    } else if (this.adminInviteType === AdminInviteType.CausableInvite) {
      this.sendCausableAdminInvite();
    }
  }

  addExistingUser() {
    if (this.adminInviteType === AdminInviteType.GameAdminInvite) {
      this.updateExistingGameAdmin();
    }
  }

  private updateExistingGameAdmin() {
    const activeGame = this.gameService.activeGame();
    if (activeGame && this.updateExistingAdminForm.valid && this.existingAdmin) {
      this.uiState = GameAdminInviteUIEnum.ShowLoading;

      const gameAdminRequest: IAddNewRoleCommand = {
        targetAdminId: this.existingAdmin.Id,
        gameId: activeGame.Id,
        roleIds: this.updateExistingAdminForm.controls.newRolesForExistingAdmin.value!,
        adminEmail: this.existingAdmin.Email,
        adminFirstName: this.existingAdmin.FirstName,
        adminLastName: this.existingAdmin.LastName,
        gameName: activeGame.Name,
        redirectUrl: `${window.location.origin}/login`
      }

      this.permissionService.addNewUserRole(gameAdminRequest).subscribe({
        next: () => {
          this.snackBarService.openSuccessfulSnackBar('Successfully Added New Game Admin');
          this.matDialogRef.close(true);
        },
        error: (error) => {
          this.uiState = GameAdminInviteUIEnum.ValidateEmail;
          this.errorHandlingService.displayDialogLevelErrorMessage(error);
        }
      })
    }
  }

  private sendCausableAdminInvite() {
    if (this.addAdminForm.valid) {
      this.uiState = GameAdminInviteUIEnum.ShowLoading;
      const inviteRequest: IGenericAdminUserRequest = {
        email: this.newUserEmail,
        lastName: this.addAdminForm.controls.lastName.value!,
        firstName: this.addAdminForm.controls.firstName.value!,
        redirectUrl: `${window.location.origin}/login`,
        adminInviteType: this.adminInviteType
      }

      this.usersService.sendCausableAdminUserInvite(inviteRequest).subscribe({
        next: (res) => {
          this.snackBarService.openSuccessfulSnackBar('Invite Sent Successfully')
          this.newUserVerificationCode = res.AdminVerificationCode;
          this.uiState = GameAdminInviteUIEnum.ViewVerificationCode;
          this.usersService.getUpdatedAdminUsersBehaviorSubject.next(true);
        },
        error: (error) => {
          this.uiState = GameAdminInviteUIEnum.ValidateEmail;
          this.errorHandlingService.displayDialogLevelErrorMessage(error);
        }
      })
    }
  }

  private sendGameAdminInvite() {
    const activeGame = this.gameService.activeGame();

    if (activeGame && this.addAdminForm.valid) {
      this.uiState = GameAdminInviteUIEnum.ShowLoading;
      const inviteRequest: IInviteGameAdminUserRequest = {
        email: this.newUserEmail,
        lastName: this.addAdminForm.controls.lastName.value!,
        firstName: this.addAdminForm.controls.firstName.value!,
        gameId: activeGame.Id,
        gameName: activeGame.Name,
        redirectUrl: `${window.location.origin}/login`,
        gameAdminRoleIds: this.addAdminForm.controls.newRoles.value!
      }

      this.usersService.sendNewGameAdminUserInvite(inviteRequest).subscribe({
        next: (res) => {
          this.snackBarService.openSuccessfulSnackBar('Invite Sent Successfully')
          this.newUserVerificationCode = res.AdminVerificationCode;
          this.uiState = GameAdminInviteUIEnum.ViewVerificationCode;
          this.usersService.getUpdatedAdminUsersBehaviorSubject.next(true);
        },
        error: (error) => {
          this.uiState = GameAdminInviteUIEnum.ValidateEmail;
          this.errorHandlingService.displayDialogLevelErrorMessage(error);
        }
      })
    }
  }
}
