import {Component} from '@angular/core';
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {SnackbarService} from "../../../services/snackbar.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {GameService} from "../../../services/game.service";
import {MatDialogRef} from "@angular/material/dialog";
import {PermissionsService} from "../../../services/permissions.service";
import {IAddRole} from "../../../interfaces/IAddRole";

@Component({
  selector: 'app-add-new-role',
  templateUrl: './add-new-role.component.html',
  styleUrls: ['./add-new-role.component.scss']
})
export class AddNewRoleComponent {
  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;
  public roleNameControl: FormControl<string | null> = new FormControl<string | null>('', Validators.required);
  public roleDescriptionControl: FormControl<string | null> = new FormControl<string | null>('', Validators.required);
  public createRoleForm = new FormGroup({
    roleName: this.roleNameControl,
    roleDescription: this.roleDescriptionControl
  });

  constructor(private permissionService: PermissionsService,
              private snackBarService: SnackbarService,
              private errorHandlingService: ErrorHandlingService,
              private gameService: GameService,
              private matDialogRef: MatDialogRef<AddNewRoleComponent>,) {
  }

  submitNewRole() {
    if (!this.createRoleForm.valid) {
      this.createRoleForm.markAsDirty();
      return;
    }

    this.uiState = UIStateEnum.ShowLoading;
    let newRoleCommand: IAddRole = {
      gameId: this.gameService.activeGame()?.Id!,
      name: this.roleNameControl.value!,
      description: this.roleDescriptionControl.value!,
    };

    this.permissionService.addNewRole(newRoleCommand).subscribe({
      next: (res) => {
        this.matDialogRef.close();
        this.snackBarService.openSuccessfulSnackBar('Successfully Added new Role');
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayDialogLevelErrorMessage(err);
      }
    })
  }
}
