<h3>Admin Audit Filter</h3>
<form [formGroup]="adminAuditFilterFormGroup" class="margin-bottom-med">
  <div class="flex-row-wrap justify-space-between">
    <mat-form-field class="flex-50" appearance="outline">
      <mat-label>Admin Id</mat-label>
      <input matInput placeholder="enter admin id" formControlName="adminId">
    </mat-form-field>
    <mat-form-field class="flex-50" appearance="outline">
      <mat-label>Event Type</mat-label>
      <mat-select placeholder="enter event type" formControlName="eventTypeValue">
        <mat-option *ngFor="let eventType of auditEventTypes" [value]="eventType.Value">
          {{eventType.Name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="flex-50" appearance="outline">
      <mat-label>Date Range</mat-label>
      <mat-date-range-input
        [rangePicker]="campaignOnePicker">
        <input matStartDate placeholder="From" formControlName="fromDate">
        <input matEndDate placeholder="To" formControlName="toDate">
      </mat-date-range-input>
      <mat-hint>MM-DD-YYYY – MM-DD-YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
      <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
    </mat-form-field>
  </div>
  <div class="text-end">
    <button [disabled]="!adminAuditFilterFormGroup.valid" mat-raised-button type="submit"
            class="primary-button margin-right-small" (click)="onFormSubmit()">Filter
    </button>
    <button mat-stroked-button class="primary-button" (click)="onResetClick()">Reset</button>
  </div>
</form>

<ag-grid-angular
  class="ag-theme-alpine margin-top-med"
  [tooltipShowDelay]=0
  [pagination]="true"
  domLayout='autoHeight'
  [suppressPaginationPanel]="true"
  (rowClicked)="onRowSelected($event)"
  overlayNoRowsTemplate="<span>No admin audits</span>"
  (gridReady)="onGridReady($event)"
  [rowData]="adminAudits$ | async"
  [columnDefs]="adminAuditColDefs">
</ag-grid-angular>
<div class="custom-paginator">
  <span>{{fromNumber}}</span> to <span>{{toNumber}}</span> of <span
  class="margin-right-large">{{totalRecords}}</span>
  <mat-icon (click)="onBtFirst()" [ngClass]="pageNumber === 1 ? 'disabled' : ''">first_page</mat-icon>
  <mat-icon (click)="onBtPrevious()" [ngClass]="pageNumber === 1 ? 'disabled' : ''">navigate_before</mat-icon>
  Page <span>{{pageNumber}}</span> of <span>{{totalPages}}</span>
  <mat-icon (click)="onBtNext()" [ngClass]="pageNumber === pagedAuditResponse?.TotalPages ? 'disabled' : ''">
    navigate_next
  </mat-icon>
  <mat-icon (click)="onBtLast()" [ngClass]="pageNumber === pagedAuditResponse?.TotalPages ? 'disabled' : ''">last_page
  </mat-icon>
</div>
