import {Component} from '@angular/core';
import {UIStateEnum} from "../../enum/UIStateEnum";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {IApiErrorResponse} from "../../interfaces/error/IApiErrorResponse";
import {UsersService} from "../../services/users.service";
import {ISendUpdateEmailAddressEmail} from "../../interfaces/ISendUpdateEmailAddressEmail";
import {SnackbarService} from "../../services/snackbar.service";
import {ActiveUserService} from "../../services/active-user.service";
import {ErrorHandlingService} from "../../services/error-handling.service";

@Component({
  selector: 'app-update-email-address',
  templateUrl: './update-email-address.component.html',
  styleUrls: ['./update-email-address.component.scss']
})
export class UpdateEmailAddressComponent {

  public uiStateEnum: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;
  public showPassword: boolean = false;
  public showConfirmationCompleteMessage: boolean = false;
  public activeAdmin = this.activeUserService.activeUser();
  public newEmailControl: FormControl = new FormControl<string>('', [
    Validators.required,
    Validators.email
  ]);
  public passwordControl: FormControl = new FormControl<string>('', Validators.required);

  public updateEmailForm: FormGroup = this.formBuilder.group({
    password: this.passwordControl,
    newEmail: this.newEmailControl
  })

  constructor(private formBuilder: FormBuilder,
              private userService: UsersService,
              private errorHandlingService: ErrorHandlingService,
              private snackBarService: SnackbarService,
              private activeUserService: ActiveUserService) {
  }

  updateEmailAddress() {

    if (!this.updateEmailForm.valid) {
      this.updateEmailForm.markAllAsTouched()
      return;
    }

    if (this.activeAdmin.Id) {
      this.updateEmailForm.disable();

      this.uiStateEnum = UIStateEnum.ShowLoading;

      const sendUpdateEmailEmail: ISendUpdateEmailAddressEmail = {
        password: this.passwordControl.value,
        newEmail: this.newEmailControl.value,
        adminId: this.activeAdmin.Id,
        oldEmail: this.activeAdmin.Email,
        redirectUrl: `${window.location.origin}/login`
      }

      this.userService.sendUpdateAdminEmailAddressEmail(sendUpdateEmailEmail).subscribe({
        next: () => {
          this.uiStateEnum = UIStateEnum.ShowData;
          this.showConfirmationCompleteMessage = true;
          this.snackBarService.openSuccessfulSnackBar('Email updated successfully!')
        },
        error: (res: IApiErrorResponse) => {
          this.updateEmailForm.enable();
          this.uiStateEnum = UIStateEnum.ShowData;
          if (res.Error?.ClientErrorMessages?.length > 0) {
            this.errorHandlingService.displayPageLevelErrorMessage(res, res.Error.ClientErrorMessages.toString())
          } else {
            this.errorHandlingService.displayPageLevelErrorMessage(res);
          }
        }
      })
    }
  }

}
