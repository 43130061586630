import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UIStateEnum} from "../../enum/UIStateEnum";
import {IGameQueryResult} from "../../interfaces/IGameQueryResult";
import {IGameInstance} from "../../interfaces/IGameInstance";
import {ColDef, GridApi, GridReadyEvent} from "ag-grid-community";
import {GameService} from "../../services/game.service";
import {GameInstanceStatusPipe} from "../../pipes/game-instance-status.pipe";
import {DatePipe} from "@angular/common";
import {GameInstanceService} from "../../services/game-instance.service";
import {FormatCurrencyPipe} from "../../pipes/format-currency.pipe";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {DateService} from "../../services/date.service";

@Component({
  selector: 'app-choose-game-instance',
  templateUrl: './choose-game-instance.component.html',
  styleUrls: ['./choose-game-instance.component.scss']
})
export class ChooseGameInstanceComponent implements OnInit {

  @Output() public chosenGameInstanceEvent: EventEmitter<IGameInstance> = new EventEmitter<IGameInstance>();
  public uiState: UIStateEnum = UIStateEnum.ShowLoading;
  public uiStateEnumForTemplate = UIStateEnum;
  public drawDate: Date | null = null;
  public chosenGame: IGameQueryResult | undefined = this.gameService.activeGame();
  public gameInstances: IGameInstance[] = [];
  public filteredGameInstances: IGameInstance[] = [];
  public columnDefs: ColDef[] = [
    {
      headerName: 'Draw Date',
      field: 'EndedOn',
      filter: 'agDateColumnFilter',
      valueFormatter: params => {
        const convertedDate = this.datePipe.transform(params.value, 'MMM d, y HH:mm', 'UTC');
        return convertedDate ? convertedDate : '';
      }
    },
    {
      headerName: 'Started On',
      field: 'StartedOn',
      filter: 'agDateColumnFilter',
      valueFormatter: params => {
        const convertedDate = this.datePipe.transform(params.value, 'MMM d, y', 'UTC');
        return convertedDate ? convertedDate : '';
      }
    },
    {
      headerName: 'State',
      field: 'State',
      valueFormatter: params => {
        return this.gameInstanceStatusPipe.transform(params.value)
      }
    },
    {
      headerName: 'Starting Pot',
      field: 'StartingPot',
      valueFormatter: params => {
        return this.formatCurrency.transform(params.value)
      }
    }
  ];


  public gameInstanceGridApi!: GridApi<IGameInstance>;

  constructor(private gameService: GameService,
              private gameInstanceStatusPipe: GameInstanceStatusPipe,
              private formatCurrency: FormatCurrencyPipe,
              private datePipe: DatePipe,
              private dateService: DateService,
              private errorHandlingService: ErrorHandlingService,
              private gameInstanceService: GameInstanceService) {
  }

  ngOnInit(): void {
    this.getGameInstancesForGame();
  }

  onRowSelected(rowDataP: any) {
    let gameInstanceFromChosenRow = this.gameInstances.find((instance) => instance.Id === rowDataP.data.Id);
    this.chosenGameInstanceEvent.emit(gameInstanceFromChosenRow)
  }

  onGameInstanceGridReady(params: GridReadyEvent<IGameInstance>) {
    this.gameInstanceGridApi = params.api;
    this.gameInstanceGridApi.sizeColumnsToFit({
      defaultMinWidth: 90,
    });
  }

  onDateInputChange(dateString: string) {
    if (dateString) {
      const parsedDate = new Date(dateString);

      if (!isNaN(parsedDate.getTime())) {
        this.filterResultsPerGameDrawDate(parsedDate);
      }

      return;
    }

    this.resetFilter();
  }

  public resetFilter() {
    this.drawDate = null;
    this.filteredGameInstances = this.gameInstances;
  }

  filterResultsPerGameDrawDate(parsedDateP: Date, overrideP: boolean = false) {

    if (parsedDateP && !overrideP) {
      this.filteredGameInstances = this.gameInstances.filter((reportP) => {
        return this.dateService.areDatesSameDay(reportP.EndedOn, parsedDateP)
      });

      return;
    }
    this.resetFilter();
  }

  public getGameInstancesForGame() {
    if (this.chosenGame) {
      this.gameInstanceService.getGameInstancesByGameId(this.chosenGame.Id).subscribe({
        next: (res) => {
          this.gameInstances = res;
          this.filteredGameInstances = this.gameInstances;
        }, error: (err) => {
          this.errorHandlingService.displayPageLevelErrorMessage(err);
        }
      })
    }
  }
}
