import {Component, OnInit} from '@angular/core';
import {CharityService} from "../../../services/charity.service";
import {ICharityCategory} from "../../../interfaces/charity/ICharityCategory";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {FormControl, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {ICreateCharityCommand} from "../../../interfaces/charity/ICreateCharityCommand";
import {SnackbarService} from "../../../services/snackbar.service";
import {AddNewCategoryComponent} from "../add-new-category/add-new-category.component";
import {concatMap, iif, Observable, of, tap} from "rxjs";
import {INewCharity} from "../../../interfaces/charity/INewCharity";
import {GameService} from "../../../services/game.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-add-new-charity',
  templateUrl: './add-new-charity.component.html',
  styleUrls: ['./add-new-charity.component.scss']
})
export class AddNewCharityComponent implements OnInit {

  public charityCategories: ICharityCategory[] = [];
  public uiState: UIStateEnum = UIStateEnum.ShowLoading;
  public showErrorMessage: boolean = false;
  public uiStateEnumForTemplate = UIStateEnum;
  public createCharityForm = new FormGroup({
    categoryControl: new FormControl<ICharityCategory | null>(null, Validators.required),
    charityNameControl: new FormControl<string | null>('', [this.notAddedToRequestValidator.bind(this)]),
    charityEnabled: new FormControl<boolean>(true)
  });
  public listOfNewCharities: INewCharity[] = [];
  public activeGame = this.gameService.activeGame();

  constructor(private charityService: CharityService,
              private gameService: GameService,
              private errorHandlingService: ErrorHandlingService,
              private matDialogRef: MatDialogRef<AddNewCharityComponent>,
              private matDialog: MatDialog,
              private snackBarService: SnackbarService) {
  }

  ngOnInit(): void {
    this.charityService.charityCategoriesByGame(this.activeGame?.Id!).subscribe({
      next: (categories) => {
        this.charityCategories = categories;
        this.uiState = UIStateEnum.ShowData;
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayDialogLevelErrorMessage(err);
      }
    });
  }


  notAddedToRequestValidator(): ValidationErrors | null {
    if (this.showErrorMessage) {
      return {notAddedToRequest: true};
    }

    return null;
  }

  addToCharityList() {
    this.showErrorMessage = false;
    const charityNameControl = this.createCharityForm.controls.charityNameControl;
    const chosenCategory = this.createCharityForm.controls.categoryControl;
    if (chosenCategory.value) {
      let newSortOrder = (chosenCategory.value.HighestCharitySortOrder + 1) + this.listOfNewCharities.length
      const newCharity: INewCharity = {
        active: true,
        name: charityNameControl.value!,
        sortOrder: newSortOrder
      };

      this.listOfNewCharities.push(newCharity);

      charityNameControl.setValue('');
    }

  }

  removeFromCharityList(itemP: INewCharity) {
    this.listOfNewCharities = this.listOfNewCharities.filter((charity) => charity.name !== itemP.name);
  }

  openAddNewCategoryDialog() {
    const dialog = this.matDialog.open(AddNewCategoryComponent, {
      width: '650px'
    });

    dialog.afterClosed()
      .pipe(concatMap((res) =>
        iif(() => res, this.getCharitiesUpdateCategory(res), of(false))))
      .subscribe({
        error: (err) => {
          this.uiState = UIStateEnum.ShowData;
          this.errorHandlingService.displayDialogLevelErrorMessage(err);
        }
      });
  }

  private getCharitiesUpdateCategory(newCategoryIdP: string): Observable<any> {
    return this.charityService.charityCategoriesByGame(this.activeGame?.Id!).pipe(tap((categories) => {
      this.charityCategories = categories;

      const newlyCreatedCat = this.charityCategories.find((cat) => cat.Id === newCategoryIdP);
      if (newlyCreatedCat) {
        this.createCharityForm.get('categoryControl')?.setValue(newlyCreatedCat)
      }
      this.uiState = UIStateEnum.ShowData;
    }));
  }

  submitNewCharities() {
    this.showErrorMessage = false;
    if (this.listOfNewCharities.length <= 0) {
      this.showErrorMessage = true;
      this.createCharityForm.controls.charityNameControl.updateValueAndValidity();
      return;
    }

    if (this.createCharityForm.valid) {
      let newCharityCommand: ICreateCharityCommand = {
        charityCategoryId: this.createCharityForm.get('categoryControl')!.value!.Id,
        gameId: this.activeGame?.Id!,
        newCharities: this.listOfNewCharities
      };

      this.charityService.createNewCharity(newCharityCommand).subscribe({
        next: (res) => {
          this.matDialogRef.close(res.IsSuccess);
          this.snackBarService.openSuccessfulSnackBar('Successfully Add New Charity');
        },
        error: (err) => {
          this.uiState = UIStateEnum.ShowData;
          this.errorHandlingService.displayDialogLevelErrorMessage(err, 'An error occurred while attempting to add your charity(ies). Please try again or contact your system administrator for assistance.');
        }
      })
    }
  }
}
