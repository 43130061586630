<app-component-level-error-message [errorMessage]="errorMessage"></app-component-level-error-message>
<ng-container *ngIf="uiState === uiStateForTemplate.ShowLoading">
  <app-loading-card-content></app-loading-card-content>
</ng-container>

<div *ngIf="uiState === uiStateForTemplate.ShowData && comprehensiveReport" class="margin-top-large">

  <div class="justify-space-between flex-row align-start">
    <div>
      <div class="section-header">Comprehensive Game Report</div>
      <div class="ticket-type-header">Date Range (UTC):</div>
      {{dateRanges.StartDate | date: 'MMM d, y HH:mm' : 'UTC'}} -
      {{dateRanges.EndDate | date: 'MMM d, y HH:mm' : 'UTC'}}
    </div>
    <div>
      <button mat-raised-button color="primary" (click)="generatePDF()">Generate
        PDF
      </button>
    </div>
  </div>

  <div class="margin-top-large">
    <h3>Financial Details</h3>
    <div class="margin-left-med flex-row-wrap justify-space-between align-start gap-normal">
      <div class="flex-report-item bottom-border">
        <div class="report-label">Gross Sales</div>
        <div class="report-value">{{comprehensiveReport.FinancialTotals.GrossTotalSales | formatCurrency}}</div>
      </div>
      <div class="flex-report-item bottom-border">
        <div class="report-label">Total Amount Raised For Charities</div>
        <div
          class="report-value">{{comprehensiveReport.FinancialTotals.CharityContributionTotal | formatCurrency}}</div>
      </div>
      <div class="flex-report-item bottom-border">
        <div class="report-label">Total Charity Host Admin Fees</div>
        <div
          class="report-value">{{comprehensiveReport.FinancialTotals.AdministrativeCommissionTotal | formatCurrency}}</div>
      </div>
      <div class="flex-report-item bottom-border">
        <div class="report-label">Total Fess Collected</div>
        <div class="report-value">{{comprehensiveReport.FinancialTotals.TotalFees| formatCurrency}}</div>
      </div>
      <div class="flex-report-item bottom-border">
        <div class="report-label">Total Taxes Collected</div>
        <div class="report-value">{{comprehensiveReport.FinancialTotals.TotalTaxes| formatCurrency}}</div>
      </div>
      <div class="flex-report-item bottom-border">
        <div class="report-label">Total Amount Contributed to Prizes</div>
        <div class="report-value">{{comprehensiveReport.FinancialTotals.PotContributionTotal| formatCurrency}}</div>
      </div>
    </div>

    <h3>Game Counts</h3>
    <div class="margin-left-med flex-row-wrap justify-space-between align-start gap-normal">
      <div class="flex-report-item bottom-border">
        <div class="report-label">Number of completed games</div>
        <div class="report-value">{{comprehensiveReport.GameWinnerData.TotalNumberOfCompletedGames}}</div>
      </div>
      <div class="flex-report-item bottom-border">
        <div class="report-label">Completed Games with no Winner</div>
        <div class="report-value">{{comprehensiveReport.GameWinnerData.NumberOfGamesWithNoWinners}}</div>
      </div>
      <div class="flex-report-item bottom-border">
        <div class="report-label">Completed Games with A Winner</div>
        <div class="report-value">{{comprehensiveReport.GameWinnerData.NumberOfCompletedGamesWithWinners}}</div>
      </div>
    </div>

    <h3>Players and Player Numbers</h3>
    <div class="margin-left-med flex-row-wrap justify-space-between align-start gap-normal">
      <div class="flex-report-item bottom-border">
        <div class="report-label">Count of Played Numbers</div>
        <div class="report-value">{{comprehensiveReport.TicketCounts.NumberOfTicketsPlayed}}</div>
      </div>
      <div class="flex-report-item bottom-border">
        <div class="report-label">Count of Non Played Numbers</div>
        <div class="report-value">{{comprehensiveReport.TicketCounts.NumberOfTicketsNotPlayed}}</div>
      </div>
      <div class="flex-report-item bottom-border">
        <div class="report-label">Number of Active Users</div>
        <div class="report-value">{{comprehensiveReport.UserCounts.NumberOfActiveUsers}}</div>
      </div>
      <div class="flex-report-item bottom-border">
        <div class="report-label">Number of Inactive Users</div>
        <div class="report-value">{{comprehensiveReport.UserCounts.NumberOfInactiveUsers}}</div>
      </div>
    </div>

    <h3>Group/Charity Breakdown</h3>
    <div class="margin-left-med">
      <div class="report-label">Total Charity Contribution</div>
      <div
        class="report-value">{{comprehensiveReport.FinancialTotals.CharityContributionTotal | formatCurrency}}</div>
    </div>

    <ag-grid-angular
      class="ag-theme-alpine margin-top-med  margin-left-med"
      (gridReady)="onGridReady($event)"
      [animateRows]="true"
      [domLayout]="'autoHeight'"
      overlayNoRowsTemplate="<span>No tickets for this game</span>"
      [rowData]="comprehensiveReport.CharityBreakdown"
      [columnDefs]="groupBreakdownReportDefs">
    </ag-grid-angular>
  </div>
</div>
