<div class="text-center" *hasPermission="permissionTypes.ModifyAdminUsers">
  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon class="menu-button"
              data-cy="show-admin-settings-menu"
              matTooltipPosition="right"
              matTooltip="open admin user menu"
    >more_horiz
    </mat-icon>
  </button>
</div>
<mat-menu #menu="matMenu">
  <button mat-menu-item
          data-cy="navigate-to-admin-details"
          (click)="onViewDetailsClick()">
    <mat-icon class="dark-grey menu-icon">settings</mat-icon>
    <span class="dark-grey menu-text">View Details</span>
  </button>

</mat-menu>
