<div class="page-container" *ngIf="activeGameInstance$ | async as activeGameInstance">
  <h2 data-cy="active-game-title">Current {{activeGame?.Name}} Draw</h2>

  <div class="flex-row-wrap gap-normal justify-space-between" *ngIf="activeGameInstance.Id else noActiveGameInstance">
    <div class="full-width">

      <app-display-active-game-instance
        [setSelectedGameInstance]="activeGameInstance"></app-display-active-game-instance>

    </div>
  </div>
</div>

<ng-template #noActiveGameInstance>
  <div class="flex-row-wrap gap-normal">
    Hey looks like there is no currently active draw. Please wait while the new game is being set up.
  </div>

</ng-template>
