import {NgModule} from '@angular/core';
import {LoadingCardContentComponent} from "./loading-card-content/loading-card-content.component";
import {SnackBarComponent} from "./snack-bar/snack-bar.component";
import {GamePageBase} from "./game-page-base";
import {DialogLevelErrorMessageComponent} from "../components/display-errors/dialog-level-error-message.component";
import {PageLevelErrorMessageComponent} from "../components/display-errors/page-level-error-message.component";
import {CommonModule} from "@angular/common";
import {DialogBaseComponent} from "../components/dialogs/dialog-base/dialog-base.component";
import {
  ComponentLevelErrorMessageComponent
} from "../components/display-errors/component-level-error-message/component-level-error-message.component";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatDialogModule} from "@angular/material/dialog";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatButtonModule} from "@angular/material/button";


@NgModule({
  declarations: [
    LoadingCardContentComponent,
    SnackBarComponent,
    DialogLevelErrorMessageComponent,
    DialogBaseComponent,
    GamePageBase,
    PageLevelErrorMessageComponent,
    ComponentLevelErrorMessageComponent
  ],
  exports: [
    LoadingCardContentComponent,
    SnackBarComponent,
    DialogLevelErrorMessageComponent,
    DialogBaseComponent,
    PageLevelErrorMessageComponent,
    ComponentLevelErrorMessageComponent
  ],
  imports: [
    MatProgressBarModule,
    CommonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatButtonModule
  ]
})
export class SharedModule {
}
