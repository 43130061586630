import {Component, Input} from '@angular/core';
import {ReportingService} from "../../../services/reporting.service";
import {IGroupsPerHourPerGame} from "../../../interfaces/reporting/IGroupsPerHourPerGame";
import {ColDef, GridApi, GridReadyEvent} from "ag-grid-community";
import {DatePipe} from "@angular/common";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {GameService} from "../../../services/game.service";
import {DateService} from "../../../services/date.service";

@Component({
  selector: 'app-groups-played-per-hour',
  templateUrl: './groups-played-per-hour.component.html',
  styleUrls: ['./groups-played-per-hour.component.scss']
})
export class GroupsPlayedPerHourComponent {

  @Input() set setDefaultGameInstanceDrawDate(drawTimeP: Date) {
    this.reportingService.getTicketGroupsPerHourBreakdown(this.gameService.activeGame().Id).subscribe({
      next: (reportResP) => {
        if (reportResP.length > 0) {
          this.allGroupsPerHour = reportResP;
          this.drawDate = drawTimeP;
          this.filterResultsPerGameDrawDate();
        }
        this.uiState = UIStateEnum.ShowData;
      },
      error: () => {
        this.errorMessage = 'There was an issue retrieving your retention report. ' +
          'Please try refreshing the page or reaching out to a system administrator.';
        this.uiState = UIStateEnum.ShowData;
      }
    })
  }

  public allGroupsPerHour: IGroupsPerHourPerGame[] = [];
  public filteredGroupsPerGameNumber: IGroupsPerHourPerGame[] = [];
  public uiState = UIStateEnum.ShowLoading;
  public drawDate: Date | null = null;
  public uiStateForTemplate = UIStateEnum;
  public errorMessage: string = '';

  public groupsPerHourDefs: ColDef[] = [
    {
      headerName: 'Draw Date',
      field: 'DrawDate',
      valueFormatter: params => {
        const convertedDate = this.datePipe.transform(params.value, 'MMM d, y HH:mm', 'UTC');
        return convertedDate ? convertedDate : '';
      },
      resizable: true,
      sortable: true
    },
    {
      headerName: 'Date of Sale',
      resizable: true,
      sortable: true,
      width: 250,
      field: 'Date',
      valueFormatter: params => {
        const convertedDate = this.datePipe.transform(params.value, 'MMM d, y', 'UTC');
        return convertedDate ? convertedDate : '';
      }
    },
    {
      headerName: 'Strips Sold',
      resizable: true,
      wrapHeaderText: true,
      filter: 'agNumberColumnFilter',
      sortable: true,
      field: 'TotalGroups'
    },
    {
      headerName: 'Hour Range Start',
      resizable: true,
      wrapHeaderText: true,
      sortable: true,
      field: 'HourRangeStart'
    },
    {
      headerName: 'Hour Range End',
      resizable: true,
      wrapHeaderText: true,
      sortable: true,
      field: 'HourRangeEnd'
    }
  ];

  public groupsGridApi!: GridApi<IGroupsPerHourPerGame>;

  constructor(private reportingService: ReportingService,
              private gameService: GameService,
              private dateService: DateService,
              private datePipe: DatePipe) {
  }

  filterResultsPerGameDrawDate(overrideP: boolean = false) {
    if (this.drawDate && !overrideP) {
      this.filteredGroupsPerGameNumber = this.allGroupsPerHour.filter((reportP) => {
        return this.dateService.areDatesSameDay(reportP.DrawDate, this.drawDate!)
      });

      return;
    }
    this.drawDate = null;
    this.filteredGroupsPerGameNumber = this.allGroupsPerHour;
  }

  onGroupsGridReady(params: GridReadyEvent<IGroupsPerHourPerGame>) {
    this.groupsGridApi = params.api;
    this.groupsGridApi.sizeColumnsToFit();
  }
}
