<h2 mat-dialog-title>Make Charity Payments</h2>
<mat-dialog-content class="mat-typography">
  <mat-form-field appearance="outline" class="full-width">
    <mat-label>Choose Charity</mat-label>
    <mat-select [(ngModel)]="selectedCharityBalance" (ngModelChange)="updateForm()">
      <mat-option *ngFor="let charity of charityBreakdown" [value]="charity">
        {{charity.CharityName}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <form [formGroup]="makeCharityPaymentForm" *ngIf="selectedCharityBalance">

    <div class="margin-bottom-med text-center charity-balance">
      <div>{{selectedCharityBalance.CharityName}} Balance:</div>
      <div class="bold-text margin-top-med">{{selectedCharityBalance.TotalOwedToCharity | formatCurrency}}</div>
    </div>

    <mat-form-field appearance="outline">
      <mat-label>Choose a date</mat-label>
      <input matInput formControlName="paymentDateControl" [matDatepicker]="picker">
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="outline" class="full-width margin-top-med">
      <mat-label>Enter Payment Amount ($)</mat-label>
      <input
        formControlName="paymentAmount"
        type="number"
        matInput
        placeholder="enter payment amount">
    </mat-form-field>
  </form>
  <mat-spinner *ngIf="uiState === uiStateEnumForTemplate.ShowLoading"></mat-spinner>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button class="primary-button" [disabled]="!makeCharityPaymentForm.valid">Make Payment
  </button>
</mat-dialog-actions>
