<h2>Winner Payouts</h2>
<h3>{{activeGame.Name}} - Winners</h3>
<ng-container *ngIf="uiState === uiStateForTemplate.ShowData else showLoading">
  <ag-grid-angular
    *ngIf="gameInstanceWinners.length > 0"
    class="ag-theme-alpine margin-top-med margin-bottom-med"
    [tooltipShowDelay]=0
    domLayout='autoHeight'
    overlayNoRowsTemplate="<span>No charities for this game</span>"
    (gridReady)="onWinnersGridReady($event)"
    [rowData]="gameInstanceWinners"
    [columnDefs]="winnersDefs">
  </ag-grid-angular>

</ng-container>

<ng-template #showLoading>
  <app-loading-card-content></app-loading-card-content>
</ng-template>
