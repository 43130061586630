import { Component } from '@angular/core';
import {OidcService} from "../../services/oidc.service";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {Observable} from "rxjs";

@Component({
  selector: 'app-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.scss']
})
export class NotAuthorizedComponent {

  public unauthorizedErrorMessage$: Observable<string> = this.errorHandlingService.unauthorizedErrorMessage$;
  constructor(private oidcService: OidcService,
              private errorHandlingService: ErrorHandlingService) {
  }

  onLogoutClick(): void {
    this.oidcService.logout();
  }
}
