import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {CharityService} from "../../../services/charity.service";
import {ICharityPayments} from "../../../interfaces/reporting/ICharityPayments";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {forkJoin} from "rxjs";
import {ICharityContacts} from "../../../interfaces/reporting/ICharityContacts";
import {ColDef, GridApi, GridReadyEvent} from "ag-grid-community";
import {DatePipe} from "@angular/common";
import {FormatCurrencyPipe} from "../../../pipes/format-currency.pipe";
import {ErrorHandlingService} from "../../../services/error-handling.service";

@Component({
  selector: 'app-charity-info',
  templateUrl: './charity-info.component.html',
  styleUrls: ['./charity-info.component.css']
})
export class CharityInfoComponent implements OnInit {

  public charityPayments: ICharityPayments[] = [];
  public charityContacts: ICharityContacts[] = [];
  public uiState: UIStateEnum = UIStateEnum.ShowLoading;
  public uiStateEnumForTemplate = UIStateEnum;

  public paymentsColDefs: ColDef[] = [
    {
      headerName: 'Payment Date',
      field: 'DateOfPayment',
      resizable: true,
      valueFormatter: params => {
        const convertedDate = this.datePipe.transform(params.value, 'MMM d, y', 'UTC');
        return convertedDate ? convertedDate : '';
      },
      sortable: true
    },
    {
      headerName: 'Payment Amount',
      resizable: true,
      sortable: true,
      valueFormatter: params => {
        const convertedDate = this.formatCurrencyPipe.transform(params.value);
        return convertedDate ? convertedDate : '';
      },
      field: 'PaymentAmount'
    },
    {
      headerName: 'Payment Entered By',
      resizable: true,
      sortable: true,
      field: 'CreatedByUser'
    }
  ];

  public contactsColDefs: ColDef[] = [
    {
      headerName: 'First Name',
      field: 'FirstName',
      filter: 'agNumberColumnFilter',
      resizable: true,
      sortable: true
    },
    {
      headerName: 'Last Name',
      resizable: true,
      sortable: true,
      field: 'LastName'
    },
    {
      headerName: 'Email',
      resizable: true,
      sortable: true,
      field: 'Email'
    },
    {
      headerName: 'Phone',
      resizable: true,
      sortable: true,
      field: 'Phone'
    },
  ];

  public contactsGridApi!: GridApi<ICharityContacts>;
  public paymentsGridApi!: GridApi<ICharityPayments>;

  constructor(private charityService: CharityService,
              private datePipe: DatePipe,
              private errorHandlingService: ErrorHandlingService,
              private formatCurrencyPipe: FormatCurrencyPipe,
              @Inject(MAT_DIALOG_DATA) public charityId: string) {
  }

  ngOnInit(): void {
    forkJoin([
      this.charityService.paymentsPerCharity(this.charityId),
      this.charityService.contactsPerCharity(this.charityId),
    ]).subscribe({
      next: ([paymentsP, contactsP]) => {
        this.charityPayments = paymentsP;
        this.charityContacts = contactsP;
        this.uiState = UIStateEnum.ShowData;
      },
      error: (err) => {
        this.errorHandlingService.displayDialogLevelErrorMessage(err);
      }
    })
  }


  onPaymentsGridReady(params: GridReadyEvent<ICharityPayments>) {
    this.paymentsGridApi = params.api;
    this.paymentsGridApi.sizeColumnsToFit({
      defaultMinWidth: 90,
    });
  }

  onContactsGridReady(params: GridReadyEvent<ICharityContacts>) {
    this.contactsGridApi = params.api;
    this.contactsGridApi.sizeColumnsToFit({
      defaultMinWidth: 90,
    });
  }

}
