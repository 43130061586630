import {Component} from '@angular/core';
import {AuditingService} from "../../../services/auditing.service";
import {IPlayerAuditEvent} from "../../../interfaces/audit/IPlayerAuditEvent";
import {map, Observable} from "rxjs";
import {ColDef, RowClickedEvent} from "ag-grid-community";
import {AuditEventTables} from "../../../table-definitions/audit-event-tables";
import {FormControl, FormGroup} from "@angular/forms";
import {AuditTablesBase} from "../audit-tables-base";
import {DateService} from "../../../services/date.service";
import {AuditingDataDisplayComponent} from "../../dialogs/auditing-data-display/auditing-data-display.component";
import {EventSourceType} from "../../../enum/EventSourceType";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-player-audit-table',
  templateUrl: './player-audit-table.component.html',
  styleUrls: ['./player-audit-table.component.scss']
})
export class PlayerAuditTableComponent extends AuditTablesBase {

  public playerAudits$: Observable<IPlayerAuditEvent[]> = this.auditingService.playerAudits$.pipe(map((responseP) => {
    this.pagedAuditResponse = responseP;
    return responseP.Data;
  }));

  public playerAuditColDefs: ColDef[] = this.auditEventTables.playerAuditEventColDefs;
  public playerIdFormControl: FormControl = new FormControl('');
  public playerAuditFilterFormGroup = new FormGroup(
    {
      playerId: this.playerIdFormControl,
      eventTypeValue: this.eventTypeFormControl,
      fromDate: this.fromDateFormControl,
      toDate: this.toDateFormControl
    }
  );

  constructor(auditingService: AuditingService,
              dateService: DateService,
              matDialog: MatDialog,
              errorHandlingService: ErrorHandlingService,
              auditEventTables: AuditEventTables) {
    super(auditingService, dateService, matDialog, errorHandlingService, auditEventTables);
  }


  onRowSelected(playerAuditP: RowClickedEvent<IPlayerAuditEvent>) {
    this.matDialog.open(AuditingDataDisplayComponent, {
      data: {
        audit: playerAuditP.data,
        eventSource: EventSourceType.WebApp
      }
    })
  }

  onResetClick() {
    super.onResetClick();
    this.playerIdFormControl.setValue('');
    this.getPaginatedResponse(1);
  }

  getPaginatedResponse(pageNumberP: number): void {

    this.auditingService.retrievePlayerAudits({
      fromDate: this.fromDateFormControl.value,
      toDate: this.toDateFormControl.value,
      pageNumber: pageNumberP,
      pageSize: 15,
      eventTypeValue: this.eventTypeFormControl.value,
      playerId: this.playerIdFormControl.value,
    }).subscribe({
      next: () => {
        this.updatePaginator();
      },
      error: (err) => {
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    });
  }
}
