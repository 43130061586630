import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {OidcService} from './oidc.service';
import {IApiErrorResponse} from "../interfaces/error/IApiErrorResponse";
import {AppConfigService} from "./app-config.service";
import {ErrorHandlingService} from "./error-handling.service";
import {EventSourceType} from "../enum/EventSourceType";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class APIService {

  constructor(private oidcService: OidcService,
              private appConfigService: AppConfigService,
              private errorHandlingService: ErrorHandlingService,
              private router: Router,
              private http: HttpClient) {
  }

  public MakeGetRequest<T>(apiMethod: string, paramsP?: HttpParams): Observable<T> {
    const baseUrl = this.appConfigService.commonApiUrl();
    this.errorHandlingService.unauthorizedErrorMessageBehaviorSubject.next('');

    return this.http.get<T>(`${baseUrl}/${apiMethod}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.oidcService.getAccessToken()}`,
        Accept: 'application/json'
      }),
      params: paramsP
    }).pipe(catchError((errorResponseP: HttpErrorResponse) => {
      if (errorResponseP?.status === 401) {
        this.errorHandlingService.display401ErrorMessage();
        this.router.navigateByUrl('not-authorized');
      }

      if(errorResponseP?.status === 403) {
        this.errorHandlingService.display403ErrorMessage();
        this.router.navigateByUrl('not-authorized');
      }

      throw <IApiErrorResponse>{
        Error: errorResponseP.error,
        Method: apiMethod,
        RequestUrl: apiMethod,
        Status: errorResponseP.status
      };
    }));
  }

  public MakeDeleteRequest<T>(apiMethod: string, bodyP: any) {
    return this.MakeAPIRequest<T>('delete', apiMethod, bodyP);
  }

  public MakePostRequest<T>(apiMethod: string, bodyP: any): Observable<T> {
    return this.MakeAPIRequest<T>('post', apiMethod, bodyP);
  }

  public MakePutRequest<T>(apiMethod: string, bodyP: any) {
    return this.MakeAPIRequest<T>('put', apiMethod, bodyP);
  }

  private GetHttpOptions(paramsP?: any) {
    if (paramsP) {
      paramsP.AuditEventSourceTypeId = EventSourceType.AdminApp;
    }

    return {
      body: paramsP ? JSON.stringify(paramsP) : undefined,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.oidcService.getAccessToken()}`,
        Accept: 'application/json'
      })
    };
  }

  private MakeAPIRequest<T>(httpMethodP: string, apiMethodP: string, bodyP?: {}): Observable<T> {
    this.errorHandlingService.clearErrorMessages();
    const baseUrl = this.appConfigService.commonApiUrl();
    return this.http.request<T>(httpMethodP, `${baseUrl}/${apiMethodP}`, this.GetHttpOptions(bodyP)).pipe(catchError((errorResponseP: HttpErrorResponse) => {

      if (errorResponseP?.status === 401) {
        this.errorHandlingService.display401ErrorMessage();
        this.router.navigateByUrl('not-authorized');
      }

      if(errorResponseP?.status === 403) {
        this.errorHandlingService.display403ErrorMessage();
        this.router.navigateByUrl('not-authorized');
      }

      throw <IApiErrorResponse>{
        Error: errorResponseP.error,
        Method: apiMethodP,
        RequestUrl: apiMethodP,
        Status: errorResponseP.status
      };
    }));
  }
}
