<button *ngIf="!rowData.Paid"
        mat-button
        class="primary-button"
        (click)="onPayWinnerClick()"
        matTooltip="Pay this winner"
        matTooltipPosition="right"
        matTooltipShowDelay="500">
  <mat-icon>payment</mat-icon>
  Pay Winner
</button>
