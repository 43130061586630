<app-component-level-error-message [errorMessage]="errorMessage"></app-component-level-error-message>
<div class="ticket-type-header margin-top-large">
  <div>Date Range (UTC):</div>
  {{dateRanges.StartDate | date: 'MMM d, y HH:mm' : 'UTC'}} -
  {{dateRanges.EndDate | date: 'MMM d, y HH:mm' : 'UTC'}}
</div>

<h3>Lottery License Report</h3>
<ng-container *ngIf="uiState === uiStateForTemplate.ShowLoading">
  <app-loading-card-content></app-loading-card-content>
</ng-container>
<ng-container *ngIf="uiState === uiStateForTemplate.ShowData">
  <div class="margin-left-med flex-row-wrap justify-space-between align-start gap-normal">
    <div class="flex-report-item bottom-border">
      <div class="report-label">Game Name</div>
      <div class="report-value">{{lotteryLicenseReport.GameName}}</div>
    </div>
    <div class="flex-report-item bottom-border">
      <div class="report-label">Game Type</div>
      <div class="report-value">{{lotteryLicenseReport.GameType | gameType}}</div>
    </div>
    <div class="flex-report-item bottom-border">
      <div class="report-label">License #</div>
      <div class="report-value">{{lotteryLicenseReport.LicenseNumber}}</div>
    </div>
    <div class="flex-report-item bottom-border">
      <div class="report-label">Price Per Ticket</div>
      <div class="report-value">
        {{lotteryLicenseReport.TicketsInGroup}} for
        {{lotteryLicenseReport.PricePerGroup | formatCurrency}}</div>
    </div>
    <div class="flex-report-item bottom-border">
      <div class="report-label">Tickets Sold</div>
      <div class="report-value">{{lotteryLicenseReport.TicketsSold}}</div>
    </div>
    <div class="flex-report-item bottom-border">
      <div class="report-label">Total Gross Sales</div>
      <div class="report-value">{{lotteryLicenseReport.TotalGrossSales | formatCurrency}}</div>
    </div>
    <div class="flex-report-item bottom-border">
      <div class="report-label">Total Expenses</div>
      <div class="report-value">{{lotteryLicenseReport.TotalExpenses | formatCurrency}}</div>
    </div>
    <div class="flex-report-item bottom-border">
      <div class="report-label">New Proceeds Raised for Charity</div>
      <div class="report-value">{{lotteryLicenseReport.NetProceedsForCharity | formatCurrency}}</div>
    </div>
  </div>

  <h3 class="winner-subtitle">Winners</h3>
  <div>
    <mat-list>
      <mat-list-item *ngFor="let winner of lotteryLicenseReport.Winners" class="padding-bottom-med padding-top-med">
        <span matListItemTitle>{{winner.FirstName}} {{winner.LastName}}</span>
        <span matListItemLine>Phone: {{winner.Phone}}</span>
        <span matListItemLine>Winnings: {{winner.PlayerWinnings | formatCurrency}}</span>
        <span matListItemLine>Draw date: {{winner.EndedOn | date: 'yyyy-MM-dd'}}</span>
      </mat-list-item>
    </mat-list>
  </div>

</ng-container>
