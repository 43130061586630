<div class="page-container">
  <h2>Player Search</h2>
  <form [formGroup]="playerSearchForm">
    <div class="flex-row-wrap justify-space-between align-center">
      <mat-form-field class="flex-25" appearance="outline">
        <mat-label>First Name</mat-label>
        <input matInput placeholder="enter first name" formControlName="firstName" data-cy="player-search-first-name">
      </mat-form-field>
      <mat-form-field class="flex-25" appearance="outline">
        <mat-label>Last Name</mat-label>
        <input matInput placeholder="enter last name" formControlName="lastName" data-cy="player-search-last-name">
      </mat-form-field>
      <mat-form-field class="flex-25" appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput placeholder="enter email" formControlName="email" type="email" email data-cy="player-search-email">
        <mat-error *ngIf="playerSearchForm.controls.email.invalid">please enter a valid email address</mat-error>
      </mat-form-field>
      <mat-form-field class="flex-25" appearance="outline">
        <mat-label>Phone Number</mat-label>
        <input matInput placeholder="enter phone number" formControlName="phoneNumber" data-cy="player-search-phone-number">
        <mat-error *ngIf="playerSearchForm.controls.phoneNumber.invalid">please enter a valid phone number</mat-error>
      </mat-form-field>
    </div>
    <div class="text-end margin-bottom-med">
      <button matTooltip="search players"
              data-cy="search-players-button"
              [disabled]="!playerSearchForm.valid"
              mat-raised-button
              type="submit"
              color="primary"
              class="margin-right-small"
              (click)="onFormSubmit()">Search Players
        <mat-icon>search</mat-icon>
      </button>
      <button matTooltip="reset filters"
              data-cy="reset-players-button"
              mat-raised-button
              color="warn"
              (click)="onResetClick()">Reset Parameters
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </form>

  <mat-card>
    <ag-grid-angular
      id="playerSearchGrid"
      style="width: 100%; height: 500px;"
      class="ag-theme-alpine large-table"
      (rowClicked)="onRowSelected($event)"
      [tooltipShowDelay]=0
      rowClass="table-row table-row-hover"
      overlayNoRowsTemplate="<span>Click Search to see all players you have access to, or fill in the form then search for more refined search results.</span>"
      (gridReady)="onGridReady($event)"
      [rowData]="players"
      [pagination]="true"
      [paginationPageSize]="20"
      [columnDefs]="columnDefs">
    </ag-grid-angular>
  </mat-card>


</div>
