<app-dialog-base [showLoadingSpinner]="uiState == uiStateEnumForTemplate.ShowLoading">
  <span dialog-title>Add New Admin</span>

  <ng-container dialog-content>
    <ng-container *ngIf="uiState === uiStateEnumForTemplate.ValidateEmail">
      <p>Please enter the new users email and click validate</p>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Email</mat-label>
        <input
          data-cy="add-admin-email-input"
          matInput
          name="newEmail"
          email
          required
          placeholder="Email"
          #name="ngModel"
          [(ngModel)]="newUserEmail"
        >
      </mat-form-field>
      <ng-container *ngIf="invalidEmailReason">
        <div class="display-invalid-reason">{{invalidEmailReason | displayInvalidEmailReason}}</div>
      </ng-container>
      <button
        data-cy="add-admin-validate-email-button"
        mat-stroked-button
        [disabled]="!name.valid"
        (click)="validateEmail()">Validate Email
      </button>
    </ng-container>

    <form [formGroup]="addAdminForm" *ngIf="uiState === uiStateEnumForTemplate.InputUserData">
      <p>Please provide the new users general information and they will be sent an email to complete their
        registration.</p>
      <div class="margin-bottom-med">
        New User Email : <span class="bold-text">{{newUserEmail}}</span>
      </div>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>First Name</mat-label>
        <input
          data-cy="add-admin-first-name-input"
          formControlName="firstName"
          type="text"
          matInput
          placeholder="First Name">
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Last Name</mat-label>
        <input
          data-cy="add-admin-last-name-input"
          formControlName="lastName"
          type="text"
          matInput
          placeholder="Last Name">
      </mat-form-field>

      <mat-form-field appearance="outline"
                      *ngIf="adminInviteType === adminInviteTypeForTemplate.GameAdminInvite">
        <mat-label>New User Role(s)</mat-label>
        <mat-select
          data-cy="add-admin-user-role-select"
          formControlName="newRoles"
          multiple
          required>
          <mat-option *ngFor="let role of possibleRoles"
                      data-cy="add-admin-user-role-options"
                      [value]="role.Id"
                      [matTooltip]="role.Description"
                      matTooltipPosition="right">
            {{role.Name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>

    <form [formGroup]="updateExistingAdminForm"
          *ngIf="uiState === uiStateEnumForTemplate.AddExistingUser && existingAdmin">
      <p>Looks like the user with email <span class="bold-text">{{existingAdmin.Email}}</span> already exists in the
        system</p>
      <div>Name: {{existingAdmin.FirstName}} {{existingAdmin.LastName}}</div>
      <div>Since : {{existingAdmin.CreatedOn | date: 'yyyy-MM-dd'}}</div>
      <ng-container *ngIf="adminInviteType === adminInviteTypeForTemplate.GameAdminInvite">
        <p>adding this user as a game admin will not affect any of their existing roles</p>
      </ng-container>
      <ng-container *ngIf="adminInviteType === adminInviteTypeForTemplate.CausableInvite">
        <p>adding this user as a causable admin will override all roles for this user</p>
      </ng-container>

      <mat-form-field appearance="outline"
                      *ngIf="adminInviteType === adminInviteTypeForTemplate.GameAdminInvite">
        <mat-label>New User Role(s)</mat-label>
        <mat-select formControlName="newRolesForExistingAdmin" multiple required>
          <mat-option *ngFor="let role of possibleRoles" [value]="role.Id" [matTooltip]="role.Description"
                      matTooltipPosition="right">
            {{role.Name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>


    <ng-container *ngIf="uiState === uiStateEnumForTemplate.ViewVerificationCode">
      <div>{{addAdminForm.getRawValue().firstName}} {{addAdminForm.getRawValue().lastName}} has been sent and
        email verification. Please provide them with the following code so they can complete their authentication
        process:
      </div>
      <div class="code" data-cy="add-admin-verification-code">{{newUserVerificationCode}}</div>
    </ng-container>
  </ng-container>

  <ng-container actions>
    <button mat-button
            data-cy="add-admin-send-invite-button"
            *ngIf="uiState == uiStateEnumForTemplate.InputUserData"
            (click)="sendInvite()"
            [disabled]="!addAdminForm.valid">Send Invite
    </button>
    <button mat-button *ngIf="uiState == uiStateEnumForTemplate.AddExistingUser" (click)="addExistingUser()"
            [disabled]="!updateExistingAdminForm.valid">Add New Role
    </button>
  </ng-container>
</app-dialog-base>
