<div class="page-container">
  <h2>Auditing Events</h2>
  <mat-tab-group>
    <mat-tab label="Player Audits">
      <app-player-audit-table></app-player-audit-table>
    </mat-tab>
    <mat-tab label="Admin Audits">
      <app-admin-audit-table></app-admin-audit-table>
    </mat-tab>
    <mat-tab label="System Audits">
      <app-system-audit-table></app-system-audit-table>
    </mat-tab>
  </mat-tab-group>
</div>
