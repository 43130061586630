import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {PermissionTypes} from "../../../enum/PermissionTypes";
import {IDateRanges} from "../../../interfaces/reporting/IDateRanges";

@Component({
  selector: 'app-date-range-reporting',
  templateUrl: './date-range-reporting.component.html',
  styleUrls: ['./date-range-reporting.component.scss', '../../../pages/reports/reports.scss',]
})
export class DateRangeReportingComponent {
  public comprehensiveDatesFromGroup: FormGroup = new FormGroup<any>({
    startDate: new FormControl('', Validators.required),
    endDate: new FormControl('', Validators.required),
  });

  public permissionTypes = PermissionTypes;
  public dateRanges!: IDateRanges;
  public showReports = false;

  constructor() {
  }

  onSubmit() {
    this.dateRanges = {
      StartDate: this.comprehensiveDatesFromGroup.controls.startDate.value,
      EndDate: this.comprehensiveDatesFromGroup.controls.endDate.value
    }
    this.showReports = true;
  }
}
