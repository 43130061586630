<div class="page-container">
  <h2>Causable Admin Reporting</h2>
  <div class="flex justify-space-between gap-normal" *ngIf="chosenReportingView === null">
    <div
      data-cy="date-range-reports-button"
      class="report-section"
      role="button"
      (click)="updateReportingScreen(DATE_RANGE_REPORTS)">
      <mat-icon>date_range</mat-icon>
      <h3>Reporting Per Date Range</h3>
    </div>

    <div
      class="report-section"
      data-cy="per-game-reports-button"
      role="button"
      (click)="updateReportingScreen(REPORTING_PER_GAME)">
      <mat-icon>games</mat-icon>
      <h3>Reporting Per Draw</h3>
    </div>
  </div>

  <div *ngIf="chosenReportingView === DATE_RANGE_REPORTS">
    <button mat-raised-button (click)="updateReportingScreen(null)">
      <mat-icon>arrow_back_ios</mat-icon>
      Back
    </button>
    <h3>Reporting by Date Range</h3>
    <app-date-range-reporting></app-date-range-reporting>
  </div>

  <div *ngIf="chosenReportingView === REPORTING_PER_GAME">
    <button mat-raised-button (click)="updateReportingScreen(null)">
      <mat-icon>arrow_back_ios</mat-icon>
      Back
    </button>
    <h3>Reporting by Draw</h3>
    <mat-stepper #stepper>
      <mat-step>
        <ng-template matStepLabel>Choose draw to generate reports</ng-template>
        <form>
          <app-choose-game-instance
            (chosenGameInstanceEvent)="updateChosenGameInstance($event, stepper)"></app-choose-game-instance>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>View reports</ng-template>
        <button mat-raised-button (click)="resetActiveGameInstance(stepper)">
          <mat-icon>arrow_back_ios</mat-icon>
          Back
        </button>

        <div class="margin-top-med" *ngIf="chosenGameInstance">
          <ng-container>
            <mat-tab-group [(selectedIndex)]="selectedTab" dynamicHeight disableRipple data-cy="report-tab-group">
              <mat-tab label="Standard Game Report"
                       labelClass="standard-report-tab"
                       *ngIf="chosenGameInstance.State === gameInstanceStatusesForTemplate.WinnerAnnounced">
                <div class="margin-top-large">
                  <app-standard-game-report [updateChosenGameInstance]="chosenGameInstance"></app-standard-game-report>
                </div>
              </mat-tab>
              <mat-tab label="Auditing Report"
                       labelClass="auditing-report-tab"
                       *hasPermission="permissionTypes.ViewAuditingReport">
                <div class="margin-top-large">
                  <app-raffle-report [updateChosenGameInstance]="chosenGameInstance"></app-raffle-report>
                </div>
              </mat-tab>
              <mat-tab
                labelClass="sales-per-game-report-tab"
                label="Sales Per Game"
                *hasPermission="permissionTypes.ViewGameSummaryReport">
                <ng-template matTabContent>
                  <div class="margin-top-large">
                    <app-sales-per-game
                      [setDefaultGameInstanceDrawDate]="chosenGameInstance.EndedOn"></app-sales-per-game>
                  </div>
                </ng-template>
              </mat-tab>
              <mat-tab
                labelClass="ticket-sales-hourly-report-tab"
                label="Hourly Ticket Sales"
                *hasPermission="permissionTypes.ViewHourlyTicketSalesReport">
                <div class="margin-top-large">
                  <app-groups-played-per-hour
                    [setDefaultGameInstanceDrawDate]="chosenGameInstance.EndedOn"></app-groups-played-per-hour>
                </div>
              </mat-tab>
            </mat-tab-group>
          </ng-container>
        </div>
        <ng-container *ngIf="!chosenGameInstance">
          <div>
            No draw was chosen, please select a draw to view reports
          </div>
        </ng-container>
      </mat-step>
    </mat-stepper>

  </div>


</div>
