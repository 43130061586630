<app-component-level-error-message [errorMessage]="errorMessage"></app-component-level-error-message>
<div>
  <mat-form-field appearance="outline">
    <mat-label>Draw Date</mat-label>
    <input placeholder="filter by draw date" matInput [matDatepicker]="picker" [(ngModel)]="drawDate"
           (ngModelChange)="filterResultsPerGameDrawDate()">
    <mat-hint>MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</div>
<button class="text-underline margin-top-med" mat-stroked-button (click)="filterResultsPerGameDrawDate(true)">View All Draw Dates</button>
<ng-container *ngIf="uiState === uiStateForTemplate.ShowData">
  <ag-grid-angular
    data-cy="sales-per-game-report-table"
    class="ag-theme-alpine margin-top-med"
    [tooltipShowDelay]=0
    domLayout='autoHeight'
    overlayNoRowsTemplate="<span>No tickets for this game</span>"
    (gridReady)="onRetentionGridReady($event)"
    [rowData]="filteredRetentionReportsByGameNumber"
    [columnDefs]="retentionReportDefs">
  </ag-grid-angular>
</ng-container>
<ng-container *ngIf="uiState === uiStateForTemplate.ShowLoading">
  <app-loading-card-content></app-loading-card-content>
</ng-container>
