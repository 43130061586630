import {Injectable} from '@angular/core';
import {IGameQueryResult} from "../interfaces/IGameQueryResult";
import {AppConfigService} from "./app-config.service";

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  constructor(private appConfig: AppConfigService) {
  }

  public setApplicationTheme(gameConfigP: IGameQueryResult): void {
    if (gameConfigP.ColorThemeUrl && this.appConfig.cdnRoot()) {
      const head = document.getElementsByTagName('head')[0];
      const css_link = document.createElement('link');
      css_link.setAttribute('rel', 'stylesheet');
      css_link.setAttribute('href', `${this.appConfig.cdnRoot()}${gameConfigP.ColorThemeUrl}`);
      head.appendChild(css_link);
    }
  }
}
