<div class="page-container">

  <ng-container *ngIf="currentUser">
    <button mat-raised-button class="margin-bottom-med margin-top-med" (click)="onBackToUsersClick()">
      <mat-icon>arrow_back_ios</mat-icon>
      Back to Users
    </button>
    <h1>{{currentUser.FirstName}} {{currentUser.LastName}}</h1>
    <mat-tab-group data-cy="user-details-tab-group">
      <mat-tab label="Details" labelClass="user-details-tab">
        <div class="flex-row justify-space-between align-center margin-top-large">
          <p>User details</p>
        </div>
        <div class="margin-top-medium custom-card-border">
          <div class="flex-row-wrap justify-start gap-normal">
            <div class="detail-section">
              <div class="detail-label">Admin Since</div>
              <div class="detail-value">{{currentUser.CreatedOn | date: 'MMM d, y' : 'UTC'}}</div>
            </div>

            <div class="detail-section">
              <div class="detail-label">Email Address</div>
              <div class="detail-value" data-cy="current-user-email-value">{{currentUser.Email}}</div>
            </div>

            <div class="detail-section">
              <div class="detail-label">Phone Number</div>
              <div class="detail-value">{{currentUser.Phone}}</div>
            </div>

            <div class="detail-section">
              <div class="detail-label">Admin Id</div>
              <div class="detail-value" data-cy="current-user-id-value">{{currentUser.Id}}</div>
            </div>

            <div class="detail-section">
              <div class="detail-label">Verification Code</div>
              <div class="detail-value" data-cy="current-user-verification-value">{{currentUser.VerificationCode}}</div>
            </div>

            <div class="detail-section">
              <div class="detail-label">Status</div>
              <div class="detail-value">
                <mat-icon
                  [ngClass]="currentUser.Active ? 'green-text' : 'red-text'">{{currentUser.Active ? 'check' : 'do_not_disturb'}}</mat-icon>
                <span [ngClass]="currentUser.Active ? 'green-text' : 'red-text'">{{currentUser.Active ? 'Active' : 'Deactivated'}}</span>
              </div>
            </div>
          </div>
        </div>

      </mat-tab>
      <mat-tab label="Roles" labelClass="user-roles-tab">
        <div class="flex-row justify-space-between align-center margin-top-large">
          <p>All Roles assigned to this User.</p>
          <button
            data-cy="current-user-assign-roles-button"
            mat-raised-button
            color="primary"
            (click)="onUpdateUserRolesClick(currentUser)">Assign Roles</button>
        </div>

        <table mat-table [dataSource]="currentUser.Roles" data-cy="user-roles-table">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let element"> {{element.Description}} </td>
          </ng-container>

          <ng-container matColumnDef="deactivate">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <button
                data-cy="remove-role-button"
                mat-icon-button
                matTooltip="remove this role"
                (click)="onRemoveUserRoleClick(element)">
                <mat-icon class="red-text">delete</mat-icon>
              </button>
            </td>

          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr data-cy="user-roles-row" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

      </mat-tab>
    </mat-tab-group>
  </ng-container>
</div>
