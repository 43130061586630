import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, tap} from 'rxjs';
import {APIService} from "./api.service";
import {IDrawNumberRequest} from "../interfaces/IDrawNumberRequest";
import {IDrawNumberResponse} from "../interfaces/IDrawNumberResponse";
import {IGameInstance} from "../interfaces/IGameInstance";
import {IActiveGameInstance} from "../interfaces/IActiveGameInstance";
import {IGameInstanceWinner} from "../interfaces/IGameInstanceWinner";
import {createStore, select, setProp, withProps} from "@ngneat/elf";
import {updateRequestCache, withRequestsCache} from "@ngneat/elf-requests";
import {GameInstanceStatuses} from "../enum/GameInstanceStatusEnum";
import {GameTypeEnum} from "../enum/GameTypeEnum";
import {catchError} from "rxjs/operators";
import {ErrorHandlingService} from "./error-handling.service";

@Injectable({
  providedIn: 'root'
})
export class GameInstanceService {

  public gameInstanceHasEndedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public gameInstanceHasEnded$: Observable<boolean> = this.gameInstanceHasEndedSubject.asObservable();

  private defaultActiveGameInstance: IActiveGameInstance = {
    GameId: '',
    Name: '',
    Description: '',
    Id: '',
    NumberOfActivePlayers: 0,
    NumberOfTotalPlayers: 0,
    PotTotal: 0,
    State: GameInstanceStatuses.Active,
    TicketGroupPrice: 0,
    Type: GameTypeEnum.GoldRush,
    TicketsInGroup: 0,
    TotalPaidTickets: 0,
    TotalUnpaidTickets: 0,
    EndedOn: new Date(),
    StartedOn: new Date()
  };

  private gameInstanceStore = createStore(
    {name: 'game-instance'},
    withProps<{ activeGameInstance: IActiveGameInstance }>({
      activeGameInstance: this.defaultActiveGameInstance
    }),
    withRequestsCache<'game-instance'>()
  );

  constructor(private apiService: APIService,
              private errorHandlingService: ErrorHandlingService) {
  }

  public gameInstanceWinners: IGameInstanceWinner[] = [
    {
      Email: 'randomemail@yoyo.com',
      GameNumber: 1,
      FirstName: 'Nick',
      Paid: true,
      Phone: '902-999-0987',
      LastName: 'Vincent',
      AmountOwed: 0,
      GameInstanceDrawDate: new Date('2022-12-01')
    },
    {
      Email: 'anotherone@yoyo.com',
      GameNumber: 2,
      FirstName: 'Fabio',
      Paid: false,
      Phone: '902-763-8329',
      LastName: 'Carroll',
      AmountOwed: 56700,
      GameInstanceDrawDate: new Date('2022-12-08')
    },
    {
      Email: 'howaboutathird@yoyo.com',
      GameNumber: 3,
      FirstName: 'Mariah',
      Paid: false,
      Phone: '902-107-7193',
      LastName: 'Peconi',
      AmountOwed: 40000,
      GameInstanceDrawDate: new Date('2022-12-15')
    },
    {
      Email: 'somemore@yoyo.com',
      GameNumber: 4,
      FirstName: 'Jordan',
      Paid: true,
      Phone: '902-982-1230',
      LastName: 'Spencer',
      AmountOwed: 0,
      GameInstanceDrawDate: new Date('2022-12-22')
    },
    {
      Email: 'lastone@yoyo.com',
      GameNumber: 5,
      FirstName: 'Esther',
      Paid: false,
      Phone: '902-917-0921',
      LastName: 'Ramsey',
      AmountOwed: 90000,
      GameInstanceDrawDate: new Date('2022-12-29')
    }
  ];

  public selectActiveGameInstance(): Observable<IActiveGameInstance> {
    return this.gameInstanceStore.pipe(select((state) => state.activeGameInstance));
  }

  public activeGameInstanceId(): string {
    return this.gameInstanceStore.query(state => state.activeGameInstance.Id);
  }

  public clearActiveGameInstance() {
    this.gameInstanceStore.reset();
  }

  public drawWinner(gameInstanceIdP: string): Observable<IDrawNumberResponse> {
    const request_body = <IDrawNumberRequest>{
      GameInstanceId: gameInstanceIdP,
      OverrideValidation: false
    };
    return this.apiService.MakePostRequest<IDrawNumberResponse>('game-instance/draw-winner', request_body);
  }

  public getGameInstancesByGameId(gameIdP: string): Observable<IGameInstance[]> {
    return this.apiService.MakeGetRequest<IGameInstance[]>(`game-instance/for-game/${gameIdP}`)
      .pipe(catchError((error) => {
        this.errorHandlingService.displayPageLevelErrorMessage(error);
        return [];
      }));
  }

  public activeGameInstanceByGame(gameIdP: string): Observable<IActiveGameInstance> {
    return this.apiService.MakeGetRequest<IActiveGameInstance>(`game-instance/active-for-game/${gameIdP}`).pipe(tap((activeGameInstance) => this.gameInstanceStore.update(
      updateRequestCache('game-instance', {value: 'full'}),
      setProp('activeGameInstance', activeGameInstance))));
  }

  public gameInstanceWinnersByGame(gameIdP: string): Observable<IGameInstanceWinner[]> {
    return of(this.gameInstanceWinners);
    //TODO: Tie in real API logic
    // return this.apiService.MakeGetRequest<IGameInstanceWinner[]>(`game-instance/winners-per-game/${gameIdP}`);
  }

}
