<app-component-level-error-message [errorMessage]="errorMessage"></app-component-level-error-message>
<ng-container *ngIf="uiState === uiStateEnumForTemplate.ShowLoading ">
  <app-loading-card-content></app-loading-card-content>
</ng-container>
<div *ngIf="uiState === uiStateEnumForTemplate.ShowData" data-cy="auditing-report-container">
  <ng-container *ngIf="raffleReportSummaryData; else noReportData">
    <div class="flex-row justify-space-between align-start">

      <div>
        <div class="section-header">Auditing Report</div>
        <div class="ticket-type-header">Draw Date: {{chosenGameInstance?.EndedOn | date: 'MMM d, y HH:mm' : 'UTC'}}
        </div>
      </div>

      <div>
        <button *ngIf="raffleReportSummaryData"
                mat-raised-button
                color="primary" (click)="generatePDF()">Generate PDF
        </button>
      </div>
    </div>
    <div class="margin-top-large">
      <div class="raffle-section">
        <div class="section-header">Ticket Summary:</div>
        <div class="ion-margin-bottom">
          <div>Number of Registered Players: {{raffleReportSummaryData.NumberOfRegisteredPlayers}}</div>
          <div>Number of Players in this Game: {{raffleReportSummaryData.NumberOfPlayersInGame}}</div>
          <div>Number of Played Tickets: {{raffleReportSummaryData.TotalPaidAndPlayed}}</div>
          <div *ngIf="activeGame?.Type === gameTypes.GoldRush">Number of Unpaid
            Tickets: {{raffleReportSummaryData.TotalNotPaid}}</div>
          <div>Number of Voided Tickets: {{raffleReportSummaryData.TotalVoided}}</div>
          <div>Price per ticket strip: {{raffleReportSummaryData.GroupPrice | formatCurrency}}</div>
        </div>
      </div>
      <div *ngFor="let data of allGroupedTickets">
        <div class="section-header margin-top-med">Player Info:</div>
        <div class="margin-bottom-med">
          <div>{{data.Player.FirstName + ' ' + data.Player.LastName}}</div>
          <div>{{data.Player.Email}}</div>
          <div>{{data.Player.Phone}}</div>
        </div>
        <div class="ticket-type-header">Played Tickets:</div>
        <ng-container *ngIf="data.GroupedTickets?.PaidAndPlayed; else noPaidTickets">
          <table class="margin-bottom-med">
            <tr>
              <th>Ticket Number</th>
              <th>Assigned On</th>
              <th>Purchased On</th>
            </tr>
            <ng-container *ngFor="let group of data.GroupedTickets.PaidAndPlayed">
              <tr>
                <th colspan="4">Ticket Strip ID: {{ group.GroupId }}</th>
              </tr>
              <tr *ngFor="let row of group.Tickets">
                <td>{{ row.TicketNumber }}</td>
                <td>{{ row.CreatedOn }}</td>
                <td>{{ row.PurchasedOn }}</td>
              </tr>
            </ng-container>
          </table>
        </ng-container>
        <ng-template #noPaidTickets>
          <div class="margin-bottom-med">This player had no paid tickets in this draw</div>
        </ng-template>

        <ng-container *ngIf="activeGame?.Type === gameTypes.GoldRush">
          <div class="ticket-type-header">Not Paid Tickets:</div>
          <ng-container *ngIf="data.GroupedTickets.NotPaid.length; else noUnpaidTickets">
            <table class="margin-bottom-med">
              <tr>
                <th>Ticket Number</th>
                <th>Assigned On</th>
              </tr>
              <ng-container *ngFor="let group of data.GroupedTickets.NotPaid">
                <tr>
                  <th colspan="4">Ticket Strip ID: {{ group.GroupId }}</th>
                </tr>
                <tr *ngFor="let row of group.Tickets">
                  <td>{{ row.TicketNumber }}</td>
                  <td>{{ row.CreatedOn }}</td>
                </tr>
              </ng-container>
            </table>
          </ng-container>
          <ng-template #noUnpaidTickets>
            <div class="margin-bottom-med">This player had no unpaid tickets in this draw</div>
          </ng-template>
        </ng-container>

        <div class="ticket-type-header">Voided Tickets:</div>
        <ng-container *ngIf="data.GroupedTickets.Voided.length; else noVoidedTickets">
          <table class="margin-bottom-med">
            <tr>
              <th>Ticket Number</th>
            </tr>
            <ng-container *ngFor="let group of data.GroupedTickets.Voided">
              <tr>
                <th colspan="4">Ticket Strip ID: {{ group.GroupId }}</th>
              </tr>
              <tr *ngFor="let row of group.Tickets">
                <td>{{ row.TicketNumber }}</td>
              </tr>
            </ng-container>
          </table>
        </ng-container>
        <ng-template #noVoidedTickets>
          <div class="margin-bottom-med">This player had no voided tickets in this draw</div>
        </ng-template>

        <hr>
      </div>
    </div>

    <ng-container *ngIf="raffleReport.length === 0">
      <div class="no-results">No Auditing Report data for this draw</div>
    </ng-container>
  </ng-container>
</div>
<ng-template #noReportData>
  No Available Report Data
</ng-template>
