import {NgModule} from '@angular/core';
import {UserHasCausableAdminAccessDirective} from "./permissions/user-has-causable-admin-access.directive";
import {HasPermissionDirective} from "./permissions/has-permission";

@NgModule({
  declarations: [
    UserHasCausableAdminAccessDirective,
    HasPermissionDirective
  ],
  exports: [
    UserHasCausableAdminAccessDirective,
    HasPermissionDirective
  ]
})
export class DirectiveModule {
}
