<mat-tab-group *ngIf="uiState == uiStateForTemplate.ShowData" data-cy="player-tab-group">
  <mat-tab labelClass="player-tickets-tab"
           label="Tickets"
           *hasPermission="permissionTypes.ViewPlayerNumbers">
    <ng-template matTabContent>
      <h3>Player Tickets</h3>
      <div class="text-end" *ngIf="playerNumberGroupRows.length > 0 && userHasActiveStrips">
        <button class="red-text"
                (click)="onDeactivateAllTicketsPlayerClick()"
                data-cy="deactivate-all-ticket-strips-button"
                mat-stroked-button>
          <mat-icon>do_disturb</mat-icon>
          Deactivate All Active Strips
        </button>
      </div>

      <ag-grid-angular
        id="playerNumbersGrid"
        class="ag-theme-alpine margin-top-med large-table"
        [tooltipShowDelay]=0
        rowClass="table-row"
        overlayNoRowsTemplate="<span>No tickets for this game</span>"
        (gridReady)="onTicketGridReady($event)"
        [rowData]="playerNumberGroupRows"
        [columnDefs]="ticketsColumnDefs">
      </ag-grid-angular>
    </ng-template>
  </mat-tab>

  <mat-tab label="Transactions"
           labelClass="player-transactions-tab"
           *hasPermission="permissionTypes.ViewPlayerTransactions">
    <ng-template matTabContent>
      <h3>Transaction Filter</h3>
      <form [formGroup]="transactionSearchForm" class="margin-top-med">
        <div class="flex-row-wrap justify-space-between align-center">
          <mat-form-field class="flex-33" appearance="outline">
            <mat-label>Stripe Charge Id</mat-label>
            <input matInput placeholder="enter stripe id" formControlName="stripeId">
            <mat-hint>enter entire or part of stripe charge id</mat-hint>
          </mat-form-field>
          <mat-form-field class="flex-33" appearance="outline">
            <mat-label>Ticket Number</mat-label>
            <input matInput placeholder="enter a ticket number" formControlName="ticketNumber">
            <mat-hint>enter whole ticket number</mat-hint>
          </mat-form-field>
          <mat-form-field class="flex-33" appearance="outline">
            <mat-label>Transaction Date</mat-label>
            <input placeholder="filter by transaction date" matInput [matDatepicker]="picker"
                   formControlName="transactionDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-hint>YYYY-MM-DD</mat-hint>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="text-end margin-bottom-med margin-top-med">
          <button matTooltip="search players" [disabled]="!transactionSearchForm.valid" mat-raised-button type="submit"
                  color="primary" class="margin-right-small" (click)="onFormSubmit()">Filter
            <mat-icon>search</mat-icon>
          </button>
          <button matTooltip="reset filters" mat-raised-button color="warn" (click)="onResetClick()">Reset
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
      </form>
      <h3>Transactions</h3>
      <ag-grid-angular
        id="playerTransactionsGrid"
        class="ag-theme-alpine margin-top-med large-table"
        [tooltipShowDelay]=0
        rowClass="table-row"
        overlayNoRowsTemplate="<span>No transactions for this game</span>"
        (gridReady)="onTransactionGridReady($event)"
        [rowData]="filteredTransactionsPerGame"
        [pagination]="true"
        [columnDefs]="transactionColumnDefs">
      </ag-grid-angular>
    </ng-template>
  </mat-tab>
</mat-tab-group>
<div *ngIf="uiState == uiStateForTemplate.ShowLoading">
  <mat-spinner></mat-spinner>
</div>
