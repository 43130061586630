<div class="choose-game-container">
  <h2>Welcome to Causable Admin</h2>
  <p>please choose the game that you would like to manage</p>
  <form [formGroup]="chosenGameFromGroup">
    <mat-form-field appearance="outline" *ngIf="availableGames.length > 0" class="full-width">
      <mat-label>Choose Game</mat-label>
      <mat-select formControlName="chosenGame" data-cy="select-game">
        <mat-option data-cy="game-option" *ngFor="let game of availableGames" [value]="game" class="choose-game-option">
          {{game.Name}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="chosenGameFromControl.errors && (chosenGameFromControl.touched || chosenGameFromControl.dirty)">
        please choose a game
      </mat-error>
    </mat-form-field>
  </form>

  <div class="text-end">
    <button color="primary" mat-raised-button (click)="onGameConfirmClick()" data-cy="confirm-game">
      Confirm
    </button>
  </div>
</div>
