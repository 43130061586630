import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {PermissionsService} from '../../services/permissions.service';
import {PermissionTypes} from "../../enum/PermissionTypes";

@Directive({
  selector: '[hasPermission]'
})
export class HasPermissionDirective {
  @Input()
  set hasPermission(val: PermissionTypes) {
    const hasAccess = this.permissionService.userHasPermission(val);
    if (hasAccess) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionService: PermissionsService
  ) {
  }

}
