import {Component, Input} from '@angular/core';
import {IDateRanges} from "../../../interfaces/reporting/IDateRanges";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {ILotteryLicenseReport} from "../../../interfaces/reporting/ILotteryLicenseReport";
import {ReportingService} from "../../../services/reporting.service";
import {GameService} from "../../../services/game.service";

@Component({
  selector: 'app-lottery-license-report',
  templateUrl: './lottery-license-report.component.html',
  styleUrls: ['./lottery-license-report.component.scss', '../../../pages/reports/reports.scss',]
})
export class LotteryLicenseReportComponent {
  @Input() set dateRangesSetter(valueP: IDateRanges) {
    this.dateRanges = valueP;
    this.reportingService.getLotteryLicenseReport(
      this.gameService.activeGame().Id,
      valueP.StartDate,
      valueP.EndDate
    ).subscribe({
      next: (comprehensiveReportResP) => {
        this.lotteryLicenseReport = comprehensiveReportResP;
        this.uiState = UIStateEnum.ShowData;
      },
      error: () => {
        this.errorMessage = 'There was an issue retrieving your lottery license report. ' +
          'Please try refreshing the page or reaching out to a system administrator.';
        this.uiState = UIStateEnum.ShowData;
      }
    })
  }

  public uiState = UIStateEnum.ShowLoading;
  public uiStateForTemplate = UIStateEnum;
  public errorMessage = '';
  public lotteryLicenseReport!: ILotteryLicenseReport;
  public dateRanges!: IDateRanges;

  constructor(private reportingService: ReportingService,
              private gameService: GameService) {
  }
}
