import {Component} from "@angular/core";
import {GameService} from "../services/game.service";
import {UIStateEnum} from "../enum/UIStateEnum";

@Component({
  selector: 'app-base',
  template: '',
  styles: []
})
export class GamePageBase {

  public uiState = UIStateEnum.ShowData;
  public uiStateForTemplate = UIStateEnum;
  public activeGame = this.gameService.activeGame();

  constructor(protected gameService: GameService) {
  }


}
