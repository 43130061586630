import { Component } from '@angular/core';
import {ActiveUserService} from "../../services/active-user.service";
import {OidcService} from "../../services/oidc.service";

@Component({
  selector: 'app-user-profile-menu',
  templateUrl: './user-profile-menu.component.html',
  styleUrls: ['./user-profile-menu.component.scss']
})
export class UserProfileMenuComponent {
  public activeUser$ = this.activeUserService.selectActiveUser();

  constructor(private activeUserService: ActiveUserService,
              private oidcService: OidcService) {
  }

  onLogoutClick(): void
  {
    this.oidcService.logout();
  }
}
